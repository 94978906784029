import { toastSuccessMessage } from '../../components/common/ToastMessage';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useDeathNoticesApi from 'src/api/deathNotice.api';
import { IDeathNoticePayload } from 'src/datatypes/DeathNotice';

const initialState: IDeathNoticePayload = {
  deathNoticeList: {
    loading: false,
    data: {
      data: [],
      current_page: 0,
      first_page_url: '',
      from: 0,
      last_page: 0,
      path: '',
      per_page: 0,
      prev_page_url: '',
      to: 0,
      total: 0,
    },
    error: false,
  },
  deathNoticeDetails: { loading: false, data: null, error: false },
  deleteNoticeDetails: { loading: false, success: false, error: false },
};

export const deathNoticeSlice = createSlice({
  name: 'deathNotice',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetDeleteDeathNoticeData: (state) => {
      state.deleteNoticeDetails = initialState.deleteNoticeDetails;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeathNoticeList.pending, (state) => {
        state.deathNoticeList.loading = true;
      })
      .addCase(fetchDeathNoticeList.fulfilled, (state, { payload }) => {
        state.deathNoticeList.data = payload?.death_notices;
        state.deathNoticeList.loading = false;
      })
      .addCase(fetchDeathNoticeList.rejected, (state) => {
        state.deathNoticeList.loading = false;
        state.deathNoticeList.error = true;
      })
      .addCase(fetchDeathNoticeDetails.pending, (state) => {
        state.deathNoticeDetails.loading = true;
      })
      .addCase(fetchDeathNoticeDetails.fulfilled, (state, { payload }) => {
        state.deathNoticeDetails.data = payload?.death_notice;
        state.deathNoticeDetails.loading = false;
      })
      .addCase(fetchDeathNoticeDetails.rejected, (state) => {
        state.deathNoticeDetails.loading = false;
        state.deathNoticeDetails.error = true;
      })
      .addCase(deleteDeathNoticeDetails.pending, (state) => {
        state.deleteNoticeDetails.loading = true;
        state.deleteNoticeDetails.success = false;
        state.deleteNoticeDetails.error = false;
      })
      .addCase(deleteDeathNoticeDetails.fulfilled, (state) => {
        state.deleteNoticeDetails.loading = false;
        state.deleteNoticeDetails.success = true;
        state.deleteNoticeDetails.error = false;
      })
      .addCase(deleteDeathNoticeDetails.rejected, (state) => {
        state.deleteNoticeDetails.loading = false;
        state.deleteNoticeDetails.error = true;
      })
  },
});

// Async API Hooks
/**
 * Fetch Death Notice List from API and save in redux store
 */
export const fetchDeathNoticeList = createAsyncThunk(
  'deathNotice/fetchDeathNoticeList',
  async (data: any, { rejectWithValue }) => {
    const response = await useDeathNoticesApi().getDeathNoticeList(data.params);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.status === 201) {
      return response?.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

/**
 * Fetch Death Notice Details for the from API and save in redux store
 */
export const fetchDeathNoticeDetails = createAsyncThunk(
  'deathNotice/fetchDeathNoticeDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useDeathNoticesApi().getDeathNoticeDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.status === 201) {
      return response?.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const deleteDeathNoticeDetails = createAsyncThunk(
  'deathNotice/deleteDeathNoticeDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useDeathNoticesApi().deleteDeathNoticeDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.status === 201) {
      toastSuccessMessage('Successfully Deleted');
      return response?.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const { resetDeleteDeathNoticeData } = deathNoticeSlice.actions;

export default deathNoticeSlice.reducer;
