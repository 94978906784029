import { toastSuccessMessage } from '../../components/common/ToastMessage';
import PageTitle from '../../components/common/PageTitle';
import { useEffect, useState } from 'react';
import {
  IShowDeleteLivingWillModal,
  ILivingWillFormPayload,
} from 'src/datatypes/LivingWillForms';
import {
  fetchLivingWillFormList,
  resetFormData,
} from 'src/redux/slices/livingWillFormSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useNavigate } from 'react-router-dom';
import Listing from '../../components/living-will-forms/Listing';

const LivingWillFormListing = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { formList, deleteFormStatus } = useAppSelector<ILivingWillFormPayload>(
    (state) => state.livingWillForm
  );

  const [showDeleteLivingWillFormModal, setShowDeleteLivingWillFormModal] =
    useState<IShowDeleteLivingWillModal>({
      show: false,
      idDetails: [],
    });

  const getLivingWillFormListData = () => {
    dispatch(fetchLivingWillFormList());
  };

  useEffect(() => {
    getLivingWillFormListData();
    // eslint-disable-next-line
  }, []);

  //handle successful delete api call and modal closing
  useEffect(() => {
    if (deleteFormStatus.success) {
      toastSuccessMessage('Living Will Form deleted successfully!');
      dispatch(resetFormData());
      setShowDeleteLivingWillFormModal({
        show: false,
        idDetails: [],
      });
      getLivingWillFormListData();
    }
    // eslint-disable-next-line
  }, [deleteFormStatus]);

  return (
    <div className="living-will-form-listing">
      <PageTitle
        heading="Living Will Form"
        buttonName="New"
        buttonClick={() => {
          dispatch(resetFormData());
          navigate('/living-will-forms/new');
        }}
      />
      <div className="living-will-form-listing-content">
        <Listing
          showDeleteModal={showDeleteLivingWillFormModal}
          setShowDeleteModal={setShowDeleteLivingWillFormModal}
          livingWillList={formList}
          deleteFormDetail={deleteFormStatus}
        />
      </div>
    </div>
  );
};

export default LivingWillFormListing;
