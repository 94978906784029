import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TextInput from '../common/TextInput';
import FowButton from '../common/FowButton';
import { IFaqSearch } from 'src/datatypes/Faq';
import SelectInput from '../common/SelectInput';
import { typeOptions } from 'src/constants/Faq';

// interface for the component
interface IProps {
  faqSearch: IFaqSearch;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFaqSearch: () => void;
  resetFaqSearch: () => void;
  isFilterApplied: boolean;
  errors: IFaqSearch;
}

const FaqSearch = ({
  faqSearch,
  handleInputChange,
  onFaqSearch,
  resetFaqSearch,
  isFilterApplied,
  errors,
}: IProps) => {
  return (
    <div className="charity-form">
      <Row>
        <Col lg={6} md={6} sm={12}>
          <TextInput
            name="question_text"
            placeholder="Question Text"
            value={String(faqSearch.question_text)}
            onChange={(e) => handleInputChange(e)}
            label="Term"
            errorMessage={String(errors.question_text)}
            isRequired={false}
          />
        </Col>
        <Col lg={6} md={6} sm={12}>
          <SelectInput
            name="type"
            placeholder="Select Category"
            value={faqSearch.type}
            optionLabel="label"
            optionValue="value"
            options={typeOptions}
            handleSelectChange={(e) => handleInputChange(e)}
            label="Category"
            isRequired={false}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col lg={12}>
          <div className="d-flex gap-3 justify-content-between w-100">
            <FowButton variant="success" onClick={onFaqSearch}>
              <i className="fa fa-search mr-2"></i> Search
            </FowButton>
            {isFilterApplied && (
              <FowButton variant="outline-secondary" onClick={resetFaqSearch}>
                <i className="fa fa-undo mr-2"></i> Reset
              </FowButton>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FaqSearch;
