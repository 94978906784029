import { IDeathNoticeField } from 'src/datatypes/DeathNotice';

export const searchDeathNoticeField: IDeathNoticeField = {
  first_name: '',
  last_name: '',
  created_date_from: '',
  created_date_to: '',
  state_id: '',
  city: '',
};

export const deathNoticeValidations: IDeathNoticeField = {
  first_name: 'nameValidate',
  last_name: 'nameValidate',
  created_date_from: 'date:YYYY-MM-DD|compareDateInputLess',
  created_date_to: 'date:YYYY-MM-DD|compareDateInputMore',
  state: '',
  city: '',
};

export const deathNoticeListPagination = {
  per_page: 50,
  page: 1,
};
