import useAxiosService from '../services/axios.service';

const useWillStatusApi = () => {
  const { getUserHttpClient } = useAxiosService();
  const getWillStatusList = async (params?: any) => {
    return getUserHttpClient()
      .get('/user-wills', { params: params ? params : {} })
      .then((response) => {
        return response;
      });
  };

  const getWillStatusDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/user-wills/' + id)
      .then((response) => response);
  };

  const deleteWillStatusData = async (id: string) => {
    return await getUserHttpClient()
      .delete('/user-wills/' + id)
      .then((response) => response);
  };

  return {
    getWillStatusList,
    getWillStatusDetails,
    deleteWillStatusData,
  };
};

export default useWillStatusApi;
