import { useRef, useState, useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import ErrorMessage from './ErrorMessage';
import JoditEditor from 'jodit-react';

interface IProps {
  value: string;
  initialValue: string;
  config: any;
  label?: string;
  onChange: (e: any) => void;
  errorMessage?: string;
  controlId?: string;
  className?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
}

interface IEditorProps {
  initialValue: string;
  value: string;
  config: any;
  onChange: (e: any) => void;
}

const Editor = ({ initialValue, value, config, onChange }: IEditorProps) => {
  const editor = useRef(null);
  const [content, setContent] = useState(value);

  useEffect(() => {
    onChange(content);
    // eslint-disable-next-line
  }, [content]);

  return useMemo(
    () => (
      <JoditEditor
        ref={editor}
        value={initialValue}
        config={config}
        onBlur={(content) => setContent(content)}
      />
    ),
    // eslint-disable-next-line
    [initialValue]
  );
};

const EditorInput = ({
  initialValue,
  value,
  label,
  config,
  onChange,
  errorMessage,
  controlId,
  isDisabled,
  isRequired,
}: IProps) => {
  return (
    <Form.Group controlId={controlId}>
      <Form.Label>
        {label}
        {isRequired && <span className="required">*</span>}
      </Form.Label>
      {isDisabled ? (
        <div dangerouslySetInnerHTML={{ __html: value }}></div>
      ) : (
        <Editor
          initialValue={initialValue}
          value={value}
          config={config}
          onChange={onChange}
        />
      )}
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </Form.Group>
  );
};

export default EditorInput;

EditorInput.defaultProps = {
  label: '',
  value: '',
  initialValue: '',
  errorMessage: '',
  className: '',
  editorConfig: {},
  isDisabled: false,
  isRequired: false,
};
