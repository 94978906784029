import Modal from 'react-bootstrap/Modal';
import FowButton from '../common/FowButton';
import {
  IMissingWillPayload,
  IWillDetailRow,
} from 'src/datatypes/MisssingWill';

interface IProps {
  show: boolean;
  toggleDeleteModal: () => void;
  handleSubmit: () => void;
  selectedIdDetails: IWillDetailRow[];
  deleteWillDetail: IMissingWillPayload['deleteWill'];
}

const DeleteModal = ({
  show,
  toggleDeleteModal,
  handleSubmit,
  selectedIdDetails,
  deleteWillDetail,
}: IProps) => {
  return (
    <>
      <Modal show={show} onHide={toggleDeleteModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Do you want to delete missing will search request?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-will">
            You will delete{' '}
            {selectedIdDetails?.length > 0 &&
              selectedIdDetails.map(
                (willDetail: IWillDetailRow, index: number) => (
                  <span>
                    {willDetail.deceased_name}
                    {selectedIdDetails?.length !== index + 1 && ','}
                  </span>
                )
              )}{' '}
            Missing Will Search Request!
          </div>
          <div></div>
        </Modal.Body>
        <Modal.Footer>
          <FowButton variant="secondary" onClick={toggleDeleteModal}>
            Close
          </FowButton>
          <FowButton
            variant="danger"
            onClick={handleSubmit}
            isLoading={deleteWillDetail?.loading}
          >
            Delete
          </FowButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
