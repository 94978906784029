import DataTable from 'react-data-table-component';
import { Card } from 'react-bootstrap';
import { dtCustomStyle } from '../../helpers/DataTableHelper';
import { IInvoiceDetailRow, IInvoiceDetailColumn } from 'src/datatypes/Invoice';
import { formatLabel } from '../../helpers/common';
import { PageOptions } from 'src/constants/Common';
import moment from 'moment';
import { IDashboardPayload } from 'src/datatypes/Dashboard';
import PageTitle from '../../components/common/PageTitle';
import { useNavigate } from 'react-router-dom';
import NotFound from '../../components/common/NotFound';
import { Link } from 'react-router-dom';

interface IProps {
  invoiceData: IDashboardPayload['dashboardData']['data']['invoiceData'];
  isLoading: IDashboardPayload['dashboardData']['loading'];
}

const InvoiceDashboardListing = ({ invoiceData, isLoading }: IProps) => {
  const navigate = useNavigate();

  const formatStatusLabel = (status: string) => {
    switch (status) {
      case 'approved':
        return <span className="badge badge-success">Approved</span>;
      case 'pending':
        return <span className="badge badge-secondary">Pending</span>;
      case 'failed':
        return <span className="badge badge-danger">Failed</span>;
    }
  };

  const columns: IInvoiceDetailColumn[] = [
    {
      name: '',
      selector: (row: IInvoiceDetailRow) => row['actions'],
      width: '2.5rem',
      center: true,
      cell: (row: IInvoiceDetailRow) => (
        <div className="mx-auto">
          <Link
            to={`/invoices/${row?.id}/view`}
            state={{ source: 'dashboard' }}
            title="View"
            className="btn btn-secondary btn-sm"
            key="view"
          >
            <i className="fa fa-eye"></i>
          </Link>
        </div>
      ),
      right: true,
    },
    {
      name: 'Invoice Number Transaction Id',
      selector: (row: IInvoiceDetailRow) => row['invoice_number'],
      sortable: true,
      width: '10.6rem',
      cell: (row: IInvoiceDetailRow) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{row['invoice_number']}</div>
          <div style={{ color: 'gray' }}>{row['transaction_id'] ?? 'N/A'}</div>
        </div>
      ),
    },
    {
      name: 'Name',
      selector: (row: IInvoiceDetailRow) => row['customer_name'],
      sortable: true,
      compact: true,
    },
    {
      name: 'Payment Price , Type & Status',
      selector: (row: IInvoiceDetailRow) => row['payment_type'],
      sortable: true,
      width: '9.5rem',
      // center: true,
      compact: true,
      cell: (row: IInvoiceDetailRow) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <div>
            <span className="white-space-pre">{row['price']}</span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '5px 0px',
            }}
          >
            <div>{formatLabel(row.payment_type) ?? 'N/A'}</div>
            <div style={{ paddingTop: '5px' }}>
              {formatStatusLabel(row.payment_status) ?? 'N/A'}
            </div>
          </div>
        </div>
      ),
    },
    {
      name: 'Payment date',
      selector: (row: IInvoiceDetailRow) => row['payment_date'],
      sortable: true,
      compact: true,
      cell: (row: IInvoiceDetailRow) => (
        <span className="white-space-pre">
          {moment
            .utc(new Date(row['payment_date']))
            .local()
            .format('MMM DD, YYYY[\r\n]h:mm a')}
        </span>
      ),
    },
  ];

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <PageTitle
          heading="Last Week Invoices"
          buttonName="View All"
          buttonClick={() => {
            navigate('/invoices');
          }}
          border={false}
        />
        <Card>
          <Card.Body className="dashboard-card">
            <div className="dashboard-table">
              {!!invoiceData.data ? (
                <DataTable
                  className="dt-table"
                  persistTableHead
                  fixedHeader
                  fixedHeaderScrollHeight="330px"
                  responsive
                  progressPending={isLoading}
                  keyField="id"
                  pagination
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5, ...PageOptions]}
                  striped
                  highlightOnHover
                  defaultSortFieldId="title"
                  columns={columns}
                  paginationTotalRows={invoiceData.total}
                  data={invoiceData.data}
                  customStyles={dtCustomStyle}
                />
              ) : (
                <NotFound
                  heading="Invoice Details Not Found!"
                  subHeading="Please try again after sometime."
                />
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default InvoiceDashboardListing;
