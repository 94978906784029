import Modal from 'react-bootstrap/Modal';
import FowButton from '../common/FowButton';
import { IPricingPayload, IPricingRow } from 'src/datatypes/Pricing';

interface IProps {
  show: boolean;
  toggleDeleteModal: () => void;
  handleSubmit: () => void;
  selectedIdDetails: IPricingRow[];
  deletePricingDetail: IPricingPayload['deletePricing'];
}

const DeleteModal = ({
  show,
  toggleDeleteModal,
  handleSubmit,
  selectedIdDetails,
  deletePricingDetail,
}: IProps) => {
  return (
    <Modal show={show} onHide={toggleDeleteModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Do you want to delete pricing ?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="delete-template">
          You will delete
          {selectedIdDetails?.length > 0 &&
            selectedIdDetails.map(
              (pricingDetail: IPricingRow, index: number) => (
                <span className="mx-2">
                  {pricingDetail.name}
                  {selectedIdDetails?.length !== index + 1 && ','}
                </span>
              )
            )}
          pricing !
        </div>
        <div></div>
      </Modal.Body>
      <Modal.Footer>
        <FowButton variant="secondary" onClick={toggleDeleteModal}>
          Close
        </FowButton>
        <FowButton
          variant="danger"
          onClick={handleSubmit}
          isLoading={deletePricingDetail?.loading}
        >
          Delete
        </FowButton>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
