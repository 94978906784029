import { toastSuccessMessage } from '../../components/common/ToastMessage';
import PageTitle from '../../components/common/PageTitle';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useNavigate } from 'react-router-dom';
import { ValidateField } from '../../helpers/ValidatorHelper';
import { Button } from 'react-bootstrap';
import { searchFaqField } from 'src/constants/Faq';
import { IShowDeleteFaqModal, IFaqPayload } from 'src/datatypes/Faq';
import { fetchFaqList, resetFaqData } from 'src/redux/slices/faqSlice';
import Listing from 'src/components/faqs/Listing';
import FaqSearch from '../../components/faqs/FaqSearch';

const FaqsListing = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [faqSearch, setFaqSearch] = useState(searchFaqField);

  const [errors, setErrors] = useState(searchFaqField);

  const [showAdvancedSearch, setShowAdvancedSearch] = useState({
    show: false,
    filterApplied: false,
  });

  const [showDeleteModal, setShowDeleteModal] = useState<IShowDeleteFaqModal>({
    show: false,
    idDetails: [],
  });

  const { faqList, deleteFaqStatus } = useAppSelector<IFaqPayload>(
    (state) => state.faqs
  );

  //function to toggle advanced search
  const toggleAdvancedFilter = () => {
    setShowAdvancedSearch({
      ...showAdvancedSearch,
      show: !showAdvancedSearch.show,
    });
  };

  //function to toggle filter applied
  const toggleFilterApplied = (isApplied: boolean) => {
    setShowAdvancedSearch({
      ...showAdvancedSearch,
      filterApplied: isApplied,
    });
  };

  //function to handle search input
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFaqSearch((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const fieldName = name === 'alternative_term' ? 'Alternative Term' : name;
    //check for errors
    const error =
      value === ''
        ? null
        : ValidateField(fieldName, value, String(errors[name]));

    //set errors
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error || '',
    }));
  };

  //function to validate search on submit
  const validateForm = () => {
    const hasValues = Object.keys(faqSearch).some(
      (key) => faqSearch[key] !== ''
    );
    const hasErrors = Object.keys(errors).some((key) => errors[key] !== '');

    return !hasValues || hasErrors;
  };

  //function to handle search button click
  const onFaqSearch = () => {
    const hasError = validateForm();
    if (!hasError) {
      const payload = {
        ...faqSearch,
      };
      dispatch(fetchFaqList({ params: payload }));
      toggleFilterApplied(true);
    }
  };

  // function to handle reset button click
  const resetFaqSearch = () => {
    dispatch(fetchFaqList({ params: {} }));
    setFaqSearch(searchFaqField);
    toggleFilterApplied(false);
  };

  useEffect(() => {
    if (!faqList.loading) {
      dispatch(fetchFaqList({ params: {} }));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (deleteFaqStatus.success) {
      toastSuccessMessage('Faq Deleted Successfully');
      setShowDeleteModal({
        show: false,
        idDetails: [],
      });

      dispatch(resetFaqData());
      dispatch(fetchFaqList({ params: {} }));
    }
    // eslint-disable-next-line
  }, [deleteFaqStatus]);

  return (
    <div className="faqs-templates">
      <PageTitle
        heading="Faqs"
        buttonName="Add New"
        buttonClick={async () => {
          dispatch(resetFaqData());
          navigate('/faqs/new');
        }}
      />
      <div className="control-top-button mb-2">
        <Button
          variant="primary"
          className="mr-2"
          onClick={() => toggleAdvancedFilter()}
        >
          Advanced Search
        </Button>
      </div>
      <div className="faqs-content">
        {showAdvancedSearch.show && (
          <FaqSearch
            errors={errors}
            handleInputChange={handleInputChange}
            faqSearch={faqSearch}
            onFaqSearch={onFaqSearch}
            resetFaqSearch={resetFaqSearch}
            isFilterApplied={showAdvancedSearch.filterApplied}
          />
        )}
        <Listing
          faqList={faqList}
          showDeleteFaqModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deleteFaqDetail={deleteFaqStatus}
        />
      </div>
    </div>
  );
};

export default FaqsListing;
