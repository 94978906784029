import { ValidateField } from '../../helpers/ValidatorHelper';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import FowButton from '../../components/common/FowButton';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { IRouteParams } from 'src/datatypes/CommonTypes';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import _forEach from 'lodash/forEach';

import {
  templateTerminologyDetailsField,
  terminologyDetailsValidations,
} from 'src/constants/Terminology';
import {
  ITerminologyDetails,
  ITerminologyPayload,
} from 'src/datatypes/Terminology';
import {
  fetchTerminologyDetails,
  resetTerminologyData,
  saveUpdateTerminology,
} from 'src/redux/slices/terminologySlice';
import TextInput from '../../components/common/TextInput';
import EditorInput from '../../components/common/EditorInput';

const TerminologiesDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id }: IRouteParams = useParams();
  const location = useLocation();

  const { terminologyDetails, saveUpdateStatus } =
    useAppSelector<ITerminologyPayload>((state) => state.terminologies);

  const [terminologyType, setTerminologyType] = useState<string>('new');

  const [formData, setFormData] = useState<ITerminologyDetails>(
    templateTerminologyDetailsField
  );

  const [errors, setErrors] = useState<ITerminologyDetails>(
    templateTerminologyDetailsField
  );

  const validations = useMemo(() => terminologyDetailsValidations, []);

  // function to handle inputs
  const handleInputs = (e: any) => {
    const { name, value } = e.target;
    const fieldName = name === 'alternate_term' ? 'Alternate Term' : name;

    //check for errors
    const error = ValidateField(fieldName, value, validations[name]);

    //set form data
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    //set errors
    if (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
  };

  // function to check for errors on form submit
  const hasFormError = () => {
    let hasError = false;
    _forEach(formData, (value, key) => {
      //set field name
      const fieldName = key === 'alternate_term' ? 'Alternate Term' : key;

      //check for errors
      const error = ValidateField(fieldName, value, validations[key]);

      //set errors
      if (error) {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: error }));
        hasError = true;
      }
    });

    // return errors status
    return hasError;
  };

  // function to handle form submit
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    //check if form has errors
    if (!hasFormError()) {
      // if no errors call api
      dispatch(saveUpdateTerminology(formData));
    }
  };

  //function to get terminology details and set terminology type
  const getTerminologyDetailsData = async () => {
    // check for id
    if (id && id !== 'new') {
      dispatch(fetchTerminologyDetails(id));

      // check for mode edit / view
      const containsView = location.pathname.includes('view');
      if (containsView) {
        setTerminologyType('view');
      } else {
        setTerminologyType('edit');
      }
    } else {
      // set data for new entry
      setTerminologyType('new');
    }
  };

  // Component to render Edit button in page title if currently in view state.
  const EditButtonComponent = () => {
    if (terminologyType === 'view') {
      return (
        <Button
          variant="primary"
          type="button"
          onClick={() => navigate(`/terminologies/${id}/edit`)}
        >
          Edit
        </Button>
      );
    } else {
      return null;
    }
  };

  // useEffect to get terminology details
  useEffect(() => {
    getTerminologyDetailsData();
    // eslint-disable-next-line
  }, []);

  // check for success and error
  useEffect(() => {
    if (saveUpdateStatus.success) {
      toastSuccessMessage(
        `Terminology Successfully ${
          terminologyType === 'new' ? 'Created' : 'Updated'
        }`
      );
      dispatch(resetTerminologyData());
      navigate('/terminologies');
    }
    if (saveUpdateStatus.error) {
      dispatch(resetTerminologyData());
    }
    // eslint-disable-next-line
  }, [saveUpdateStatus]);

  useEffect(() => {
    if (!terminologyDetails.loading && terminologyDetails?.success) {
      setFormData(terminologyDetails?.data);
    }
    // eslint-disable-next-line
  }, [terminologyDetails]);

  const editorConfig = {
    readonly: false,
    height: 300,
    toolbarButtonSize: 'small' as const,
  };
  return (
    <div className="terminologies-details">
      <PageTitle
        heading={
          terminologyType === 'new'
            ? 'New Terminology'
            : ` Terminology : ${terminologyDetails?.data?.term ?? ''}`
        }
        buttonName="Listing"
        buttonClick={() => navigate('/terminologies')}
        customButton={<EditButtonComponent />}
      />

      {terminologyDetails?.loading ? (
        <Loader isFull />
      ) : !!terminologyDetails ? (
        <Card>
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Body>
              <Row className="align-items-center">
                <Col lg={12} md={12}>
                  <TextInput
                    controlId="term"
                    label="Term"
                    className="form-field"
                    placeholder="Term"
                    name="term"
                    value={formData.term}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.term}
                    isRequired={true}
                    isDisabled={terminologyType === 'view'}
                  />
                </Col>
                <Col lg={12} md={12}>
                  <TextInput
                    controlId="alternate_term"
                    label="Alternate Term"
                    className="form-field"
                    placeholder="Alternate Term"
                    name="alternate_term"
                    value={formData.alternate_term}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.alternate_term}
                    isDisabled={terminologyType === 'view'}
                    isRequired={false}
                  />
                </Col>
                <Col lg={12} md={12}>
                  <EditorInput
                    value={formData.description}
                    config={editorConfig}
                    controlId="section_body"
                    label="Description"
                    errorMessage={errors?.description}
                    onChange={(e: string) => {
                      if (e) {
                        handleInputs({
                          target: {
                            name: 'description',
                            value: e,
                          },
                        });
                      }
                    }}
                    isRequired={true}
                    isDisabled={terminologyType === 'view'}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              {terminologyType !== 'view' && (
                <FowButton
                  variant="primary"
                  onClick={handleSubmit}
                  isLoading={saveUpdateStatus.loading}
                >
                  {terminologyType === 'new' ? 'Save' : 'Update'}
                </FowButton>
              )}
              <FowButton
                variant="secondary"
                onClick={() => {
                  dispatch(resetTerminologyData());
                  navigate('/terminologies');
                }}
                isDisabled={saveUpdateStatus.loading}
              >
                Cancel
              </FowButton>
            </Card.Footer>
          </Form>
        </Card>
      ) : (
        <NotFound
          heading="Terminology Not Found"
          subHeading="Please try again after sometime."
        />
      )}
    </div>
  );
};

export default TerminologiesDetails;
