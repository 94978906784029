import {
  ICharityDetails,
  ICharitySearch,
  ICharityValidations,
} from 'src/datatypes/Charities';
import { OptionType } from 'src/datatypes/CommonTypes';

//template for charity fields
export const templateCharityDetailsField: ICharityDetails = {
  name: '',
  description: '',
  category_id: '',
  state_id: '',
  county_id: '',
  status: 'active',
  logo: '',
};

//template for validations
export const charityDetailsValidations: ICharityValidations = {
  name: 'required|min:2|max:50',
  description: 'required|min:20|max:255',
  category_id: 'required',
  state_id: 'required',
  county_id: 'required',
  status: 'required',
  logo: 'required',
};

//template for status options
export const optionsCharityStatus: OptionType<string>[] = [
  {
    label: 'Active',
    value: 'active',
    disabled: false,
    color: 'btn-success',
  },
  {
    label: 'Inactive',
    value: 'inactive',
    disabled: false,
    color: 'btn-secondary',
  },
];

//template for search charity
export const searchCharityField: ICharitySearch = {
  name: '',
  status: '',
  category_id: '',
  state_id: '',
};

//template for search charity validations
export const charitySearchValidations: ICharitySearch = {
  name: 'nameValidate',
};
