import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../../components/common/PageTitle';
import { useEffect } from 'react';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { IRouteParams } from 'src/datatypes/CommonTypes';
import { IInvoicePayload } from 'src/datatypes/Invoice';
import { fetchInvoiceDetails } from 'src/redux/slices/invoiceSlice';
import moment from 'moment';

const InvoiceDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { id }: IRouteParams = useParams();

  const { invoiceDetails } = useAppSelector<IInvoicePayload>(
    (state) => state.invoice
  );

  useEffect(() => {
    getInvoiceDetailsData();
    // eslint-disable-next-line
  }, []);

  const getInvoiceDetailsData = () => {
    if (id) {
      dispatch(fetchInvoiceDetails(id));
    }
  };

  return (
    <div className="missing-will-details">
      <PageTitle
        heading={`View Invoice : ${
          invoiceDetails?.data?.invoice_information?.invoice_number
            ? invoiceDetails?.data?.invoice_information?.invoice_number
            : ''
        }`}
        buttonName={location.state?.source ? 'Dashboard' : 'Listing'}
        buttonClick={() => navigate(location.state?.source ? '/' : `/invoices`)}
      />

      {invoiceDetails.loading ? (
        <Loader isFull />
      ) : !!invoiceDetails ? (
        <div>
          <table className="missing-will-details-table table table-hover table-bordered">
            <tbody>
              <tr>
                <th colSpan={4}>
                  <div className="table-title">
                    <h4>Invoice Detail</h4>
                  </div>
                </th>
              </tr>
              <tr>
                <th className="text-left">Invoice ID:</th>
                <td width="30%">
                  {invoiceDetails?.data?.invoice_information?.invoice_number}
                </td>
                <th className="text-left">Invoice For:</th>
                <td width="30%">
                  {invoiceDetails?.data?.invoice_information?.invoice_for}
                </td>
              </tr>
              <tr>
                <th className="text-left">Transaction ID (Authorized.Net):</th>
                <td width="30%">
                  {invoiceDetails?.data?.invoice_information?.transaction_id}
                </td>
                <th className="text-left">Payment Type:</th>
                <td width="30%">
                  {invoiceDetails?.data?.invoice_information?.payment_type}
                </td>
              </tr>
              <tr>
                <th className="text-left">Payment Status:</th>
                <td width="30%">
                  {invoiceDetails?.data?.invoice_information?.payment_status}
                </td>
                <th className="text-left">Amount:</th>
                <td width="30%">
                  {invoiceDetails?.data?.invoice_information?.price}
                </td>
              </tr>
              <tr>
                <th className="text-left">Payment Date :</th>
                <td width="30%">
                  <span className="white-space-pre">
                    {moment
                      .utc(
                        new Date(
                          invoiceDetails?.data?.invoice_information?.payment_date
                        )
                      )
                      .local()
                      ?.format('MMM DD, YYYY[\r\n]h:mm a')}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="missing-will-details-table table table-hover table-bordered">
            <tbody>
              <tr>
                <th colSpan={4}>
                  <div className="table-title">
                    <h4>Billing Information</h4>
                  </div>
                </th>
              </tr>
              <tr>
                <th className="text-left">Name:</th>
                <td colSpan={3} width="74%">
                  {invoiceDetails?.data?.billing_invoice?.first_name +
                    ' ' +
                    invoiceDetails?.data?.billing_invoice?.last_name ?? 'N/A'}
                </td>
              </tr>
              <tr>
                <th className="text-left">Email:</th>
                <td width="74%">
                  {invoiceDetails?.data?.billing_invoice?.email}
                </td>
              </tr>
              <tr>
                <th className="text-left" style={{ height: '100px' }}>
                  Address :
                </th>
                <td colSpan={3} width="74%">
                  {invoiceDetails?.data?.billing_invoice?.address_1 ?? ''}
                  {invoiceDetails?.data?.billing_invoice?.address_2
                    ? ', ' + invoiceDetails?.data?.billing_invoice?.address_2
                    : ''}
                  {invoiceDetails?.data?.billing_invoice?.city
                    ? ', ' + invoiceDetails?.data?.billing_invoice?.city
                    : ''}
                  {invoiceDetails?.data?.billing_invoice?.county
                    ? ', ' + invoiceDetails?.data?.billing_invoice?.county
                    : ''}
                  {invoiceDetails?.data?.billing_invoice?.state
                    ? ', ' + invoiceDetails?.data?.billing_invoice?.state
                    : ''}
                  {invoiceDetails?.data?.billing_invoice?.country
                    ? ', ' + invoiceDetails?.data?.billing_invoice?.country
                    : ''}
                  {invoiceDetails?.data?.billing_invoice?.zipcode
                    ? ' - ' + invoiceDetails?.data?.billing_invoice?.zipcode
                    : ''}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="missing-will-details-table table table-hover table-bordered">
            <tbody>
              <tr>
                <th colSpan={4}>
                  <div className="table-title">
                    <h4>Missing Will Search Information</h4>
                  </div>
                </th>
              </tr>
              <tr>
                <th className="text-left">Searcher Email:</th>
                <td width="30%">
                  {
                    invoiceDetails?.data?.will_missing_search_invoice
                      ?.searcher_email
                  }
                </td>
                <th className="text-left">Search PIN:</th>
                <td width="30%">
                  {
                    invoiceDetails?.data?.will_missing_search_invoice
                      ?.search_pin
                  }
                </td>
              </tr>
              <tr>
                <th className="text-left">Transaction Counts:</th>
                <td width="30%">
                  {
                    invoiceDetails?.data?.will_missing_search_invoice
                      ?.transaction_count
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <NotFound
          heading="Will Details Not Found!"
          subHeading="Please try again after sometime."
        />
      )}
    </div>
  );
};

export default InvoiceDetails;
