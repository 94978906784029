import { Dtspinner, dtCustomStyle } from '../../helpers/DataTableHelper';
import { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import {
  IShowDeleteLivingWillModal,
  ILivingWillColumn,
  ILivingWillRow,
  ILivingWillPayload,
  ILivingWillPagination,
} from 'src/datatypes/LivingWill';
import { useAppDispatch } from 'src/redux/hooks';
import DeleteModal from './DeleteModal';
import { deleteLivingWillData } from 'src/redux/slices/livingWillSlice';
import { PageOptions } from 'src/constants/Common';
import moment from 'moment';

interface IProps {
  livingWillList: ILivingWillPayload['livingWillList'];
  deleteLivingWillDetail: ILivingWillPayload['deleteLivingWillStatus'];
  showDeleteModal: IShowDeleteLivingWillModal;
  setShowDeleteModal: React.Dispatch<
    React.SetStateAction<IShowDeleteLivingWillModal>
  >;
  setPagination: React.Dispatch<React.SetStateAction<ILivingWillPagination>>;
}

const Listing = ({
  livingWillList,
  deleteLivingWillDetail,
  showDeleteModal,
  setShowDeleteModal,
  setPagination,
}: IProps) => {
  const dispatch = useAppDispatch();

  //siwtch for delete modal
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeleteLivingWillModal) => ({
      ...prev,
      show: !showDeleteModal.show,
      ...(showDeleteModal.show ? { idDetails: [] } : {}),
    }));
  };

  // selected testimonial
  const selectedTestimonialId = (rows: ILivingWillRow[]) => {
    setShowDeleteModal((prev: IShowDeleteLivingWillModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  // handle delete testimonial
  const handleDelete = () => {
    const selectedIds = showDeleteModal.idDetails
      .map((testimonialDetails: ILivingWillRow) => testimonialDetails.id)
      .join(',');
    dispatch(deleteLivingWillData(selectedIds));
  };

  // handle delete modal open
  const openDeleteModal = (row: ILivingWillRow) => {
    selectedTestimonialId([row]);
    toggleDeleteModal();
  };

  //columns for listing
  const columns: ILivingWillColumn[] = [
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: ILivingWillRow) => row.actions,
      center: true,
      compact: true,
      width: '6.5rem',
      cell: (row: ILivingWillRow) => (
        <div className="mx-auto" style={{ display: 'flex', gap: '10px' }}>
          <a
            title="View"
            className="btn btn-secondary btn-sm"
            href={`/living-will/${row?.id}`}
            key="view"
          >
            <i className="fa fa-eye"></i>
          </a>
          <Button
            title="Delete"
            className="btn btn-danger btn-sm"
            key="delete"
            onClick={() => openDeleteModal(row)}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
    {
      id: 'user_name',
      name: 'Full Name',
      selector: (row: ILivingWillRow) => row.user_name,
      sortable: true,
    },
    {
      id: 'email',
      name: 'Email',
      selector: (row: ILivingWillRow) => row.email,
      sortable: true,
    },
    {
      id: 'will_code',
      name: 'Will Code',
      selector: (row: ILivingWillRow) => row.will_code,
      sortable: true,
    },
    {
      id: 'loaction',
      name: 'Location',
      selector: (row: ILivingWillRow) => row.location,
      sortable: true,
    },
    {
      id: 'created_at',
      name: 'Created On',
      selector: (row: ILivingWillRow) => row.created_at,
      sortable: false,
      width: '9.5rem',
      right: true,
      cell: (row: ILivingWillRow) => (
        <span className="white-space-pre">
          {moment
            .utc(moment(row['created_at']))
            .local()
            ?.format('MMM DD, YYYY[\r\n]h:mm a')}
        </span>
      ),
    },
  ];

  // handle delete modal close on success delete
  useEffect(() => {
    if (deleteLivingWillDetail?.success) {
      setShowDeleteModal((prev: IShowDeleteLivingWillModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deleteLivingWillDetail?.success]);

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={livingWillList.loading}
              keyField="id"
              progressComponent={<Dtspinner />}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              paginationServer
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={livingWillList.data?.total}
              data={livingWillList.data?.data}
              customStyles={dtCustomStyle}
              onChangePage={(page: number) =>
                setPagination((prev: ILivingWillPagination) => ({
                  ...prev,
                  page: page,
                }))
              }
              onChangeRowsPerPage={(new_per_page: number) =>
                setPagination((prev: ILivingWillPagination) => ({
                  ...prev,
                  per_page: new_per_page,
                }))
              }
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteModal.show}
          selectedIdDetails={showDeleteModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteLivingWillDetail={deleteLivingWillDetail}
        />
      </div>
    </div>
  );
};

export default Listing;
