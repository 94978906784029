import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../../components/common/PageTitle';
import { useEffect } from 'react';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { fetchMissingWillSearchDetails } from 'src/redux/slices/missingWillSlice';
import { IRouteParams } from 'src/datatypes/CommonTypes';
import { IMissingWillPayload } from 'src/datatypes/MisssingWill';

const MissingWillSearchDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { id }: IRouteParams = useParams();

  const { willDetails } = useAppSelector<IMissingWillPayload>(
    (state) => state.missingWill
  );

  useEffect(() => {
    getWillDetailsData();
    // eslint-disable-next-line
  }, []);

  const getWillDetailsData = () => {
    if (id) {
      dispatch(fetchMissingWillSearchDetails(id));
    }
  };

  return (
    <div className="missing-will-details">
      <PageTitle
        heading={`View Missing Will Request From : ${
          willDetails?.data?.searcher?.first_name
            ? willDetails?.data?.searcher?.first_name
            : ''
        } ${
          willDetails?.data?.searcher?.last_name
            ? willDetails?.data?.searcher?.last_name
            : ''
        }`}
        buttonName={location.state?.source ? 'Dashboard' : 'Listing'}
        buttonClick={() =>
          navigate(location.state?.source ? '/' : `/missing-will-searches`)
        }
      />

      {willDetails.loading ? (
        <Loader isFull />
      ) : !!willDetails ? (
        <div>
          <table className="missing-will-details-table table table-hover table-bordered">
            <tbody>
              <tr>
                <th colSpan={4}>
                  <div className="table-title">
                    <h4>Searcher Information</h4>
                  </div>
                </th>
              </tr>
              <tr>
                <th className="text-left">Name:</th>
                <td width="30%" colSpan={3}>
                  {willDetails?.data?.searcher?.first_name +
                    ' ' +
                    willDetails?.data?.searcher?.last_name ?? 'N/A'}
                </td>
              </tr>
              <tr>
                <th className="text-left">Phone:</th>
                <td width="30%">{willDetails?.data?.searcher?.phone}</td>
                <th className="text-left">Email:</th>
                <td width="30%">{willDetails?.data?.searcher?.email}</td>
              </tr>
              <tr>
                <th className="text-left" style={{ height: '100px' }}>
                  Address :
                </th>
                <td width="30%" colSpan={3}>
                  {willDetails?.data?.searcher?.address_1 ?? ''}
                  {willDetails?.data?.searcher?.address_2
                    ? ', ' + willDetails?.data?.searcher?.address_2
                    : ''}
                  {willDetails?.data?.searcher?.city
                    ? ', ' + willDetails?.data?.searcher?.city
                    : ''}
                  {willDetails?.data?.searcher?.county_name
                    ? ', ' + willDetails?.data?.searcher?.county_name
                    : ''}
                  {willDetails?.data?.searcher?.state_name
                    ? ', ' + willDetails?.data?.searcher?.state_name
                    : ''}
                  {willDetails?.data?.searcher?.country
                    ? ', ' + willDetails?.data?.searcher?.country
                    : ''}
                  {willDetails?.data?.searcher?.zipcode
                    ? ' - ' + willDetails?.data?.searcher?.zipcode
                    : ''}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="missing-will-details-table table table-hover table-bordered">
            <tbody>
              <tr>
                <th colSpan={4}>
                  <div className="table-title">
                    <h4>Deceased Information</h4>
                  </div>
                </th>
              </tr>
              <tr>
                <th className="text-left">First Name:</th>
                <td width="30%">{willDetails?.data?.deceased?.first_name}</td>
                <th className="text-left">Last Name:</th>
                <td width="30%">{willDetails?.data?.deceased?.last_name}</td>
              </tr>
              <tr>
                <th className="text-left">Birth Date:</th>
                <td width="30%">{willDetails?.data?.deceased?.d_birth_date}</td>
                <th className="text-left">State</th>
                <td width="30%">{willDetails?.data?.deceased?.state}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <NotFound
          heading="Will Details Not Found!"
          subHeading="Please try again after sometime."
        />
      )}
    </div>
  );
};

export default MissingWillSearchDetails;
