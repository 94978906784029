import React from 'react';
import DataTable from 'react-data-table-component';
import { Card } from 'react-bootstrap';
import { dtCustomStyle, Dtspinner } from '../../helpers/DataTableHelper';
import { IWillPagination } from 'src/datatypes/MisssingWill';
import {
  IObituaryPagination,
  IObituaryPayload,
  IObituaryDetailRow,
  IObituaryDetailColumn,
} from 'src/datatypes/Obituaries';
import { useAppDispatch } from 'src/redux/hooks';
import { deleteDeathNoticeDetails } from 'src/redux/slices/deathNoticeSlice';
import { PageOptions } from 'src/constants/Common';
import moment from 'moment';
import _startCase from 'lodash/startCase';

interface IProps {
  obituaryList: IObituaryPayload['obituaryList'];
  setPagination: React.Dispatch<React.SetStateAction<IObituaryPagination>>;
}

const ObituaryList = ({ obituaryList, setPagination }: IProps) => {
  const dispatch = useAppDispatch();
  const columns: IObituaryDetailColumn[] = [
    {
      name: 'Name',
      selector: (row: IObituaryDetailRow) => row['name'],
      sortable: true,
      cell: (row: IObituaryDetailRow) =>
        _startCase(`${row?.first_name ?? ''} ${row?.last_name ?? ''}`.trim()),
    },
    {
      name: 'Location',
      selector: (row: IObituaryDetailRow) => row['location'],
      sortable: true,
    },
    {
      name: 'Birth Date',
      selector: (row: IObituaryDetailRow) => row['birthdate'],
      sortable: true,
      right: true,
    },
    {
      name: 'Death Date',
      selector: (row: IObituaryDetailRow) => row['deathdate'],
      sortable: true,
      right: true,
    },
    {
      name: 'Created On',
      selector: (row: IObituaryDetailRow) => row['posted_date'],
      sortable: true,
      right: true,
      cell: (row: IObituaryDetailRow) => (
        <span className="white-space-pre">
          {moment
            .utc(moment(row['posted_date']))
            .local()
            ?.format('MMM DD, YYYY[\r\n]h:mm a')}
        </span>
      ),
    },
    {
      name: 'Actions',
      selector: (row: IObituaryDetailRow) => row['actions'],
      cell: (row: IObituaryDetailRow) => (
        <div className="mx-auto" style={{ display: 'flex', gap: '10px' }}>
          <a
            title="View"
            className="btn btn-secondary btn-sm"
            href={`/obituaries/${row?.id}/view`}
            key="view"
          >
            <i className="fa fa-eye"></i>
          </a>
          <a
            title="Edit"
            className="btn btn-primary btn-sm"
            href={`/obituaries/${row.id}/edit`}
            key="edit"
          >
            <i className="fa fa-pencil"></i>
          </a>
          <button
            title="Delete"
            className="btn btn-danger btn-sm"
            key="delete"
            onClick={() => {
              deleteDeathNoticeDetail(row);
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
      center: true,
    },
  ];

  const deleteDeathNoticeDetail = async (row: IObituaryDetailRow) => {
    dispatch(deleteDeathNoticeDetails(String(row.id)));
  };

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h2>Obituaries List</h2>
          </Card.Header>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={obituaryList.loading}
              keyField="id"
              progressComponent={<Dtspinner />}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              paginationServer
              highlightOnHover
              columns={columns}
              paginationTotalRows={obituaryList?.data?.total}
              onChangeRowsPerPage={(new_per_page: number) =>
                setPagination((prev: IWillPagination) => ({
                  ...prev,
                  per_page: new_per_page,
                }))
              }
              data={obituaryList.data?.data}
              paginationDefaultPage={obituaryList.data.current_page}
              customStyles={dtCustomStyle}
              onChangePage={(page: number) =>
                setPagination((prev: IWillPagination) => ({
                  ...prev,
                  page: page,
                }))
              }
            />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default ObituaryList;
