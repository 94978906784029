import {
  ISearchWillField, 
} from 'src/datatypes/MisssingWill';

export const searchWillField: ISearchWillField = {
  s_first_name: '',
  s_last_name: '',
  s_email: '',
  d_first_name: '',
  d_last_name: '',
  city: '',
  state: '',
  county: '',
  zipcode: '',
  d_birth_date: '',
  created_from: '',
  created_to: '',
};

export const searchMissingWillValidations: ISearchWillField = {
  s_first_name: 'min:2',
  s_last_name: 'min:2',
  s_email: 'email',
  d_first_name: 'min:2',
  d_last_name: 'min:2',
  city: 'min:2',
  state: '',
  county: '',
  zipcode: 'zipcode',
  d_birth_date: 'birthdate|maxAge:115',
  created_from: 'date:MM-DD-YYYY',
  created_to: 'date:MM-DD-YYYY|compareDateMore',
};

export const willListPagination = {
  per_page: 50,
  page: 1,
};
