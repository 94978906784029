import DataTable from 'react-data-table-component';
import { Card } from 'react-bootstrap';
import { dtCustomStyle } from '../../helpers/DataTableHelper';
import { PageOptions } from 'src/constants/Common';
import moment from 'moment';
import { IDashboardPayload } from 'src/datatypes/Dashboard';
import PageTitle from '../../components/common/PageTitle';
import { useNavigate } from 'react-router-dom';
import { IWillStatusColumn, IWillStatusRow } from 'src/datatypes/WillStatus';
import NotFound from '../../components/common/NotFound';
import { Link } from 'react-router-dom';

interface IProps {
  willCreatorsData: IDashboardPayload['dashboardData']['data']['willCreatorsData'];
  isLoading: IDashboardPayload['dashboardData']['loading'];
}

const WillCreatorDashboardListing = ({
  willCreatorsData,
  isLoading,
}: IProps) => {
  const navigate = useNavigate();

  const columns: IWillStatusColumn[] = [
    {
      name: '',
      center: true,
      selector: (row: IWillStatusRow) => row['actions'],
      width: '2.5rem',
      cell: (row: IWillStatusRow) => (
        <div className="mx-auto">
          <Link
            to={`/will-creators/${row?.id}`}
            state={{ source: 'dashboard' }}
            title="View"
            className="btn btn-secondary btn-sm"
            key="view"
          >
            <i className="fa fa-eye"></i>
          </Link>
        </div>
      ),
      right: true,
    },
    {
      id: 'user_name',
      name: 'Full Name',
      selector: (row: IWillStatusRow) => row.last_name,
      sortable: true,
      width: '13rem',
      cell: (row: IWillStatusRow) => {
        return `${row.first_name} ${row.middle_name} ${row.last_name}`;
      },
    },
    {
      name: 'Contact Details',
      selector: (row: IWillStatusRow) => row['first_name'],
      sortable: true,
      width: '13rem',
      cell: (row: IWillStatusRow) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '3px 0px',
          }}
        >
          <a
            href={`mailto:${row['email']}`}
            style={{ color: 'black', textDecoration: 'none' }}
          >
            {row['email']}
          </a>
          <div style={{ color: 'gray' }}>
            {row['phone'] !== '' && row['phone'] ? row['phone'] : 'N/A'}
          </div>
        </div>
      ),
    },
    {
      id: 'status',
      name: 'Status',
      selector: (row: IWillStatusRow) => row.status,
      sortable: true,
      center: true,
      width: '4.2rem',
      compact: true,
      cell: (row: IWillStatusRow) => {
        switch (row?.status) {
          case 'finished':
            return <span className="badge badge-success">Finished</span>;
          case 'in_progress':
            return <span className="badge badge-secondary">In Progress</span>;
          default:
            return <span className="badge badge-warning">Not yet started</span>;
        }
      },
    },
    {
      name: 'Created At',
      selector: (row: IWillStatusRow) => row['created_at'],
      sortable: true,
      cell: (row: IWillStatusRow) => (
        <span className="white-space-pre">
          {moment
            .utc(moment(row['created_at']))
            .local()
            ?.format('MMM DD, YYYY[\r\n]h:mm a')}
        </span>
      ),
      width: '8.3rem',
      right: true,
    },
  ];

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <PageTitle
          heading="Last Week Will Creators"
          buttonName="View All"
          buttonClick={() => {
            navigate('/will-creators');
          }}
          border={false}
        />
        <Card>
          <Card.Body className="dashboard-card">
            <div className="dashboard-table">
              {!!willCreatorsData.data ? (
                <DataTable
                  className="dt-table"
                  persistTableHead
                  fixedHeader
                  fixedHeaderScrollHeight="330px"
                  responsive
                  progressPending={isLoading}
                  keyField="id"
                  pagination
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5, ...PageOptions]}
                  striped
                  highlightOnHover
                  defaultSortFieldId="title"
                  columns={columns}
                  paginationTotalRows={willCreatorsData.total}
                  data={willCreatorsData.data}
                  customStyles={dtCustomStyle}
                />
              ) : (
                <NotFound
                  heading="Will Details Not Found!"
                  subHeading="Please try again after sometime."
                />
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default WillCreatorDashboardListing;
