import { deleteEmptyKeys } from '../../helpers/common';
import { reformatLivingWillData } from '../../helpers/LivingWillHelper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useLivingWillApi from 'src/api/livingWill.api';
import {
  ILivingWillDetails,
  ILivingWillLocation,
  ILivingWillPayload,
} from 'src/datatypes/LivingWill';

const initialState: ILivingWillPayload = {
  livingWillList: {
    loading: false,
    error: false,
    success: false,
    data: {
      data: [],
      current_page: 0,
      first_page_url: '',
      from: 0,
      last_page: 0,
      path: '',
      per_page: 0,
      prev_page_url: '',
      to: 0,
      total: 0,
    },
  },
  livingWillDetails: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  deleteLivingWillStatus: { loading: false, success: false, error: false },
};

export const livingWillSlice = createSlice({
  name: 'livingWill',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetLivingWillData: (state: ILivingWillPayload) => {
      state.deleteLivingWillStatus = initialState.deleteLivingWillStatus;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchLivingWillList.pending, (state) => {
        state.livingWillList.loading = true;
        state.livingWillList.error = false;
        state.livingWillList.success = false;
      })
      .addCase(fetchLivingWillList.fulfilled, (state, { payload }) => {
        state.livingWillList.data = payload?.livingWills;
        state.livingWillList.loading = false;
        state.livingWillList.success = true;
      })
      .addCase(fetchLivingWillList.rejected, (state) => {
        state.livingWillList.loading = false;
        state.livingWillList.error = true;
      })
      .addCase(fetchLivingWillDetails.pending, (state) => {
        state.livingWillDetails.loading = true;
        state.livingWillDetails.error = false;
        state.livingWillDetails.success = false;
        state.livingWillDetails.data = initialState.livingWillDetails.data;
      })
      .addCase(fetchLivingWillDetails.fulfilled, (state, { payload }) => {
        state.livingWillDetails.data = payload;
        state.livingWillDetails.loading = false;
        state.livingWillDetails.success = true;
      })
      .addCase(deleteLivingWillData.pending, (state) => {
        state.deleteLivingWillStatus.loading = true;
        state.deleteLivingWillStatus.success = false;
        state.deleteLivingWillStatus.error = false;
      })
      .addCase(deleteLivingWillData.fulfilled, (state) => {
        state.deleteLivingWillStatus.loading = false;
        state.deleteLivingWillStatus.success = true;
      })
      .addCase(deleteLivingWillData.rejected, (state) => {
        state.deleteLivingWillStatus.loading = false;
        state.deleteLivingWillStatus.error = true;
      });
  },
});

function convertDateString(dateString: string) {
  // Split the date string by "/"
  const parts = dateString.split('/');

  // Ensure we have three parts (day, month, year)
  if (parts.length !== 3) {
    throw new Error('Invalid date format');
  }

  // Reformat the date parts and join them with "-"
  const year = parts[2].length === 4 ? parts[2] : '20' + parts[2];
  const month = parts[0].length === 1 ? '0' + parts[0] : parts[0];
  const day = parts[1].length === 1 ? '0' + parts[1] : parts[1];

  return `${year}-${month}-${day}`;
}

// Async API Hooks

/**
 * Fetch Living Will List from API and save in redux store
 */
export const fetchLivingWillList = createAsyncThunk(
  'livingWill/fetchLivingWillList',
  async (data: any, { rejectWithValue }) => {
    data.params = deleteEmptyKeys(data.params);

    // convert date string to api payload format.
    if (data.params['created_from']) {
      data.params['created_from'] = convertDateString(
        data.params['created_from']
      );
    }
    if (data.params['created_to']) {
      data.params['created_to'] = convertDateString(data.params['created_to']);
    }

    const response = await useLivingWillApi().getLivingWillList(data.params);
    // The value we returns becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Delete Living Will Details
 */
export const deleteLivingWillData = createAsyncThunk(
  'livingWill/deleteLivingWillData',
  async (id: string, { rejectWithValue }) => {
    const response = await useLivingWillApi().deleteLivingWillData(id);
    // The value we returns becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Fetch Living Will Details from API and save in redux store
 */
export const fetchLivingWillDetails = createAsyncThunk(
  'livingWill/fetchLivingWillDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useLivingWillApi().getLivingWillDetails(id);

    if (response.status === 200) {
      const livingWill = JSON.parse(JSON.stringify(response?.data?.livingWIll));

      const data: ILivingWillDetails = {
        id: livingWill?.id,
        will_code: livingWill?.living_wills?.will_code,
        beneficiaries: livingWill?.livingWillBeneficiaries,
        personalInfo: livingWill?.personalInfo,
        willLocations: [],
      };

      const willLocations = [
        { ...livingWill?.originalWillLocation },
        { ...livingWill?.duplicateWillLocation },
        { ...livingWill?.trustLocation },
      ];

      const livingWillLocations: ILivingWillLocation[] = [];

      willLocations?.forEach((location: any) => {
        const payload: ILivingWillLocation = reformatLivingWillData(location);
        livingWillLocations.push(payload);
      });

      data.willLocations = livingWillLocations;

      return data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const { resetLivingWillData } = livingWillSlice.actions;

export default livingWillSlice.reducer;
