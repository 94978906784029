import { toastErrorMessage } from '../components/common/ToastMessage';
import axios from 'axios';

const useAxiosService = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiVersion = process.env.REACT_APP_API_VERSION;

  const axiosInstance = axios.create({
    baseURL: `${apiUrl}/${apiVersion}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const errorInterceptor = async (error: {
    response: { data: { errors: any , message ?: string}; status: any };
    message: any;
    config: any;
  }) => {
    // const message = error?.response?.data?.errors || 'something went wrong';
    // check if it's a server error
    // const message = error?.response?.data?.errors || 'something went wrong';
    // check if it's a server error
    if (!error.response) {
      localStorage.removeItem('admin_user');
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    }
    
    if (typeof error?.response?.data?.errors === 'string') {
      toastErrorMessage(`${error?.response?.data?.errors}`);
    } else if (typeof error?.response?.data?.errors === 'object') {
      const errorsArray = Object.values(error?.response?.data?.errors || {});
      errorsArray.forEach((message: any) => toastErrorMessage(message));
    } else {
      toastErrorMessage(error?.response?.data?.message || 'Something went wrong');
    }
    // all the other error responses

    switch (error.response?.status) {
      case 400:
        //router.push('/errors/400');
        console.error(error.response?.status, error.message);
        break;

      case 401:
        localStorage.removeItem('admin_user');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        window.location.href = '/login';

        break;
      default:
        return Promise.reject(error);
    }
  };

  const responseInterceptor = (response: { status: any }) => {
    switch (response.status) {
      case 200:
        break;

      default:
    }
    return response;
  };

  axiosInstance.interceptors.response.use(
    responseInterceptor,
    errorInterceptor
  );

  const getUserHttpClient = (options?: any) => {
    axiosInstance.defaults.responseType = options?.responseType ?? 'json';

    axiosInstance.interceptors.request.use(function (config) {
      // Do something before request is sent
      config.baseURL = `${config.baseURL}`;
      const token = localStorage.getItem('access_token');
      config.headers!['Authorization'] = 'Bearer ' + token;
      return config;
    });

    return axiosInstance;
  };

  const getGuestHttpClient = () => {
    axiosInstance.interceptors.request.use(function (config) {
      config.timeout = 10000;
      return config;
    });
    return axiosInstance;
  };

  return { getUserHttpClient, getGuestHttpClient };
};

export default useAxiosService;
