import React from 'react';
import { Form } from 'react-bootstrap';
import ErrorMessage from './ErrorMessage';
import { OptionType } from 'src/datatypes/CommonTypes';

interface IProps {
  name: string;
  value: string;
  label?: string;
  options: OptionType<string>[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  errorMessage?: string;
  controlId?: string;
  className?: string;
  isDisabled?: boolean;
}

const RadioGroupInput = ({
  name,
  value,
  label,
  options,
  onChange,
  isRequired,
  errorMessage,
  controlId,
  className,
  isDisabled,
}: IProps) => {
  return (
    <Form.Group controlId={controlId}>
      {label && (
        <div className="instruction-title">
          <Form.Label>
            {label}
            {isRequired && <span className="required">*</span>}
          </Form.Label>
        </div>
      )}
      <div className={`btn-group colors ${className}`} data-toggle="buttons">
        {options.map((option, index) => {
          return (
            <label
              key={index}
              className={`d-flex align-item-center btn radio-group-btn ${
                option?.color
              } ${value === option?.value ? 'active' : ''}`}
            >
              <input
                type="radio"
                name={name}
                value={option?.value}
                checked={value === option?.value}
                onChange={onChange}
                disabled={isDisabled || option?.disabled}
              />
              {option.label}
            </label>
          );
        })}
      </div>
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </Form.Group>
  );
};

export default RadioGroupInput;

RadioGroupInput.defaultProps = {
  name: '',
  value: '',
  label: '',
  onChange: () => {},
  isRequired: true,
  errorMessage: '',
  controlId: '',
  className: '',
  isDisabled: false,
};
