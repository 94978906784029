import { ValidateField } from '../../helpers/ValidatorHelper';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { IRouteParams } from '../../datatypes/CommonTypes';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import _forEach from 'lodash/forEach';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import TextInput from '../../components/common/TextInput';
import FileInput from '../../components/common/FileInput';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import SelectInput from '../../components/common/SelectInput';
import { fetchStates } from 'src/redux/slices/locationSlice';
import { ILocationPayload } from 'src/datatypes/Location';
import {
  templateLivingWillFormDetails,
  livingWillFormValidations,
} from '../../constants/LivingWillForms';
import {
  fetchLivingWillFormDetails,
  saveUpdateLivingWillForm,
} from '../../redux/slices/livingWillFormSlice';
import {
  ILivingWillFormPayload,
  ILivingWillFormDetails,
} from '../../datatypes/LivingWillForms';

const LivingWillFormDetails = () => {
  //route related hooks
  const location = useLocation();
  const navigate = useNavigate();
  const { id }: IRouteParams = useParams();

  //redux
  const dispatch = useAppDispatch();

  const { saveUpdateForm, formDetails: livingWillFormDetails } =
    useAppSelector<ILivingWillFormPayload>((state) => state.livingWillForm);
  //get state details from redux
  const stateOptions = useAppSelector<ILocationPayload['states']['data']>(
    (state) => state.location.states.data
  );
  const stateLoading = useAppSelector<
    ILocationPayload['states']['stateLoading']
  >((state) => state.location.states.stateLoading);

  // form related states

  const [livingFormType, setLivingFormType] = useState<'new' | 'edit' | 'view'>(
    'new'
  );
  const [filePreview, setFilePreview] = useState<string>('');

  const [formData, setFormData] = useState<ILivingWillFormDetails>(
    templateLivingWillFormDetails
  );

  const [errors, setErrors] = useState<ILivingWillFormDetails>(
    templateLivingWillFormDetails
  );

  // validations master
  const validations = useMemo(() => {
    return {
      ...livingWillFormValidations,
    };
  }, []);

  //handle file upload to help with file preview button
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const allowedFileExtensions = ['.pdf'];

      // Get the file extension from the file name
      const fileExtension = file.name.substring(file.name.lastIndexOf('.'));

      if (allowedFileExtensions.includes(fileExtension.toLowerCase())) {
        const reader = new FileReader();
        //create and set file preview path
        reader.onload = (event) => {
          if (event.target && typeof event.target.result === 'string') {
            const fileUrl = URL.createObjectURL(file);

            setFilePreview(fileUrl);
          }
        };

        reader.readAsDataURL(file);

        handleInputs(e);
      } else {
        setFormData((prevData) => ({
          ...prevData,
          pdf_name: '',
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          pdf_name: 'Please upload a valid file type (pdf).',
        }));
        setFilePreview('');
      }
    }
  };

  // initialize api call on page load for edit / view mode
  const getLivingWillFormDetailsData = () => {
    if (id && id !== 'new') {
      dispatch(fetchLivingWillFormDetails(id));
      if (location.pathname.includes('edit')) {
        setLivingFormType('edit');
      } else {
        setLivingFormType('view');
      }
    }
  };

  // get the display name for the input
  const getDisplayName = (key: string) => {
    switch (key) {
      case 'site_title':
        return 'Site Title';
      case 'page_title':
        return 'Page Title';
      case 'meta_title':
        return 'Meta Title';
      case 'meta_description':
        return 'Meta Description';
      case 'state':
        return 'State';
      case 'pdf_name':
        return 'PDF Form';
      default:
        return key;
    }
  };

  // handle form inputs
  const handleInputs = (e: any) => {
    const name = e.target.name;
    let value = e.target.value;

    //input handling for non-file inputs
    if (name !== 'pdf_name') {
      //implement display name
      const fieldName = getDisplayName(name);
      const error = ValidateField(fieldName, value, validations[name]);

      // set form data
      setFormData((prevData) => ({ ...prevData, [name]: value }));

      //set errors
      if (error) {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      }
    } else {
      // input handling for file
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      setFormData((prevData) => ({ ...prevData, [name]: e.target.files[0] }));
    }
  };

  // check for form errors on submission
  const hasFormErrors = () => {
    let hasErrors = false;
    _forEach(formData, (value, key) => {
      if (key !== 'pdf_name') {
        const error = ValidateField(key, value, validations[key]);
        if (error) {
          setErrors((prevErrors) => ({ ...prevErrors, [key]: error }));
          hasErrors = true;
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, [key]: '' }));
        }
      } else {
        if (
          livingFormType === 'new' &&
          !(formData[key] instanceof File) &&
          formData[key] !== null
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [key]: 'Please upload a valid file type (pdf).',
          }));
          hasErrors = true;
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, [key]: '' }));
        }
      }
    });

    return hasErrors;
  };

  // handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!hasFormErrors()) {
      let payload = { data: { ...formData }, id: id };
      // check here if in case of edit it takes id from url

      dispatch(saveUpdateLivingWillForm(payload));
    }
  };

  // Component to render Edit button in page title if currently in view state.
  const EditButtonComponent = () => {
    if (livingFormType === 'view') {
      return (
        <Button
          variant="primary"
          type="button"
          onClick={() => navigate(`/living-will-forms/${id}/edit`)}
        >
          Edit
        </Button>
      );
    } else {
      return null;
    }
  };

  //on page load
  useEffect(() => {
    setFormData(templateLivingWillFormDetails);
    setErrors(templateLivingWillFormDetails);

    getLivingWillFormDetailsData();
    dispatch(fetchStates());
    // eslint-disable-next-line
  }, []);

  // on successful update / save
  useEffect(() => {
    if (saveUpdateForm?.success) {
      toastSuccessMessage(
        `Living Will Form ${
          livingFormType === 'edit' ? 'updated' : 'created'
        } successfully!`
      );
      navigate('/living-will-forms');
    }
    // eslint-disable-next-line
  }, [saveUpdateForm]);

  // on successful form details fetch
  useEffect(() => {
    if (livingWillFormDetails?.success && livingWillFormDetails?.data) {
      const payload = JSON.parse(JSON.stringify(livingWillFormDetails.data));

      payload.state = payload.state_id;
      delete payload.state_id;
      delete payload.state_name;
      //set form data
      setFormData(payload);

      //set file preview
      if (livingWillFormDetails.data.pdf_name) {
        setFilePreview(String(livingWillFormDetails.data.pdf_name));
      }

      //set errors
      setErrors(templateLivingWillFormDetails);
    }
  }, [livingWillFormDetails]);

  return (
    <div className="living-will-form-details">
      <PageTitle
        heading={
          livingFormType === 'new'
            ? 'Create Living Will Form'
            : `Living Will Form : ${formData.page_title}`
        }
        buttonName="Listing"
        buttonClick={() => navigate('/living-will-forms')}
        customButton={<EditButtonComponent />}
      />

      {livingWillFormDetails.loading ? (
        <Loader isFull />
      ) : !!livingWillFormDetails ? (
        <Card className="">
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Body>
              <Row>
                <Col lg={6} md={12}>
                  <Row>
                    <Col lg={12} md={12}>
                      <TextInput
                        controlId="site_title"
                        label="Site Title"
                        className="form-field"
                        placeholder="Site Title"
                        name="site_title"
                        value={formData.site_title}
                        onChange={(e) => handleInputs(e)}
                        errorMessage={errors?.site_title}
                        isRequired={true}
                        isDisabled={livingFormType === 'view'}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12}>
                      <TextInput
                        controlId="page_title"
                        label="Page Title"
                        className="form-field"
                        placeholder="Page Title"
                        name="page_title"
                        value={formData.page_title}
                        onChange={(e) => handleInputs(e)}
                        errorMessage={errors?.page_title}
                        isRequired={true}
                        isDisabled={livingFormType === 'view'}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12}>
                      <TextInput
                        controlId="meta_title"
                        label="Meta Title"
                        className="form-field"
                        placeholder="Meta Title"
                        name="meta_title"
                        value={formData.meta_title}
                        onChange={(e) => handleInputs(e)}
                        errorMessage={errors?.meta_title}
                        isRequired={true}
                        isDisabled={livingFormType === 'view'}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12}>
                      <TextInput
                        controlId="meta_description"
                        label="Meta Description"
                        className="form-field"
                        placeholder="Meta Description"
                        name="meta_description"
                        value={formData.meta_description}
                        onChange={(e) => handleInputs(e)}
                        errorMessage={errors?.meta_description}
                        isRequired={false}
                        type="textarea"
                        rows={4}
                        isDisabled={livingFormType === 'view'}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {livingFormType !== 'view' && (
                      <Col lg={6} md={6}>
                        <FileInput
                          controlId="pdf_name"
                          label="PDF Form"
                          className="form-field"
                          placeholder="Document"
                          name="pdf_name"
                          value={formData.pdf_name}
                          onChange={(e) => handleFileChange(e)}
                          errorMessage={String(errors?.pdf_name)}
                          isRequired={livingFormType === 'new'}
                          acceptFiles=".pdf"
                        />
                      </Col>
                    )}
                    <Col
                      lg={livingFormType === 'view' ? 12 : 6}
                      md={livingFormType === 'view' ? 12 : 6}
                    >
                      <SelectInput
                        name="state"
                        placeholder="Select State"
                        value={formData.state}
                        optionLabel="state_name"
                        optionValue="id"
                        options={stateOptions}
                        isLoading={stateLoading}
                        handleSelectChange={(e) => handleInputs(e)}
                        label="State"
                        isRequired={true}
                        isDisabled={livingFormType === 'view'}
                      />
                    </Col>
                  </Row>
                </Col>
                {/*  Preview pdf file. */}
                <Col lg={6} md={12} className="pdf-preview-section">
                  {filePreview ? (
                    <div className="pdf-preview-wrapper">
                      <iframe
                        src={filePreview}
                        width="100%"
                        height="100%"
                        className="pdf-preview-iframe"
                        title="pdf-preview"
                      />
                    </div>
                  ) : (
                    <div className={'pdf-preview-NA'}>
                      Document Not Available
                    </div>
                  )}
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Row className="align-items-center">
                <Col lg={12} md={12}>
                  {livingFormType !== 'view' && (
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={saveUpdateForm?.loading}
                      style={{ marginRight: '10px' }}
                    >
                      {livingFormType === 'new' ? 'Save' : 'Update'}
                    </Button>
                  )}
                  <Button
                    variant="secondary"
                    type="link"
                    href="/living-will-forms"
                    disabled={saveUpdateForm?.loading}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Form>
        </Card>
      ) : (
        <NotFound
          heading="Testimonial Details"
          subHeading="Testimonial Details not found"
        />
      )}
    </div>
  );
};

export default LivingWillFormDetails;
