import {
  IGeneratePinCode,
  IInvoiceField,
  ISearchPinCode,
} from 'src/datatypes/Invoice';

export const searchInvoiceField: IInvoiceField = {
  invoice_number: '',
  transaction_id: '',
  first_name: '',
  last_name: '',
  email: '',
  payment_status: '',
  payment_date_from: '',
  payment_date_to: '',
};

export const invoiceValidations: IInvoiceField = {
  invoice_number: 'min:2',
  transaction_id: '',
  first_name: 'nameValidate',
  last_name: 'nameValidate',
  email: 'email',
  payment_status: '',
  payment_date_from: 'date:MM-DD-YYYY',
  payment_date_to: 'date:MM-DD-YYYY|compareDateMore',
};

export const invoiceListPagination = {
  per_page: 50,
  page: 1,
};

export const paymentStatusOptions = [
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Approved',
    value: 'approved',
  },
  {
    label: 'Failed',
    value: 'failed',
  },
];

export const initialFindSearchPinCodeField: ISearchPinCode = {
  transaction_id: '',
  first_name: '',
  last_name: '',
  email: '',
};

export const findSearchPinCodeFieldValidations: ISearchPinCode = {
  transaction_id: 'min:2',
  first_name: 'nameValidate|min:2',
  last_name: 'nameValidate|min:2',
  email: 'email',
};

export const initialGenerateSearchPinCodeField: IGeneratePinCode = {
  update_search_count: false,
  transaction_count: 0,
};

export const generatePinCodeFieldValidations: IGeneratePinCode = {
  update_search_count: '',
  transaction_count: 'minNumber:0|maxNumber:3',
};
