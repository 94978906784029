import Modal from 'react-bootstrap/Modal';
import FowButton from '../common/FowButton';
import { IPagesPayload, IPageRow } from 'src/datatypes/Pages';

interface IProps {
  show: boolean;
  toggleDeleteModal: () => void;
  handleSubmit: () => void;
  selectedIdDetails: IPageRow[];
  deletePageDetail: IPagesPayload['deletePageStatus'];
}

const DeleteModal = ({
  show,
  toggleDeleteModal,
  handleSubmit,
  selectedIdDetails,
  deletePageDetail,
}: IProps) => {
  return (
    <>
      <Modal show={show} onHide={toggleDeleteModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Do you want to delete page?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-page">
            You will delete
            {selectedIdDetails?.length > 0 &&
              selectedIdDetails.map((page: IPageRow, index: number) => (
                <span className="mx-2">
                  {page.title}
                  {selectedIdDetails?.length !== index + 1 && ','}
                </span>
              ))}
            page!
          </div>
          <div></div>
        </Modal.Body>
        <Modal.Footer>
          <FowButton variant="secondary" onClick={toggleDeleteModal}>
            Cancel
          </FowButton>
          <FowButton
            variant="danger"
            onClick={handleSubmit}
            isLoading={deletePageDetail.loading}
          >
            Delete
          </FowButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
