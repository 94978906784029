import { ValidateField } from '../../helpers/ValidatorHelper';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  templateObituaryDetails,
  obituaryValidations,
} from '../../constants/Obituaries';
import { IRouteParams } from '../../datatypes/CommonTypes';
import { IObituaryDetails, IObituaryPayload } from '../../datatypes/Obituaries';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  fetchObituaryDetails,
  updateObituaryDetails,
} from '../../redux/slices/obituariesSlice';
import _forEach from 'lodash/forEach';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import TextInput from '../../components/common/TextInput';
import FileInput from '../../components/common/FileInput';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import SelectInput from '../../components/common/SelectInput';
import { fetchStates } from '../../redux/slices/locationSlice';
import { ILocationPayload } from '../../datatypes/Location';
import _mapValues from 'lodash/mapValues';

const ObituaryDetails = () => {
  //route related hooks
  const location = useLocation();
  const navigate = useNavigate();
  const { id }: IRouteParams = useParams();

  //redux
  const dispatch = useAppDispatch();

  const { obituaryDetails, saveUpdateStatus } =
    useAppSelector<IObituaryPayload>((state) => state.obituaries);

  //get state details from redux
  const stateOptions = useAppSelector<ILocationPayload['states']['data']>(
    (state) => state.location.states.data
  );
  const stateLoading = useAppSelector<
    ILocationPayload['states']['stateLoading']
  >((state) => state.location.states.stateLoading);

  //form related states

  const [obituaryType, setObituaryType] = useState('view');
  const [filePreview, setFilePreview] = useState<string>('');

  const [formData, setFormData] = useState<IObituaryDetails>({
    ...templateObituaryDetails,
  });

  const [errors, setErrors] = useState<IObituaryDetails>({
    ...templateObituaryDetails,
  });

  //validations master
  const validations = useMemo(() => {
    return {
      ...obituaryValidations,
    };
  }, []);

  //handle file upload to help with file preview
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const allowedFileExtensions = ['.png', '.jpeg', '.jpg'];

      // Get the file extension from the file name
      const fileExtension = file.name.substring(file.name.lastIndexOf('.'));

      if (allowedFileExtensions.includes(fileExtension.toLowerCase())) {
        const reader = new FileReader();

        //create and set file preview path
        reader.onload = (event) => {
          if (event.target && typeof event.target.result === 'string') {
            setFilePreview(event.target.result);
          }
        };

        reader.readAsDataURL(file);

        handleInputs(e);
      } else {
        setFormData((prevData) => ({
          ...prevData,
          death_img: '',
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          death_img: 'Please upload a valid file type (jpeg, jpg, png).',
        }));
        setFilePreview(formData.death_image ?? '');
      }
    }
  };

  //initialize api call on page load for edit / view mode
  const getObituaryDetails = () => {
    if (id) {
      dispatch(fetchObituaryDetails(id));
      if (location.pathname.includes('edit')) {
        setObituaryType('edit');
      }
    }
  };

  //handle form inputs
  const handleInputs = (e: any) => {
    const name = e.target.name;
    let value = e.target.value;

    //input handling for non-file inputs
    if (name !== 'death_img') {
      const error = ValidateField(name, value ?? '', validations[name]);
      setFormData((prevData) => ({ ...prevData, [name]: value }));

      if (error) {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      }
    } else {
      //input handling for file
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      setFormData((prevData) => ({ ...prevData, [name]: e.target.files[0] }));
    }
  };

  // check for form errors on submission
  const hasFormError = () => {
    let hasErrors = false;
    _forEach(formData, (value, key) => {
      if (key === 'death_img') {
        if (!(formData[key] instanceof File) && formData[key] !== null) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [key]: 'Please upload a valid file type (jpeg, jpg, png).',
          }));
          hasErrors = true;
        }
      } else {
        const error = ValidateField(key, value ?? '', validations[key]);
        if (error) {
          setErrors((prevErrors) => ({ ...prevErrors, [key]: error }));
          hasErrors = true;
        }
      }
    });

    return hasErrors;
  };

  // handle form submission
  const handleSubmit = async (e: React.FocusEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!hasFormError()) {
      let payload: any = formData;
      //check here if in case of edit it takes id from url
      if (obituaryType === 'edit') {
        payload = _mapValues(payload, (v) => (v === null ? '' : v));
        delete payload['death_image'];
        dispatch(updateObituaryDetails(payload));
      }
    }
  };

  //handle cancel button click
  const handleCancelClick = () => {
    //clear forms and errors
    setFormData({
      ...templateObituaryDetails,
    });
    setErrors({
      ...templateObituaryDetails,
    });

    navigate('/obituaries');
  };

  // Component to render Edit button in page title if currently in view state.
  const EditButtonComponent = () => {
    if (obituaryType === 'view') {
      return (
        <Button
          variant="primary"
          type="button"
          onClick={() => navigate(`/obituaries/${id}/edit`)}
        >
          Edit
        </Button>
      );
    } else {
      return null;
    }
  };
  useEffect(() => {
    if (stateLoading || stateOptions.length === 0) {
      dispatch(fetchStates());
    }
  }, [stateLoading, stateOptions, dispatch]);

  //handle setting up states on page load
  useEffect(() => {
    setFormData({
      ...templateObituaryDetails,
    });
    if (templateObituaryDetails.death_img) {
      setFilePreview(String(templateObituaryDetails.death_img));
    }
    getObituaryDetails();
  }, [id]);

  const formatDate = (date: string) => {
    if (date) {
      return new Date(date).toISOString().split('T')[0];
    }
  };

  //handle setting up states on edit / view mode after API call
  useEffect(() => {
    //handle successful api call
    if (obituaryDetails?.success) {
      //set form data
      setFormData({
        ...obituaryDetails?.data,
        deathdate: formatDate(obituaryDetails?.data?.deathdate ?? ''),
        birthdate: formatDate(obituaryDetails?.data?.birthdate ?? ''),
      });

      //set url for profile picture preview if it exists
      if (obituaryDetails?.data?.death_image) {
        setFilePreview(String(obituaryDetails.data.death_image));
      }
    } else if (obituaryDetails?.error) {
      // handle failed api call

      setFormData({
        ...templateObituaryDetails,
      });

      navigate('/obituaries');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, obituaryDetails, obituaryDetails?.success]);

  //handle successful save / update api call
  useEffect(() => {
    if (saveUpdateStatus.success) {
      toastSuccessMessage(`
      Obituary ${obituaryType === 'new' ? 'created' : 'updated'} successfully
      `);

      navigate('/obituaries');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveUpdateStatus]);

  useEffect(() => {
    if (id) {
      dispatch(fetchObituaryDetails(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="testimonial-details profile-details">
      <PageTitle
        heading={`${
          obituaryType === 'edit' ? 'Edit Obituary' : 'Obituary'
        }: ${id}`}
        buttonName="Listing"
        buttonClick={() => navigate('/obituaries')}
        customButton={<EditButtonComponent />}
      />

      {obituaryDetails.loading ? (
        <Loader isFull />
      ) : !!obituaryDetails ? (
        <Card className="">
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Body>
              <Row className="align-items-center">
                <Col lg={4} md={12} sm={12}>
                  <TextInput
                    controlId="first_name"
                    label="First Name"
                    className="form-field"
                    placeholder="First Name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.first_name}
                    isDisabled={obituaryType === 'view'}
                  />
                </Col>
                <Col lg={4} md={12} sm={12}>
                  <TextInput
                    controlId="middle_name"
                    label="Middle Name"
                    className="form-field"
                    placeholder="Middle Name"
                    name="middle_name"
                    value={formData.middle_name ?? ''}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.middle_name}
                    isDisabled={obituaryType === 'view'}
                  />
                </Col>
                <Col lg={4} md={12} sm={12}>
                  <TextInput
                    controlId="last_name"
                    label="Last Name"
                    className="form-field"
                    placeholder="Last name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.last_name}
                    isDisabled={obituaryType === 'view'}
                  />
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <TextInput
                    controlId="birthdate"
                    label="Birth Date"
                    className="form-field"
                    placeholder="MM/DD/YYYY"
                    name="birthdate"
                    type="date"
                    value={formData.birthdate}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.birthdate}
                    isDisabled={obituaryType === 'view'}
                  />
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <TextInput
                    controlId="deathdate"
                    label="Death Date"
                    className="form-field"
                    placeholder="MM/DD/YYYY"
                    name="deathdate"
                    type="date"
                    value={formData.deathdate}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.deathdate}
                    isDisabled={obituaryType === 'view'}
                  />
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <TextInput
                    controlId="city"
                    label="City of Death"
                    className="form-field"
                    placeholder="City"
                    name="city"
                    value={formData.city}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.city}
                    isDisabled={obituaryType === 'view'}
                  />
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <SelectInput
                    name="state"
                    placeholder="State of Death"
                    value={formData.state_id}
                    optionLabel="state_name"
                    optionValue="id"
                    options={stateOptions}
                    isLoading={stateLoading}
                    handleSelectChange={(e) => handleInputs(e)}
                    label="State of Death"
                    isDisabled={obituaryType === 'view'}
                  />
                </Col>
                <Col lg={6} md={12} sm={12}>
                  {obituaryType === 'edit' && (
                    <FileInput
                      controlId="death_img"
                      label="Photo"
                      className="form-field"
                      placeholder="Photo"
                      name="death_img"
                      value={formData.death_img}
                      onChange={(e) => handleFileChange(e)}
                      errorMessage={String(errors?.death_img)}
                    />
                  )}
                </Col>
                <Col
                  lg={6}
                  md={12}
                  sm={12}
                  className="profile-pic-logo-section"
                >
                  {filePreview ? (
                    <div className="profile-pic-logo-wrapper">
                      <img
                        src={filePreview}
                        className="profile-pic-logo-image"
                      />
                    </div>
                  ) : (
                    <div className="profile-pic-logo-image-NA">
                      Image Not Available
                    </div>
                  )}
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <TextInput
                    controlId="obituary"
                    label="Write the obituary below"
                    className="form-field"
                    name="obituary"
                    value={formData.obituary}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.obituary}
                    isRequired={true}
                    type="textarea"
                    rows={4}
                    isDisabled={obituaryType === 'view'}
                  />
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <TextInput
                    controlId="charity"
                    label="Charitable Donations(optional)"
                    className="form-field"
                    name="charity"
                    value={formData.charity}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.charity}
                    isRequired={false}
                    type="textarea"
                    rows={4}
                    isDisabled={obituaryType === 'view'}
                  />
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <Row>
                    <Col lg={12} md={12}>
                      <hr />
                      <h6>
                        Name and Email address of individual submitting this
                        obituary.
                      </h6>
                    </Col>
                    <Col lg={6} md={6}>
                      <TextInput
                        controlId="contact_name"
                        label="Contact Name"
                        className="form-field"
                        placeholder="Contact Name"
                        name="contact_name"
                        value={formData.contact_name}
                        onChange={(e) => handleInputs(e)}
                        errorMessage={errors?.contact_name}
                        isDisabled={obituaryType === 'view'}
                        isRequired={true}
                      />
                    </Col>
                    <Col lg={6} md={6}>
                      <TextInput
                        controlId="contact_email"
                        label="Email Address"
                        className="form-field"
                        placeholder="Email address"
                        name="contact_email"
                        value={formData.contact_email}
                        onChange={(e) => handleInputs(e)}
                        errorMessage={errors.contact_email}
                        isRequired={true}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col lg={12} md={12}>
                  <Card.Footer>
                    {obituaryType !== 'view' && (
                      <Button
                        variant="primary"
                        className="mr-1"
                        type="submit"
                        disabled={saveUpdateStatus.loading}
                      >
                        {obituaryType === 'new' ? 'Create' : 'Update'}
                      </Button>
                    )}
                    <Button
                      variant="secondary"
                      onClick={handleCancelClick}
                      disabled={saveUpdateStatus.loading}
                    >
                      {obituaryType === 'view' ? 'Close' : 'Cancel'}
                    </Button>
                  </Card.Footer>
                </Col>
              </Row>
            </Card.Body>
          </Form>
        </Card>
      ) : (
        <NotFound
          heading="Testimonial Details"
          subHeading="Testimonial Details not found"
        />
      )}
    </div>
  );
};

export default ObituaryDetails;
