import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useStateCounty from '../../api/stateCounty.api';
import { ILocationPayload } from '../../datatypes/Location';

const initialState: ILocationPayload = {
  countyLoading: false,
  states: {
    data: [],
    stateLoading: false,
    error: false,
    stateDetails: {
      data: {
        state_name: '',
        state_abbr: '',
      },
      loading: false,
      success: false,
      error: false,
    },
    saveUpdateStatus: {
      loading: false,
      success: false,
      error: false,
    },
    deleteStateStatus: {
      loading: false,
      success: false,
      error: false,
    },
  },
  counties: [],
  error: false,
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetStateDetailsData: (state: ILocationPayload) => {
      state.states.stateDetails = initialState.states.stateDetails;
      state.states.saveUpdateStatus = initialState.states.saveUpdateStatus;
      state.states.deleteStateStatus = initialState.states.deleteStateStatus;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchStates.pending, (state) => {
        state.states.stateLoading = true;
      })
      .addCase(fetchStates.fulfilled, (state, { payload }) => {
        state.states.data = payload;
        state.states.stateLoading = false;
      })
      .addCase(fetchStates.rejected, (state) => {
        state.states.stateLoading = false;
        state.states.error = true;
      })
      .addCase(fetchStateDetails.pending, (state) => {
        state.states.stateDetails.loading = true;
        state.states.stateDetails.error = false;
        state.states.stateDetails.success = false;
      })
      .addCase(fetchStateDetails.fulfilled, (state, { payload }) => {
        state.states.stateDetails.data = payload;
        state.states.stateDetails.loading = false;
        state.states.stateDetails.success = true;
      })
      .addCase(fetchStateDetails.rejected, (state) => {
        state.states.stateDetails.loading = false;
        state.states.stateDetails.error = true;
      })
      .addCase(deleteStateDetails.pending, (state) => {
        state.states.deleteStateStatus.loading = true;
        state.states.deleteStateStatus.error = false;
        state.states.deleteStateStatus.success = false;
      })
      .addCase(deleteStateDetails.fulfilled, (state) => {
        state.states.deleteStateStatus.loading = false;
        state.states.deleteStateStatus.error = false;
        state.states.deleteStateStatus.success = true;
      })
      .addCase(deleteStateDetails.rejected, (state) => {
        state.states.deleteStateStatus.loading = false;
        state.states.deleteStateStatus.error = true;
      })
      .addCase(fetchCounties.pending, (state) => {
        state.countyLoading = true;
      })
      .addCase(fetchCounties.fulfilled, (state, { payload }) => {
        state.counties = payload;
        state.countyLoading = false;
      })
      .addCase(fetchCounties.rejected, (state) => {
        state.countyLoading = false;
        state.error = true;
      });
  },
});

// Async API Hooks
/**
 * Fetch states from API and save in redux store
 */
export const fetchStates = createAsyncThunk(
  'location/fetchStates',
  async () => {
    const response = await useStateCounty().getStates();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

/**
 * Fetch State Details from API and save in redux store
 */
export const fetchStateDetails = createAsyncThunk(
  'location/fetchStateDetails',
  async (state: string, { rejectWithValue }) => {
    const response = await useStateCounty().getStateDetails(state);
    // The value we return becomes the `fulfilled` action payload
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

/**
 * Delete single State API
 */
export const deleteStateDetails = createAsyncThunk(
  'location/deleteStateDetails',
  async (state: string, { rejectWithValue }) => {
    const response = await useStateCounty().deleteStateDetails(state);
    // The value we return becomes the `fulfilled` action payload
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

/**
 * Fetch Counties for the selected state from API and save in redux store
 */
export const fetchCounties = createAsyncThunk(
  'location/fetchCounties',
  async (state: string) => {
    const response = await useStateCounty().getCounties(state);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const { resetStateDetailsData } = locationSlice.actions;

export default locationSlice.reducer;
