import { toastSuccessMessage } from '../../components/common/ToastMessage';
import PageTitle from '../../components/common/PageTitle';
import { useEffect, useState } from 'react';
import { IShowDeletePagesModal, IPagesPayload } from 'src/datatypes/Pages';
import { resetPagesData, fetchPagesList } from 'src/redux/slices/pagesSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useNavigate } from 'react-router-dom';
import Listing from '../../components/pages/Listing';

const PagesListing = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { pagesList, deletePageStatus } = useAppSelector<IPagesPayload>(
    (state) => state.pages
  );

  const [showDeletePagesModal, setShowDeletePagesModal] =
    useState<IShowDeletePagesModal>({
      show: false,
      idDetails: [],
    });

  const getPagesListData = () => {
    dispatch(fetchPagesList());
  };

  useEffect(() => {
    getPagesListData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (deletePageStatus.success) {
      toastSuccessMessage('Page deleted successfully!');
      setShowDeletePagesModal({
        show: false,
        idDetails: [],
      });
      dispatch(resetPagesData());
      getPagesListData();
    }
    // eslint-disable-next-line
  }, [deletePageStatus]);

  return (
    <div className="pages-listing">
      <PageTitle
        heading="Pages"
        buttonName="Create Page"
        buttonClick={async () => {
          dispatch(resetPagesData());
          navigate('/pages/new');
        }}
      />
      <div className="pages-listing-content">
        <Listing
          pageList={pagesList}
          showDeleteModal={showDeletePagesModal}
          setShowDeleteModal={setShowDeletePagesModal}
          deletePageDetail={deletePageStatus}
        />
      </div>
    </div>
  );
};

export default PagesListing;
