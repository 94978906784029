import { dtCustomStyle } from '../../helpers/DataTableHelper';
import { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import {
  IShowDeleteQuestionModal,
  IQuestionRow,
  IQuestionColumn,
  IQuestionPayload,
} from 'src/datatypes/Questions';
import { useAppDispatch } from 'src/redux/hooks';
import DeleteModal from './DeleteModal';
import { deleteQuestion } from 'src/redux/slices/questionsSlice';
import { PageOptions } from 'src/constants/Common';

interface IProps {
  questionsList: IQuestionPayload['questionList'];
  deleteQuestionDetail: IQuestionPayload['deleteQuestionStatus'];
  showDeleteModal: IShowDeleteQuestionModal;
  setShowDeleteModal: React.Dispatch<
    React.SetStateAction<IShowDeleteQuestionModal>
  >;
}

const Listing = ({
  questionsList,
  deleteQuestionDetail,
  showDeleteModal,
  setShowDeleteModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  //siwtch for delete modal
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeleteQuestionModal) => ({
      ...prev,
      show: !showDeleteModal.show,
      ...(showDeleteModal.show ? { idDetails: [] } : {}),
    }));
  };

  // selected question
  const selectedQuestionId = (rows: IQuestionRow[]) => {
    setShowDeleteModal((prev: IShowDeleteQuestionModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  // handle delete question
  const handleDelete = () => {
    const selectedIds = showDeleteModal.idDetails
      .map((questionDetail: IQuestionRow) => questionDetail.id)
      .join(',');
    dispatch(deleteQuestion(selectedIds));
  };

  // handle delete modal open
  const openDeleteModal = (row: IQuestionRow) => {
    selectedQuestionId([row]);
    toggleDeleteModal();
  };

  //columns for Listing
  const columns: IQuestionColumn[] = [
    {
      id: 'question_code',
      name: 'Question Code',
      selector: (row: IQuestionRow) => row.question_code,
      sortable: true,
      compact: true,
      width: '8.5rem',
    },
    {
      id: 'page_id',
      name: 'Page ID',
      selector: (row: IQuestionRow) => row.page_id,
      sortable: true,
      compact: true,
      width: '5rem',
    },
    {
      id: 'question_text',
      name: 'Question Text',
      selector: (row: IQuestionRow) => row.question_text,
      sortable: true,
      width: '36rem',
    },
    {
      id: 'status',
      name: 'Status',
      selector: (row: IQuestionRow) => row.status,
      sortable: true,
      compact: true,
      width: '5rem',
      cell: (row: IQuestionRow) => {
        switch (row?.status) {
          case 'active':
            return <span className="badge badge-success">Active</span>;
          case 'inactive':
            return <span className="badge badge-secondary">Inactive</span>;
          default:
            return <span className="badge badge-warning">Archive</span>;
        }
      },
    },
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: IQuestionRow) => row.actions,
      center: true,
      compact: true,
      width: '8.5rem',
      cell: (row: IQuestionRow) => (
        <div className="mx-auto" style={{ display: 'flex', gap: '10px' }}>
          <a
            title="View"
            className="btn btn-secondary btn-sm"
            href={`/questions/${row?.id}/view`}
            key="view"
          >
            <i className="fa fa-eye"></i>
          </a>
          <a
            title="Edit"
            className="btn btn-primary btn-sm"
            href={`/questions/${row.id}/edit`}
            key="edit"
          >
            <i className="fa fa-pencil"></i>
          </a>
          <Button
            title="Delete"
            className="btn btn-danger btn-sm"
            key="delete"
            onClick={() => openDeleteModal(row)}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
  ];

  // handle delete modal close on success delete
  useEffect(() => {
    if (deleteQuestionDetail?.success) {
      setShowDeleteModal((prev: IShowDeleteQuestionModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deleteQuestionDetail?.success]);

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={questionsList.loading}
              keyField="id"
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={questionsList.data?.total}
              data={questionsList.data?.data}
              customStyles={dtCustomStyle}
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteModal.show}
          selectedIdDetails={showDeleteModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteQuestionDetail={deleteQuestionDetail}
        />
      </div>
    </div>
  );
};

export default Listing;
