import { toastSuccessMessage } from '../../components/common/ToastMessage';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useDeathNoticesApi from 'src/api/deathNotice.api';
import { IObituaryPayload, IObituaryDetails } from 'src/datatypes/Obituaries';

const initialState: IObituaryPayload = {
  obituaryList: {
    loading: false,
    data: {
      data: [],
      current_page: 0,
      first_page_url: '',
      from: 0,
      last_page: 0,
      path: '',
      per_page: 0,
      prev_page_url: '',
      to: 0,
      total: 0,
    },
    error: false,
  },
  saveUpdateStatus: {
    loading: false,
    success: false,
    error: false,
  },
  obituaryDetails: { loading: false, success: false, data: null, error: false },
  deleteObituaryDetails: { loading: false, success: false, error: false },
};

export const obituariesSlice = createSlice({
  name: 'obituaries',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetDeleteObituaryData: (state) => {
      state.saveUpdateStatus = initialState.saveUpdateStatus;
      state.deleteObituaryDetails = initialState.deleteObituaryDetails;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchObituaryList.pending, (state) => {
        state.obituaryList.loading = true;
      })
      .addCase(fetchObituaryList.fulfilled, (state, { payload }) => {
        state.obituaryList.data = payload?.obituries;
        state.obituaryList.loading = false;
      })
      .addCase(fetchObituaryList.rejected, (state) => {
        state.obituaryList.loading = false;
        state.obituaryList.error = true;
      })
      .addCase(fetchObituaryDetails.pending, (state) => {
        state.obituaryDetails.loading = true;
      })
      .addCase(fetchObituaryDetails.fulfilled, (state, { payload }) => {
        state.obituaryDetails.data = payload?.obituary;
        state.obituaryDetails.loading = false;
        state.obituaryDetails.success = true;
      })
      .addCase(fetchObituaryDetails.rejected, (state) => {
        state.obituaryDetails.loading = false;
        state.obituaryDetails.error = true;
      })
      .addCase(updateObituaryDetails.pending, (state) => {
        state.saveUpdateStatus.loading = true;
        state.saveUpdateStatus.error = false;
        state.saveUpdateStatus.success = false;
      })
      .addCase(updateObituaryDetails.fulfilled, (state) => {
        state.saveUpdateStatus.success = true;
        state.saveUpdateStatus.error = false;
        state.saveUpdateStatus.loading = false;
      })
      .addCase(updateObituaryDetails.rejected, (state) => {
        state.saveUpdateStatus.loading = false;
        state.saveUpdateStatus.error = true;
        state.saveUpdateStatus.success = false;
      })
      .addCase(deleteObituary.pending, (state) => {
        state.deleteObituaryDetails.loading = true;
        state.deleteObituaryDetails.success = false;
        state.deleteObituaryDetails.error = false;
      })
      .addCase(deleteObituary.fulfilled, (state) => {
        state.deleteObituaryDetails.loading = false;
        state.deleteObituaryDetails.success = true;
        state.deleteObituaryDetails.error = false;
      })
      .addCase(deleteObituary.rejected, (state) => {
        state.deleteObituaryDetails.loading = false;
        state.deleteObituaryDetails.error = true;
      });
  },
});

/**
 * Fetch Obituary List from API and save in redux store
 */
export const fetchObituaryList = createAsyncThunk(
  'obituaries/fetchObituaryList',
  async (data: any, { rejectWithValue }) => {
    const response = await useDeathNoticesApi().getObituaryList(data.params);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.status === 201) {
      return response?.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

/**
 * Fetch Obituary Details from API and save in redux store
 */
export const fetchObituaryDetails = createAsyncThunk(
  'obituaries/fetchObituaryDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useDeathNoticesApi().getObituaryDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.status === 201) {
      return response?.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const updateObituaryDetails = createAsyncThunk(
  'obituaries/updateObituaryDetails',
  async (data: IObituaryDetails, { rejectWithValue }) => {
    if (typeof data['death_img'] !== 'object') {
      delete data['death_img'];
    }
    const id = data.id;
    delete data.id;
    const response = await useDeathNoticesApi().updateObituaryDetails(
      data,
      String(id)
    );
    // The value we returns becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Delete Obituary Details from API and remove from redux store
 */
export const deleteObituary = createAsyncThunk(
  'obituaries/deleteDeathNoticeDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useDeathNoticesApi().deleteObituaryDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.status === 201) {
      toastSuccessMessage('Successfully Deleted');
      return response?.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const { resetDeleteObituaryData } = obituariesSlice.actions;

export default obituariesSlice.reducer;
