import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import usePagesApi from 'src/api/pages.api';
import { IPagesPayload, ISaveUpdatePage } from 'src/datatypes/Pages';

// initial / template state of pages redux slice
const initialState: IPagesPayload = {
  pagesList: {
    loading: false,
    success: false,
    error: false,
    data: {
      current_page: 0,
      total: 0,
      data: [],
    },
  },
  pageDetails: {
    loading: false,
    error: false,
    success: false,
    data: null,
  },
  saveUpdatePage: {
    loading: false,
    error: false,
    success: false,
  },
  deletePageStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

// redux slice for pages
export const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  // reducers that don't require API calls
  reducers: {
    resetPagesData: (state: IPagesPayload) => {
      state.pageDetails = initialState.pageDetails;
      state.saveUpdatePage = initialState.saveUpdatePage;
      state.deletePageStatus = initialState.deletePageStatus;
    },
  },
  // reducers that require API calls
  extraReducers: (builder) => {
    builder
      .addCase(fetchPagesList.pending, (state) => {
        state.pagesList.loading = true;
        state.pagesList.error = false;
        state.pagesList.success = false;
        state.pagesList.data = initialState.pagesList.data;
      })
      .addCase(fetchPagesList.fulfilled, (state, { payload }) => {
        state.pagesList.data.data = payload.pages;
        state.pagesList.data.current_page = payload.current_page ?? 0;
        state.pagesList.data.total = payload.total ?? 0;
        state.pagesList.loading = false;
        state.pagesList.error = false;
        state.pagesList.success = true;
      })
      .addCase(fetchPagesList.rejected, (state) => {
        state.pagesList.loading = false;
        state.pagesList.error = true;
        state.pagesList.success = false;
        state.pagesList.data = initialState.pagesList.data;
      })
      .addCase(fetchPageDetails.pending, (state) => {
        state.pageDetails.loading = true;
        state.pageDetails.error = false;
        state.pageDetails.success = false;
        state.pageDetails.data = initialState.pageDetails.data;
      })
      .addCase(fetchPageDetails.fulfilled, (state, { payload }) => {
        state.pageDetails.data = payload.page;
        state.pageDetails.loading = false;
        state.pageDetails.error = false;
        state.pageDetails.success = true;
      })
      .addCase(fetchPageDetails.rejected, (state) => {
        state.pageDetails.loading = false;
        state.pageDetails.error = true;
        state.pageDetails.success = false;
      })
      .addCase(saveUpdatePageDetails.pending, (state) => {
        state.saveUpdatePage.loading = true;
        state.saveUpdatePage.error = false;
        state.saveUpdatePage.success = false;
      })
      .addCase(saveUpdatePageDetails.fulfilled, (state) => {
        state.saveUpdatePage.loading = false;
        state.saveUpdatePage.error = false;
        state.saveUpdatePage.success = true;
      })
      .addCase(saveUpdatePageDetails.rejected, (state) => {
        state.saveUpdatePage.loading = false;
        state.saveUpdatePage.error = true;
        state.saveUpdatePage.success = false;
      })
      .addCase(deletePageDetails.pending, (state) => {
        state.deletePageStatus.loading = true;
        state.deletePageStatus.error = false;
        state.deletePageStatus.success = false;
      })
      .addCase(deletePageDetails.fulfilled, (state) => {
        state.deletePageStatus.loading = false;
        state.deletePageStatus.success = true;
      })
      .addCase(deletePageDetails.rejected, (state) => {
        state.deletePageStatus.loading = false;
        state.deletePageStatus.error = true;
      });
  },
});

// Async API Hooks

/**
 * Fetch Pages List from API and save in redux store
 */
export const fetchPagesList = createAsyncThunk(
  'pages/fetchPagesList',
  async (_, { rejectWithValue }) => {
    const response = await usePagesApi().getPagesList();
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Fetch Page Details from API and save in redux store
 */
export const fetchPageDetails = createAsyncThunk(
  'pages/fetchPageDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await usePagesApi().getPageDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Save / Update Page API
 */
export const saveUpdatePageDetails = createAsyncThunk(
  'pages/saveUpdatePageDetails',
  async (data: ISaveUpdatePage, { rejectWithValue }) => {
    const id = data.id;
    const response = id
      ? await usePagesApi().updatePageDetails(data.data, id)
      : await usePagesApi().savePageDetails(data.data);

    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response?.data);
    }
  }
);

/**
 * Delete Page API
 */
export const deletePageDetails = createAsyncThunk(
  'pages/deletePageDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await usePagesApi().deletePagesData(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const { resetPagesData } = pagesSlice.actions;

export default pagesSlice.reducer;
