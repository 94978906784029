import { ValidateField } from '../../helpers/ValidatorHelper';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  templateAdminUserDetailsField,
  adminUserDetailsValidations,
  genderOptions,
} from 'src/constants/Users';
import { IRouteParams } from 'src/datatypes/CommonTypes';
import { IUserDetails, IUsersPayload } from 'src/datatypes/Users';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import _forEach from 'lodash/forEach';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../components/common/ToastMessage';
import TextInput from '../../components/common/TextInput';
import SelectInput from '../../components/common/SelectInput';
import SelectCountyInput from '../../components/common/SelectCountyInput';
import { fetchStates } from 'src/redux/slices/locationSlice';
import {
  fetchAdminUserDetails,
  saveUpdateAdminUser,
} from 'src/redux/slices/usersSlice';
import { ILocationPayload } from 'src/datatypes/Location';

const UserDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id }: IRouteParams = useParams();
  const location = useLocation();

  const { adminDetails, saveUpdateAdmin } = useAppSelector<
    IUsersPayload['admin']
  >((state) => state.users.admin);

  const { data: stateOptions, stateLoading } = useAppSelector<
    ILocationPayload['states']
  >((state) => state.location.states);

  const [userDetailType, setUserDetailType] = useState('new');

  const [formData, setFormData] = useState<IUserDetails>({
    ...templateAdminUserDetailsField,
  });

  const [errors, setErrors] = useState<IUserDetails>({
    ...templateAdminUserDetailsField,
    country: '',
    gender: '',
  });

  const validations = useMemo(() => {
    return {
      ...adminUserDetailsValidations,
    };
  }, []);

  const handleInputs = (e: any) => {
    const name = e.target.name;
    let value: string | boolean;
    let error: string | undefined | null;

    //get value from checkbox / others
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    let fieldName = name;

    switch (name) {
      case 'state':
        fieldName = 'State';
        break;
      case 'birthdate':
        fieldName = 'Birth Date';
        break;
      case 'address_1':
        fieldName = 'Address';
        break;
      default:
        fieldName = name;
    }

    //check for errors
    error = ValidateField(fieldName, value, validations[name]);
    //set errors
    if (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }

    //update form data
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // final form validation check
  const hasFormError = () => {
    let hasErrors = false;
    _forEach(formData, (value, key) => {
      //set the field name for error string.
      let fieldName = key;
      switch (key) {
        case 'state':
          fieldName = 'State';
          break;
        case 'birthdate':
          fieldName = 'Birth Date';
          break;
        case 'address_1':
          fieldName = 'Address';
          break;
        default:
          fieldName = key;
      }

      //check for errors
      const error = ValidateField(fieldName, value, validations[key]);

      if (error) {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: error }));
        hasErrors = true;
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: '' }));
      }
    });

    return hasErrors;
  };

  //function to handle save / update click
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!hasFormError()) {
      let payload = {
        ...formData,
      };
      if (userDetailType === 'new') {
        dispatch(saveUpdateAdminUser(payload));
      } else {
        payload.id = id;
        dispatch(saveUpdateAdminUser(payload));
      }
    }
  };

  //function to get admin user details
  const getAdminUserDetails = () => {
    if (id && id !== 'new') {
      dispatch(fetchAdminUserDetails(id));

      const containsView = location.pathname.includes('view');
      if (containsView) {
        setUserDetailType('view');
      } else {
        setUserDetailType('edit');
      }
    } else {
      setFormData(templateAdminUserDetailsField);
      setUserDetailType('new');
    }
  };

  useEffect(() => {
    dispatch(fetchStates());
    getAdminUserDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (adminDetails.success) {
      if (adminDetails?.data?.user_type === 'admin' && id !== 'new') {
        const userData: IUserDetails = {
          first_name: adminDetails?.data?.first_name ?? '',
          middle_name: adminDetails?.data?.middle_name ?? '',
          last_name: adminDetails?.data?.last_name ?? '',
          email: adminDetails?.data?.email ?? '',
          password: adminDetails?.data?.password ?? '',
          birthdate: adminDetails?.data?.birthdate ?? '',
          gender: adminDetails?.data?.gender ?? '',
          city: adminDetails?.data?.city ?? '',
          state: adminDetails?.data?.state_id ?? '',
          county: adminDetails?.data?.county_id ?? '',
          country: adminDetails?.data?.country ?? '',
          address_1: adminDetails?.data?.address_1 ?? '',
          address_2: adminDetails?.data?.address_2 ?? '',
          zipcode: adminDetails?.data?.zipcode ?? '',
          phone: adminDetails?.data?.phone ?? '',
          bio: adminDetails?.data?.bio ?? '',
          user_type: adminDetails?.data?.user_type ?? '',
          id: adminDetails?.data?.id ?? '',
          confirmed: 1,
        };
        setFormData(userData);
      } else if (id !== 'new') {
        toastErrorMessage('Invalid Admin Id');
        navigate('/admin-users');
      }
    } else if (adminDetails.error) {
      navigate('/admin-users');
    }
    // eslint-disable-next-line
  }, [adminDetails]);

  useEffect(() => {
    if (saveUpdateAdmin.success) {
      toastSuccessMessage(
        `User ${userDetailType === 'new' ? 'created' : 'updated'} successfully`
      );
      navigate('/admin-users');
    } else if (saveUpdateAdmin.error) {
      toastErrorMessage('Something went wrong.');
    }
    // eslint-disable-next-line
  }, [saveUpdateAdmin]);

  // Component to render Edit button in page title if currently in view state.
  const EditButtonComponent = () => {
    if (userDetailType === 'view') {
      return (
        <Button
          variant="primary"
          type="button"
          onClick={() => navigate(`/admin-users/${id}/edit`)}
        >
          Edit
        </Button>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="user-details profile-details">
      <PageTitle
        heading={
          userDetailType === 'new'
            ? 'Create User'
            : `${
                userDetailType === 'view' ? 'View' : 'Update'
              } User - ${id} - ${adminDetails?.data?.first_name ?? ''} ${
                adminDetails?.data?.last_name ?? ''
              }`
        }
        buttonName="Listing"
        buttonClick={() => {
          navigate('/admin-users');
        }}
        customButton={<EditButtonComponent />}
      />

      {adminDetails.loading ? (
        <Loader isFull />
      ) : !!adminDetails ? (
        <Card>
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Body>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <TextInput
                    controlId="first_name"
                    label="First Name"
                    className="form-field"
                    placeholder="Enter First Name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleInputs}
                    errorMessage={errors.first_name}
                    isRequired={true}
                    isDisabled={userDetailType === 'view'}
                  />
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <TextInput
                    controlId="middle_name"
                    label="Middle Name"
                    className="form-field"
                    placeholder="Enter Middle Name"
                    name="middle_name"
                    value={formData.middle_name}
                    onChange={handleInputs}
                    errorMessage={errors.middle_name}
                    isRequired={false}
                    isDisabled={userDetailType === 'view'}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <TextInput
                    controlId="last_name"
                    label="Last Name"
                    className="form-field"
                    placeholder="Enter Last Name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleInputs}
                    errorMessage={errors.last_name}
                    isRequired={true}
                    isDisabled={userDetailType === 'view'}
                  />
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <SelectInput
                    name="gender"
                    placeholder="Select Gender"
                    value={formData.gender}
                    optionLabel="label"
                    optionValue="value"
                    options={genderOptions}
                    handleSelectChange={(e) => handleInputs(e)}
                    label="Gender"
                    isRequired={true}
                    errorMessage={String(errors?.gender)}
                    isDisabled={userDetailType === 'view'}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={6} sm={12}>
                  <TextInput
                    name="birthdate"
                    placeholder="YYYY-MM-DD"
                    value={formData.birthdate}
                    onChange={handleInputs}
                    label="Birthdate"
                    type="date"
                    errorMessage={errors.birthdate}
                    isRequired={false}
                    isDisabled={userDetailType === 'view'}
                  />
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <TextInput
                    controlId="phone"
                    label="Mobile"
                    className="form-field"
                    placeholder="Enter Mobile"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputs}
                    errorMessage={errors.phone}
                    isRequired={true}
                    isDisabled={userDetailType === 'view'}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={6} sm={12}>
                  <TextInput
                    controlId="email"
                    label="Email"
                    className="form-field"
                    placeholder="Enter Email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputs}
                    errorMessage={errors.email}
                    isRequired={true}
                    isDisabled={userDetailType === 'view'}
                  />
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <TextInput
                    controlId="password"
                    label="Password"
                    className="form-field"
                    placeholder="Enter Password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputs}
                    errorMessage={errors.password}
                    isRequired={true}
                    type="password"
                    isDisabled={userDetailType === 'view'}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <TextInput
                    controlId="bio"
                    label="Bio"
                    className="form-field"
                    placeholder="Enter Bio"
                    name="bio"
                    value={formData.bio}
                    onChange={handleInputs}
                    errorMessage={errors.bio}
                    isRequired={false}
                    type="textarea"
                    isDisabled={userDetailType === 'view'}
                    rows={4}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col lg={12} md={12}>
                  <TextInput
                    controlId="address_1"
                    label="Address 1"
                    className="form-field"
                    placeholder="Enter Address 1"
                    name="address_1"
                    value={formData.address_1}
                    onChange={handleInputs}
                    errorMessage={errors.address_1}
                    isRequired={false}
                    isDisabled={userDetailType === 'view'}
                  />
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <TextInput
                    controlId="address_2"
                    label="Address 2"
                    className="form-field"
                    placeholder="Enter Address 2"
                    name="address_2"
                    value={formData.address_2}
                    onChange={handleInputs}
                    errorMessage={errors.address_2}
                    isRequired={false}
                    isDisabled={userDetailType === 'view'}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <TextInput
                    controlId="city"
                    label="City"
                    className="form-field"
                    placeholder="Enter City"
                    name="city"
                    value={formData.city}
                    onChange={handleInputs}
                    errorMessage={errors.city}
                    isRequired={true}
                    isDisabled={userDetailType === 'view'}
                  />
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <TextInput
                    controlId="zipcode"
                    label="Zip"
                    className="form-field"
                    placeholder="Enter Zip"
                    name="zipcode"
                    value={String(formData.zipcode)}
                    onChange={handleInputs}
                    errorMessage={String(errors.zipcode)}
                    isRequired={false}
                    isDisabled={userDetailType === 'view'}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={6}>
                  <SelectInput
                    name="state"
                    placeholder="Select State"
                    value={formData.state}
                    optionLabel="state_name"
                    optionValue="state_abbr"
                    options={stateOptions}
                    isLoading={stateLoading}
                    handleSelectChange={(e) => handleInputs(e)}
                    label="State"
                    isRequired={true}
                    errorMessage={String(errors?.state)}
                    isDisabled={userDetailType === 'view'}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <SelectCountyInput
                    name="county"
                    placeholder="Select County"
                    value={Number(formData.county)}
                    selectedState={Number(formData.state)}
                    label="County"
                    handleSelectChange={(e) => handleInputs(e)}
                    isRequired={true}
                    errorMessage={String(errors?.county)}
                    isDisabled={userDetailType === 'view'}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <TextInput
                    controlId="country"
                    label="Country"
                    className="form-field"
                    placeholder="Enter Country"
                    name="country"
                    value={formData.country}
                    onChange={handleInputs}
                    errorMessage={errors.country}
                    isRequired={true}
                    isDisabled={true}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              {userDetailType !== 'view' && (
                <Button variant="primary" type="submit">
                  {userDetailType === 'edit' ? 'Update' : 'Create'}
                </Button>
              )}
              <Button variant="secondary" type="link" href="/admin-users">
                Cancel
              </Button>
            </Card.Footer>
          </Form>
        </Card>
      ) : (
        <NotFound
          heading="User Not Found"
          subHeading="Please try again after sometime"
        />
      )}
    </div>
  );
};

export default UserDetails;
