import { ITemplateDetails } from 'src/datatypes/Template';

export const templateDetailsField: ITemplateDetails = {
  title: '',
  slug: '',
  content: '',
  status: '',
  subject: '',
  code: '',
  type: '',
};

export const templateDetailsValidations: ITemplateDetails = {
  title: 'required|min:2',
  slug: 'min:2',
  content: 'required|min:20',
  status: '',
  subject: 'required|min:2',
  code: 'required|min:2',
  type: 'required|min:2',
};

export const templateListPagination = {
  per_page: 50,
  page: 1,
};

export const optionsTypes = [
  {
    label: 'Email',
    value: 'Email',
  },
  {
    label: 'Response',
    value: 'Response',
  },
];
