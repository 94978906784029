import useAxiosService from '../services/axios.service';

const useCharityCategoriesApi = () => {
  const { getUserHttpClient } = useAxiosService();

  //api call for charity categories list
  const getCharityCategoriesList = async () => {
    return await getUserHttpClient()
      .get('/charity-categories')
      .then((response) => response?.data);
  };

  //api call for charity categories by id
  const getCharityCategoriesDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/charity-categories/' + id)
      .then((response) => response?.data);
  };

  //api call for save charity categories
  const saveCharityCategoriesDetails = async (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return await getUserHttpClient()
      .post('/charity-categories', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to FormData
        },
      })
      .then((response) => response);
  };

  //api call for update charity categories
  const updateCharityCategoriesDetails = async (data: any, id: string) => {
    return await getUserHttpClient()
      .put('/charity-categories/' + id, data)
      .then((response) => response?.data);
  };

  //api call for delete charity categories
  const deleteCharityCategoriesDetails = async (id: string) => {
    return await getUserHttpClient()
      .delete('/charity-categories/' + id)
      .then((response) => response?.data);
  };

  return {
    getCharityCategoriesList,
    getCharityCategoriesDetails,
    saveCharityCategoriesDetails,
    updateCharityCategoriesDetails,
    deleteCharityCategoriesDetails,
  };
};

export default useCharityCategoriesApi;
