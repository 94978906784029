import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import useCharityCategoriesApi from 'src/api/charityCategories.api';
import {
  ICharityCategoryDetails,
  ICharityCategoryPayload,
} from 'src/datatypes/CharityCategories';

// initial / template state for charity categories redux slice
const initialState: ICharityCategoryPayload = {
  charityCategoryList: {
    loading: false,
    error: false,
    data: {
      data: [],
      current_page: 0,
      total: 0,
    },
  },
  charityCategoryDetails: {
    loading: false,
    error: false,
    success: false,
    data: {} as ICharityCategoryDetails,
  },
  saveUpdateCharityCategory: {
    loading: false,
    error: false,
    success: false,
  },
  deleteCharityCategory: {
    loading: false,
    error: false,
    success: false,
  },
};

//redux slice for charity categories
export const charityCategoriesSlice = createSlice({
  name: 'charityCategories',
  initialState,
  // reducers that do not require API calls
  reducers: {
    resetCharityCategoryData: (state: ICharityCategoryPayload) => {
      state.charityCategoryDetails = initialState.charityCategoryDetails;
      state.saveUpdateCharityCategory = initialState.saveUpdateCharityCategory;
      state.deleteCharityCategory = initialState.deleteCharityCategory;
    },
  },
  // reducers that require API calls
  extraReducers: (builder) => {
    builder
      .addCase(fetchCharityCategoryList.pending, (state) => {
        state.charityCategoryList.loading = true;
        state.charityCategoryList.error = false;
        state.charityCategoryList.data = initialState.charityCategoryList.data;
      })
      .addCase(fetchCharityCategoryList.fulfilled, (state, { payload }) => {
        state.charityCategoryList.data.data = payload.charityCategories;
        state.charityCategoryList.data.current_page = payload.current_page ?? 0;
        state.charityCategoryList.data.total = payload.total;
        state.charityCategoryList.loading = false;
        state.charityCategoryList.error = false;
      })
      .addCase(fetchCharityCategoryList.rejected, (state) => {
        state.charityCategoryList.loading = false;
        state.charityCategoryList.error = true;
      })
      .addCase(fetchCharityCategoryDetails.pending, (state) => {
        state.charityCategoryDetails.loading = true;
        state.charityCategoryDetails.error = false;
        state.charityCategoryDetails.success = false;
        state.charityCategoryDetails.data =
          initialState.charityCategoryDetails.data;
      })
      .addCase(fetchCharityCategoryDetails.fulfilled, (state, { payload }) => {
        state.charityCategoryDetails.data = payload.charityCategory;
        state.charityCategoryDetails.loading = false;
        state.charityCategoryDetails.success = true;
      })
      .addCase(fetchCharityCategoryDetails.rejected, (state) => {
        state.charityCategoryDetails.loading = false;
        state.charityCategoryDetails.error = true;
      })
      .addCase(saveUpdateCharityCategoryDetails.pending, (state) => {
        state.saveUpdateCharityCategory.loading = true;
        state.saveUpdateCharityCategory.error = false;
        state.saveUpdateCharityCategory.success = false;
      })
      .addCase(saveUpdateCharityCategoryDetails.fulfilled, (state) => {
        state.saveUpdateCharityCategory.loading = false;
        state.saveUpdateCharityCategory.success = true;
      })
      .addCase(saveUpdateCharityCategoryDetails.rejected, (state) => {
        state.saveUpdateCharityCategory.loading = false;
        state.saveUpdateCharityCategory.error = true;
      })
      .addCase(deleteCharityCategoryDetails.pending, (state) => {
        state.deleteCharityCategory.loading = true;
        state.deleteCharityCategory.error = false;
        state.deleteCharityCategory.success = false;
      })
      .addCase(deleteCharityCategoryDetails.fulfilled, (state) => {
        state.deleteCharityCategory.loading = false;
        state.deleteCharityCategory.success = true;
      })
      .addCase(deleteCharityCategoryDetails.rejected, (state) => {
        state.deleteCharityCategory.loading = false;
        state.deleteCharityCategory.error = true;
      });
  },
});

//Async API Hooks

/**
 * Fetch Charity Category List from API and save in redux store
 */
export const fetchCharityCategoryList = createAsyncThunk(
  'charityCategories/fetchCharityCategoryList',
  async (_, { rejectWithValue }) => {
    const response = await useCharityCategoriesApi().getCharityCategoriesList();
    //The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response;
    } else {
      return rejectWithValue(response);
    }
  }
);

/**
 * Fetch Charity Category Details for the from API and save in redux store
 */
export const fetchCharityCategoryDetails = createAsyncThunk(
  'charityCategories/fetchCharityCategoryDetails',
  async (id: string, { rejectWithValue }) => {
    const response =
      await useCharityCategoriesApi().getCharityCategoriesDetails(id);
    //The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response;
    } else {
      return rejectWithValue(response?.data);
    }
  }
);

/**
 * Save/Update  Charity Category Details
 */
export const saveUpdateCharityCategoryDetails = createAsyncThunk(
  'charityCategories/saveUpdateCharityCategory',
  async (data: ICharityCategoryDetails, { rejectWithValue }) => {
    const id = data?.id;
    delete data?.id;
    const response = id
      ? await useCharityCategoriesApi().updateCharityCategoriesDetails(data, id)
      : await useCharityCategoriesApi().saveCharityCategoriesDetails(data);
    //The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response?.data;
    } else {
      return rejectWithValue(response?.data);
    }
  }
);

/**
 * Delete Charity Category API
 */
export const deleteCharityCategoryDetails = createAsyncThunk(
  'charityCategories/deleteCharityCategory',
  async (id: string, { rejectWithValue }) => {
    const response =
      await useCharityCategoriesApi().deleteCharityCategoriesDetails(id);
    //The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response?.data;
    } else {
      return rejectWithValue(response?.data);
    }
  }
);

export const { resetCharityCategoryData } = charityCategoriesSlice.actions;

export default charityCategoriesSlice.reducer;
