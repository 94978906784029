import { toastSuccessMessage } from '../../components/common/ToastMessage';
import PageTitle from '../../components/common/PageTitle';
import { useEffect, useState } from 'react';
import { pricingListPagination } from 'src/constants/Pricing';
import {
  IPricingPagination,
  IShowDeletePricingModal,
  IPricingPayload,
} from 'src/datatypes/Pricing';
import {
  fetchPricingList,
  resetPricingData,
} from 'src/redux/slices/pricingSlice';
import Listing from 'src/components/pricing/Listing';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useNavigate } from 'react-router-dom';

const PricingListing = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [pagination, setPagination] = useState<IPricingPagination>(
    pricingListPagination
  );

  const [showDeleteModal, setShowDeleteModal] =
    useState<IShowDeletePricingModal>({
      show: false,
      idDetails: [],
    });

  const { pricingList, deletePricing } = useAppSelector<IPricingPayload>(
    (state) => state.pricing
  );

  useEffect(() => {
    if (!pricingList.loading) {
      dispatch(fetchPricingList({ params: pagination }));
    }
    // eslint-disable-next-line
  }, [pagination]);

  useEffect(() => {
    if (deletePricing.success) {
      toastSuccessMessage('Pricing Deleted Successfully');
      setShowDeleteModal({
        show: false,
        idDetails: [],
      });
      dispatch(resetPricingData());
      dispatch(fetchPricingList({ params: pagination }));
      setPagination(pricingListPagination);
    }
    // eslint-disable-next-line
  }, [deletePricing]);

  return (
    <div className="pricing-templates">
      <PageTitle
        heading="Pricing"
        buttonName="Add New"
        buttonClick={async () => {
          dispatch(resetPricingData());
          navigate('/pricing/new');
        }}
      />
      <div className="pricing-content">
        <Listing
          pricingList={pricingList}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          setPagination={setPagination}
          deletePricingDetail={deletePricing}
        />
      </div>
    </div>
  );
};

export default PricingListing;
