import { IPricingDetails, IPricingValidations } from 'src/datatypes/Pricing';
import { OptionType } from 'src/datatypes/CommonTypes';

//template for pricing fields
export const templatePricingDetailsField: IPricingDetails = {
  name: '',
  price: 0,
  code: '',
  status: 'active',
};

//template for validations
export const pricingDetailsValidations: IPricingValidations = {
  name: 'required|min:2|max:50',
  price: 'required|minNumber:0|maxNumber:9999999',
  code: 'required|min:2|max:50',
  status: 'required',
};

//template for pagination
export const pricingListPagination = {
  per_page: 50,
  page: 1,
};

//template for status options
export const statusOptions: OptionType<string>[] = [
  {
    label: 'Active',
    value: 'active',
    disabled: false,
    color: 'btn-success',
  },
  {
    label: 'Inactive',
    value: 'inactive',
    disabled: false,
    color: 'btn-secondary',
  },
];
