import { Dtspinner, dtCustomStyle } from '../../helpers/DataTableHelper';
import { useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useAppDispatch } from 'src/redux/hooks';

import {
  IShowDeleteFaqModal,
  IFaqColumn,
  IFaqRow,
  IFaqPayload,
} from 'src/datatypes/Faq';
import { deleteFaq } from 'src/redux/slices/faqSlice';
import DeleteModal from './DeleteModal';
import { PageOptions } from 'src/constants/Common';

//interface for component
interface IProps {
  faqList: IFaqPayload['faqList'];
  deleteFaqDetail: IFaqPayload['deleteFaqStatus'];
  showDeleteFaqModal: IShowDeleteFaqModal;
  setShowDeleteModal: React.Dispatch<React.SetStateAction<IShowDeleteFaqModal>>;
}

const Listing = ({
  faqList,
  deleteFaqDetail,
  showDeleteFaqModal,
  setShowDeleteModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  // switch function for delete modal
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeleteFaqModal) => ({
      ...prev,
      show: !showDeleteFaqModal.show,
      ...(showDeleteFaqModal.show ? { idDetails: [] } : {}),
    }));
  };

  const selectedFaqId = (rows: IFaqRow[]) => {
    setShowDeleteModal((prev: IShowDeleteFaqModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  // function to handle delete
  const handleDelete = () => {
    const selectedIds = showDeleteFaqModal.idDetails
      .map((FaqDetail: IFaqRow) => FaqDetail.id)
      .join(',');
    dispatch(deleteFaq(selectedIds));
  };

  // function to handle open delete modal
  const openDeleteModal = (row: IFaqRow) => {
    selectedFaqId([row]);
    toggleDeleteModal();
  };

  useEffect(() => {
    if (deleteFaqDetail.success) {
      setShowDeleteModal((prev: IShowDeleteFaqModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deleteFaqDetail]);

  const columns: IFaqColumn[] = [
    {
      name: 'Question Text',
      selector: (row: IFaqRow) => row.question_text,
      sortable: true,
    },
    {
      id: 'type',
      name: 'Type',
      selector: (row: IFaqRow) => row.type,
      center: true,
      compact: true,
      cell: (row: IFaqRow) => {
        switch (row?.type) {
          case 'create_will':
            return <span className="badge badge-success">Create a Will</span>;
          case 'find_missing_will':
            return (
              <span className="badge badge-secondary">Find a Missing Will</span>
            );
          case 'will_registration':
            return <span className="badge badge-danger">Register a Will</span>;
          default:
            return <span className="badge badge-warning">General</span>;
        }
      },
    },
    {
      id: 'status',
      name: 'Status',
      selector: (row: IFaqRow) => row.status,
      center: true,
      compact: true,
      cell: (row: IFaqRow) => {
        switch (row?.status) {
          case 'active':
            return <span className="badge badge-success">Active</span>;
          case 'inactive':
            return <span className="badge badge-secondary">Inactive</span>;
          default:
            return <span className="badge badge-warning">Archive</span>;
        }
      },
    },
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: IFaqRow) => row.actions,
      center: true,
      compact: true,
      width: '9.5rem',
      cell: (row: IFaqRow) => (
        <div>
          <a
            title="Edit"
            className="btn btn-primary btn-sm"
            href={`/faqs/${row.id}/edit`}
            key="edit"
          >
            <i className="fa fa-pencil"></i>
          </a>
          <a
            title="View"
            className="btn btn-secondary btn-sm ml-2"
            href={`/faqs/${row.id}/view`}
            key="view"
          >
            <i className="fa fa-eye"></i>
          </a>
          <Button
            title="Delete"
            className="btn btn-danger btn-sm ml-2"
            key="delete"
            onClick={() => openDeleteModal(row)}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Row className="mt-3">
      <Col lg={12}>
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={faqList.loading}
              keyField="id"
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={faqList.data?.total}
              data={faqList.data?.data}
              customStyles={dtCustomStyle}
              progressComponent={<Dtspinner />}
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteFaqModal.show}
          selectedIdDetails={showDeleteFaqModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteFaqDetail={deleteFaqDetail}
        />
      </Col>
    </Row>
  );
};

export default Listing;
