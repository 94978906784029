import SelectInput from '../common/SelectInput';
import MaskInput from '../common/MaskInput';
import TextInput from '../common/TextInput';
import FowButton from '../common/FowButton';
import React, { useEffect } from 'react';
import { IWillStatusSearchField } from 'src/datatypes/WillStatus';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchStates } from '../../redux/slices/locationSlice';
import SelectCountyInput from '../common/SelectCountyInput';
import { ILocationPayload } from 'src/datatypes/Location';

interface IProps {
  searchWill: IWillStatusSearchField;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onWillSearch: () => void;
  resetWillSearch: () => void;
  isFilterApplied: boolean;
  errors: IWillStatusSearchField;
}

const SearchForm = ({
  searchWill,
  handleInputChange,
  onWillSearch,
  resetWillSearch,
  isFilterApplied,
  errors,
}: IProps) => {
  const dispatch = useAppDispatch();

  const stateOptions = useAppSelector<ILocationPayload['states']['data']>(
    (state) => state.location.states.data
  );

  const stateLoading = useAppSelector<
    ILocationPayload['states']['stateLoading']
  >((state) => state.location.states.stateLoading);

  useEffect(() => {
    dispatch(fetchStates());
    // eslint-disable-next-line
  }, []);

  return (
    <div className="will-status-form">
      <div className="row">
        <div className="col-lg-4">
          <TextInput
            name="first_name"
            placeholder="First Name"
            value={searchWill.first_name}
            onChange={(e) => handleInputChange(e)}
            label="First Name"
            errorMessage={errors.first_name}
          />
        </div>
        <div className="col-lg-4">
          <TextInput
            name="last_name"
            placeholder="Last Name"
            value={searchWill.last_name}
            onChange={(e) => handleInputChange(e)}
            label="Last Name"
            errorMessage={errors.last_name}
          />
        </div>
        <div className="col-lg-4">
          <TextInput
            name="email"
            placeholder="Email"
            value={searchWill.email}
            onChange={(e) => handleInputChange(e)}
            label="Email"
            errorMessage={errors.email}
          />
        </div>
        <div className="col-lg-4">
          <TextInput
            name="city"
            placeholder="City"
            value={searchWill.city}
            onChange={(e) => handleInputChange(e)}
            label="City"
            errorMessage={errors.city}
          />
        </div>
        <div className="col-lg-4">
          <SelectInput
            name="state_id"
            placeholder="Select State"
            value={searchWill.state_id}
            optionLabel="state_name"
            optionValue="id"
            options={stateOptions}
            isLoading={stateLoading}
            handleSelectChange={(e) => handleInputChange(e)}
            label="State"
          />
        </div>
        <div className="col-lg-4">
          <SelectCountyInput
            name="county_id"
            placeholder="Select County"
            value={searchWill.county_id}
            selectedState={searchWill.state_id}
            label="County"
            handleSelectChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="col-lg-4">
          <TextInput
            name="zipcode"
            placeholder="Zip Code"
            value={searchWill.zipcode}
            onChange={(e) => handleInputChange(e)}
            label="Zipcode"
            errorMessage={errors.zipcode}
          />
        </div>
        <div className="col-lg-4">
          <MaskInput
            mask="99/99/9999"
            name="created_from"
            placeholder="mm/dd/yyyy"
            value={searchWill.created_from}
            label="Created From"
            onChange={(e) => handleInputChange(e)}
            errorMessage={errors.created_from}
          />
        </div>
        <div className="col-lg-4">
          <MaskInput
            mask="99/99/9999"
            name="created_to"
            placeholder="mm/dd/yyyy"
            value={searchWill.created_to}
            label="Created To"
            onChange={(e) => handleInputChange(e)}
            errorMessage={errors.created_to}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-lg-12">
          <div className="d-flex gap-3 justify-content-between w-100">
            <FowButton variant="success" onClick={onWillSearch}>
              <i className="fa fa-search mr-2"></i> Search
            </FowButton>
            {isFilterApplied && (
              <FowButton variant="outline-secondary" onClick={resetWillSearch}>
                <i className="fa fa-undo mr-2"></i> Reset
              </FowButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchForm;
