import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import locationReducer from './slices/locationSlice';
import missingWillReducer from './slices/missingWillSlice';
import invoiceReducer from './slices/invoiceSlice';
import templateReducer from './slices/templateSlice';
import deathNoticeReducer from './slices/deathNoticeSlice';
import obituariesReducer from './slices/obituariesSlice';
import testimonialSlice from './slices/testimonialSlice';
import charitiesSlice from './slices/charitiesSlice';
import charityCategoriesSlice from './slices/charityCategoriesSlice';
import pricingSlice from './slices/pricingSlice';
import usersSlice from './slices/usersSlice';
import terminologySlice from './slices/terminologySlice';
import pagesSlice from './slices/pagesSlice';
import questionsSlice from './slices/questionsSlice';
import metaSlice from './slices/metaSlice';
import livingWillFormSlice from './slices/livingWillFormSlice';
import livingWillSlice from './slices/livingWillSlice';
import faqSlice from './slices/faqSlice';
import dashboardSlice from './slices/dashboardSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import willStatusSlice from './slices/willStatusSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['form'],
};

const rootReducer = combineReducers({
  location: locationReducer,
  missingWill: missingWillReducer,
  invoice: invoiceReducer,
  template: templateReducer,
  deathNotice: deathNoticeReducer,
  obituaries: obituariesReducer,
  testimonial: testimonialSlice,
  charities: charitiesSlice,
  charityCategories: charityCategoriesSlice,
  pricing: pricingSlice,
  users: usersSlice,
  terminologies: terminologySlice,
  pages: pagesSlice,
  questions: questionsSlice,
  meta: metaSlice,
  livingWillForm: livingWillFormSlice,
  livingWill: livingWillSlice,
  faqs: faqSlice,
  willStatus: willStatusSlice,
  dashboard: dashboardSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV === 'development',
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
