import { dtCustomStyle } from '../../helpers/DataTableHelper';
import { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import {
  IShowDeleteTemplateModal,
  ITemplateDetailColumn,
  ITemplateDetailRow,
  ITemplatePagination,
  ITemplatePayload,
} from 'src/datatypes/Template';
import { useAppDispatch } from 'src/redux/hooks';
import DeleteModal from './DeleteModal';
import { deleteTemplateData } from 'src/redux/slices/templateSlice';
import { PageOptions } from 'src/constants/Common';

interface IProps {
  templateList: ITemplatePayload['templateList'];
  deleteTemplateDetail: ITemplatePayload['deleteTemplateStatus'];
  showDeleteModal: IShowDeleteTemplateModal;
  setShowDeleteModal: React.Dispatch<
    React.SetStateAction<IShowDeleteTemplateModal>
  >;
  setPagination: React.Dispatch<React.SetStateAction<ITemplatePagination>>;
}
const Listing = ({
  templateList,
  deleteTemplateDetail,
  showDeleteModal,
  setShowDeleteModal,
  setPagination,
}: IProps) => {
  const dispatch = useAppDispatch();

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeleteTemplateModal) => ({
      ...prev,
      show: !showDeleteModal.show,
      ...(showDeleteModal.show ? { idDetails: [] } : {}),
    }));
  };

  const selectedTemplateId = (rows: ITemplateDetailRow[]) => {
    setShowDeleteModal((prev: IShowDeleteTemplateModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  const handleDelete = () => {
    const selectedIds = showDeleteModal.idDetails
      .map((templateDetails: ITemplateDetailRow) => templateDetails.id)
      .join(',');
    dispatch(deleteTemplateData(selectedIds));
  };

  const openDeleteModal = (row: ITemplateDetailRow) => {
    selectedTemplateId([row]);
    toggleDeleteModal();
  };

  useEffect(() => {
    if (deleteTemplateDetail?.success) {
      setShowDeleteModal((prev: IShowDeleteTemplateModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deleteTemplateDetail?.success]);

  const columns: ITemplateDetailColumn[] = [
    {
      id: 'title',
      name: 'Title',
      selector: (row: ITemplateDetailRow) => row.title,
      sortable: true,
    },
    {
      id: 'type',
      name: 'Type',
      selector: (row: ITemplateDetailRow) => row.type,
      sortable: true,
    },
    {
      id: 'status',
      name: 'Status',
      selector: (row: ITemplateDetailRow) => row.status,
      sortable: true,
      style: {
        paddingRight: '16px',
      },
      compact: true,
      center: true,
      cell: (row: ITemplateDetailRow) => {
        if (row.status === 'active') {
          return (
            <div className="template-status">
              <i className="fa fa-check"></i>
            </div>
          );
        } else {
          return (
            <div className="template-status">
              <i className="fa fa-times"></i>
            </div>
          );
        }
      },
    },
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: ITemplateDetailRow) => row.actions,
      center: true,
      compact: true,
      cell: (row: ITemplateDetailRow) => (
        <div>
          <a
            title="Edit"
            className="btn btn-primary btn-sm"
            href={`/templates/${row.id}`}
            key="view"
          >
            <i className="fa fa-pencil"></i>
          </a>
          <Button
            title="Delete"
            className="btn btn-danger btn-sm ml-3"
            key="delete"
            onClick={() => openDeleteModal(row)}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h2>Templates</h2>
          </Card.Header>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={templateList.loading}
              keyField="id"
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              paginationServer
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={templateList.data?.total}
              onChangeRowsPerPage={(new_per_page: number) => {
                setPagination((prev: ITemplatePagination) => ({
                  ...prev,
                  per_page: new_per_page,
                }));
              }}
              data={templateList.data?.data}
              customStyles={dtCustomStyle}
              onChangePage={(page: number) => {
                setPagination((prev: ITemplatePagination) => ({
                  ...prev,
                  page: page,
                }));
              }}
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteModal.show}
          selectedIdDetails={showDeleteModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteTemplateDetail={deleteTemplateDetail}
        />
      </div>
    </div>
  );
};

export default Listing;
