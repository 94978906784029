import TextInput from '../common/TextInput';
import FowButton from '../common/FowButton';
import React, { useEffect } from 'react';
import { IUsersSearch } from 'src/datatypes/Users';
import { Col, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { fetchStates } from 'src/redux/slices/locationSlice';
import SelectInput from '../common/SelectInput';
import { ILocationPayload } from 'src/datatypes/Location';

interface IProps {
  usersSearch: IUsersSearch;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUserSearch: () => void;
  resetUserSearch: () => void;
  isFilterApplied: boolean;
  errors: IUsersSearch;
  isDataLoading: boolean;
}

const UsersSearch = ({
  usersSearch,
  handleInputChange,
  onUserSearch,
  resetUserSearch,
  isFilterApplied,
  errors,
  isDataLoading,
}: IProps) => {
  const dispatch = useAppDispatch();
  const stateOptions = useAppSelector<ILocationPayload['states']['data']>(
    (state) => state.location.states.data
  );
  const stateLoading = useAppSelector<
    ILocationPayload['states']['stateLoading']
  >((state) => state.location.states.stateLoading);

  useEffect(() => {
    dispatch(fetchStates());
    // eslint-disable-next-line
  }, []);
  return (
    <div className="users-form">
      <Row>
        <Col lg={4} md={6} sm={12}>
          <TextInput
            name="first_name"
            placeholder="First Name"
            value={String(usersSearch.first_name)}
            onChange={(e) => handleInputChange(e)}
            label="First Name"
            errorMessage={String(errors.first_name)}
            isRequired={false}
          />
        </Col>
        <Col lg={4} md={6} sm={12}>
          <TextInput
            name="last_name"
            placeholder="Last Name"
            value={String(usersSearch.last_name)}
            onChange={(e) => handleInputChange(e)}
            label="Last Name"
            errorMessage={String(errors.last_name)}
            isRequired={false}
          />
        </Col>
        <Col lg={4} md={6} sm={12}>
          <SelectInput
            name="state"
            placeholder="Select State"
            value={usersSearch.state}
            optionLabel="state_name"
            optionValue="id"
            options={stateOptions}
            isLoading={stateLoading}
            handleSelectChange={(e) => handleInputChange(e)}
            label="State"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="d-flex gap-3 justify-content-between w-100">
            <FowButton
              variant="success"
              onClick={onUserSearch}
              isLoading={isDataLoading}
            >
              <i className="fa fa-search mr-2"></i> Search
            </FowButton>
            {isFilterApplied && (
              <FowButton
                variant="outline-secondary"
                onClick={resetUserSearch}
                isLoading={isDataLoading}
              >
                <i className="fa fa-undo mr-2"></i> Reset
              </FowButton>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UsersSearch;
