import SelectInput from '../common/SelectInput';
import MaskInput from '../common/MaskInput';
import TextInput from '../common/TextInput';
import FowButton from '../common/FowButton';
import React, { useEffect } from 'react';
import { ISearchWillField } from 'src/datatypes/MisssingWill';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchStates } from '../../redux/slices/locationSlice';
import SelectCountyInput from '../common/SelectCountyInput';
import { ILocationPayload } from 'src/datatypes/Location';

interface IProps {
  searchWill: ISearchWillField;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onWillSearch: () => void;
  resetWillSearch: () => void;
  isFilterApplied: boolean;
  errors: ISearchWillField;
}

const SearchForm = ({
  searchWill,
  handleInputChange,
  onWillSearch,
  resetWillSearch,
  isFilterApplied,
  errors,
}: IProps) => {
  const dispatch = useAppDispatch();
  const stateOptions = useAppSelector<ILocationPayload['states']['data']>(
    (state) => state.location.states.data
  );
  const stateLoading = useAppSelector<
    ILocationPayload['states']['stateLoading']
  >((state) => state.location.states.stateLoading);

  useEffect(() => {
    dispatch(fetchStates());
    // eslint-disable-next-line
  }, []);

  return (
    <div className="missing-wills-form">
      <div className="row">
        <div className="col-lg-4">
          <TextInput
            name="s_first_name"
            placeholder="First Name"
            value={searchWill.s_first_name}
            onChange={(e) => handleInputChange(e)}
            label="Searcher First Name"
            errorMessage={errors.s_first_name}
            isRequired={false}
          />
        </div>
        <div className="col-lg-4">
          <TextInput
            name="s_last_name"
            placeholder="Last Name"
            value={searchWill.s_last_name}
            onChange={(e) => handleInputChange(e)}
            label="Searcher Last Name"
            errorMessage={errors.s_last_name}
            isRequired={false}
          />
        </div>
        <div className="col-lg-4">
          <TextInput
            name="s_email"
            placeholder="Email"
            value={searchWill.s_email}
            onChange={(e) => handleInputChange(e)}
            label="Searcher Email"
            errorMessage={errors.s_email}
            isRequired={false}
          />
        </div>
        <div className="col-lg-4">
          <TextInput
            name="d_first_name"
            placeholder="First Name"
            value={searchWill.d_first_name}
            onChange={(e) => handleInputChange(e)}
            label="Deceased First Name"
            errorMessage={errors.d_first_name}
            isRequired={false}
          />
        </div>
        <div className="col-lg-4">
          <TextInput
            name="d_last_name"
            placeholder="Last Name"
            value={searchWill.d_last_name}
            onChange={(e) => handleInputChange(e)}
            label="Deceased Last Name"
            errorMessage={errors.d_last_name}
            isRequired={false}
          />
        </div>
        <div className="col-lg-4">
          <TextInput
            name="city"
            placeholder="City"
            value={searchWill.city}
            onChange={(e) => handleInputChange(e)}
            label="Searcher City"
            errorMessage={errors.city}
            isRequired={false}
          />
        </div>
        <div className="col-lg-4">
          <SelectInput
            name="state"
            placeholder="Select State"
            value={searchWill.state}
            optionLabel="state_name"
            optionValue="id"
            options={stateOptions}
            isLoading={stateLoading}
            handleSelectChange={(e) => handleInputChange(e)}
            label="Searcher State"
          />
        </div>
        <div className="col-lg-4">
          <SelectCountyInput
            name="county"
            placeholder="Select County"
            value={searchWill.county}
            selectedState={searchWill.state}
            label="Searcher County"
            handleSelectChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="col-lg-4">
          <TextInput
            name="zipcode"
            placeholder="Zip Code"
            value={searchWill.zipcode}
            onChange={(e) => handleInputChange(e)}
            label="Searcher Zipcode"
            errorMessage={errors.zipcode}
            isRequired={false}
          />
        </div>
        <div className="col-lg-4">
          <MaskInput
            mask="99/99/9999"
            name="d_birth_date"
            placeholder="mm/dd/yyyy"
            value={searchWill.d_birth_date}
            label="Deceased Birth Date"
            onChange={(e) => handleInputChange(e)}
            errorMessage={errors.d_birth_date}
            isRequired={false}
          />
        </div>
        <div className="col-lg-4">
          <MaskInput
            mask="99/99/9999"
            name="created_from"
            placeholder="mm/dd/yyyy"
            value={searchWill.created_from}
            label="Created From"
            onChange={(e) => {
              e.target.placeholder = 'mm/dd/yyyy';
              handleInputChange(e);
            }}
            errorMessage={errors.created_from}
            isRequired={false}
          />
        </div>
        <div className="col-lg-4">
          <MaskInput
            mask="99/99/9999"
            name="created_to"
            placeholder="mm/dd/yyyy"
            value={searchWill.created_to}
            label="Created To"
            onChange={(e) => {
              e.target.placeholder = 'mm/dd/yyyy';
              handleInputChange(e);
            }}
            errorMessage={errors.created_to}
            isRequired={false}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-lg-12">
          <div className="d-flex gap-3 justify-content-between w-100">
            <FowButton variant="success" onClick={onWillSearch}>
              <i className="fa fa-search mr-2"></i> Search
            </FowButton>
            {isFilterApplied && (
              <FowButton variant="outline-secondary" onClick={resetWillSearch}>
                <i className="fa fa-undo mr-2"></i> Reset
              </FowButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchForm;
