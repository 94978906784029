const TheFooter = () => {
  return (
    <footer className="footer footer-fixed">
      <div className="mfs-auto">
        <span className="mr-1">Copyright </span>
        <a
          href="https://www.theuswillregistry.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          The US Will Registry
        </a>
      </div>
    </footer>
  );
};

export default TheFooter;
