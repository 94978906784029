import useAxiosService from '../services/axios.service';

const useInvoicesApi = () => {
  const { getUserHttpClient } = useAxiosService();
  const getInvoiceList = async (params?: any) => {
    return await getUserHttpClient()
      .get('/invoices', { params: params ?? {} })
      .then((response) => response?.data);
  };
  const getInvoiceDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/invoices/' + id)
      .then((response) => response?.data);
  };

  const findSearchPinCode = async (data?: any) => {
    return await getUserHttpClient()
      .post('/invoices/find-search-pin', undefined , {params : data ?? {}} )
      .then((response) => response?.data);
  }
  const generateSearchPinCode = async (data?: any) => {
    return await getUserHttpClient()
      .post('/invoices/generate-search-pin', undefined , {params : data ?? {}} )
      .then((response) => response?.data);
  };

  return { getInvoiceList, getInvoiceDetails, generateSearchPinCode , findSearchPinCode };
};

export default useInvoicesApi;
