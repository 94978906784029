import useAxiosService from '../services/axios.service';

const useMissingWillsApi = () => {
  const { getUserHttpClient } = useAxiosService();
  const getWillList = async (params?: any) => {
    return await getUserHttpClient()
      .get('/missing-will-search', { params: params ? params : {} })
      .then((response) => response?.data);
  };
  const getWillDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/missing-will-search/' + id)
      .then((response) => response?.data);
  };
  const deleteWillData = async (id: string) => {
    return await getUserHttpClient()
      .delete('/missing-will-search/' + id)
      .then((response) => response?.data);
  };

  return { getWillList, getWillDetails, deleteWillData };
};

export default useMissingWillsApi;
