import { useEffect, useState } from 'react';
import PageTitle from '../../components/common/PageTitle';
import SearchForm from '../../components/will-creators/SearchForm';
import Listing from '../../components/will-creators/Listing';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { fetchWillStatusList, resetWillStatusData } from 'src/redux/slices/willStatusSlice';
import {
  WillStatusListPagination,
  initialWillStatusSearchField,
  willStatusSearchFieldValidations,
} from 'src/constants/WillStatus';
import { ValidateField } from '../../helpers/ValidatorHelper';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import {
  IWillStatusPayload,
  IShowDeleteWillStatusModal,
  IWillStatusPagination,
} from 'src/datatypes/WillStatus';
import FowButton from '../../components/common/FowButton';

const WillCreatorsListing = () => {
  const [searchWill, setSearchWill] = useState({
    ...initialWillStatusSearchField,
  });
  const [lastValidSearch, setLastValidSearch] = useState({
    ...initialWillStatusSearchField,
  });
  const [errors, setErrors] = useState({ ...initialWillStatusSearchField });
  const [showAdvanceSearch, setShowAdvanceSearch] = useState({
    show: false,
    filterApplied: false,
  });

  const [pagination, setPagination] = useState<IWillStatusPagination>(
    WillStatusListPagination
  );

  const [showDeleteModal, setShowDeleteModal] =
    useState<IShowDeleteWillStatusModal>({
      show: false,
      idDetails: [],
    });

  const dispatch = useAppDispatch();

  const { willStatusList, deleteWillStatusStatus } =
    useAppSelector<IWillStatusPayload>((state) => state.willStatus);

  const getWillStatusListData = () => {
    dispatch(fetchWillStatusList({ params: pagination }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchWill((searchWill) => ({ ...searchWill, [name]: value }));

    let tempValue: any = value;
    if (name === 'created_from') {
      tempValue = {
        value1: value,
        value2: searchWill.created_to,
      };
    } else if (name === 'created_to') {
      tempValue = {
        value1: value,
        value2: searchWill.created_from,
      };
    }

    const error =
      value === ''
        ? null
        : ValidateField(
            e.target.name,
            tempValue,
            willStatusSearchFieldValidations?.[name]
          );

    setErrors((errors) => ({ ...errors, [name]: error || '' }));
  };

  const toggleAdvanceFilter = () => {
    setShowAdvanceSearch({
      ...showAdvanceSearch,
      show: !showAdvanceSearch.show,
    });
  };

  const toggleFilterApplied = (isApplied: boolean) => {
    setShowAdvanceSearch({
      ...showAdvanceSearch,
      filterApplied: isApplied,
    });
  };

  const validateForm = () => {
    let hasValuesInSearchWill = false;

    if (Object.keys(searchWill).some((key) => searchWill[key] !== '')) {
      hasValuesInSearchWill = true;
    } else if (JSON.stringify(lastValidSearch) !== JSON.stringify(searchWill)) {
      hasValuesInSearchWill = true;
    }

    const hasErrors = Object.keys(errors).some((key) => errors[key] !== '');
    return !hasValuesInSearchWill || hasErrors;
  };

  const onWillSearch = () => {
    const hasError = validateForm();
    if (!hasError) {
      setLastValidSearch(searchWill);
      dispatch(
        fetchWillStatusList({
          params: { ...searchWill, ...pagination, page: 1 },
        })
      );
      toggleFilterApplied(true);
    }
  };

  const resetWillSearch = () => {
    setLastValidSearch(initialWillStatusSearchField);
    dispatch(fetchWillStatusList({ params: pagination }));
    toggleFilterApplied(false);
    setSearchWill({ ...initialWillStatusSearchField });
  };

  //handle successful delete api call and modal closing
  useEffect(() => {
    if (deleteWillStatusStatus.success) {
      toastSuccessMessage('Will deleted successfully!');

      setShowDeleteModal({
        show: false,
        idDetails: [],
      });
      dispatch(resetWillStatusData());
      getWillStatusListData();
    }
    // eslint-disable-next-line
  }, [deleteWillStatusStatus]);

  //handle page change
  useEffect(() => {
    dispatch(
      fetchWillStatusList({ params: { ...pagination, ...lastValidSearch } })
    );
    // eslint-disable-next-line
  }, [pagination]);

  return (
    <div className="will-status">
      <PageTitle heading="Will Creators" />
      <div className="will-status-content">
        <div className="control-top-button mb-2">
          <FowButton
            variant="primary"
            className="mr-2"
            onClick={() => toggleAdvanceFilter()}
          >
            Advanced Search
          </FowButton>
        </div>
        <div>
          {showAdvanceSearch.show && (
            <SearchForm
              errors={errors}
              handleInputChange={handleInputChange}
              searchWill={searchWill}
              onWillSearch={onWillSearch}
              resetWillSearch={resetWillSearch}
              isFilterApplied={showAdvanceSearch.filterApplied}
            />
          )}
          <Listing
            willStatusList={willStatusList}
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            deleteWillStatusDetail={deleteWillStatusStatus}
            setPagination={setPagination}
          />
        </div>
      </div>
    </div>
  );
};

export default WillCreatorsListing;
