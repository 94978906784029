import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import ErrorMessage from './ErrorMessage';

interface IProps {
  name: string;
  value: string;
  type?: string;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  isDisabled?: boolean;
  errorMessage?: string;
  controlId?: string;
  className?: string;
  placeholder?: string;
  rows?: number;
  inputGroupContent?: React.ReactNode;
}

const TextInputGroup = ({
  name,
  value,
  type,
  label,
  onChange,
  isRequired,
  errorMessage,
  controlId,
  className,
  placeholder,
  isDisabled,
  rows,
  inputGroupContent,
}: IProps) => {
  return (
    <Form.Group controlId={controlId}>
      {label && (
        <div className="instruction-title">
          <Form.Label>
            {label}
            {isRequired && <span className="required">*</span>}
          </Form.Label>
        </div>
      )}
      <InputGroup>
        {inputGroupContent && (
          <InputGroup.Text style={{ borderRadius: '0.25rem 0 0 0.25rem' }}>
            {inputGroupContent}
          </InputGroup.Text>
        )}
        {type === 'textarea' ? (
          <Form.Control
            as="textarea"
            className={`${
              errorMessage
                ? 'error-focus-input'
                : className || 'form-field form-control'
            }`}
            placeholder={placeholder}
            name={name}
            value={value ?? ''}
            disabled={isDisabled}
            rows={rows}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e);
            }}
          />
        ) : (
          <Form.Control
            className={`${
              errorMessage
                ? 'error-focus-input'
                : className || 'form-field form-control'
            }`}
            placeholder={placeholder}
            name={name}
            value={value ?? ''}
            disabled={isDisabled}
            type={type}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e);
            }}
          />
        )}
      </InputGroup>
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </Form.Group>
  );
};

export default TextInputGroup;

TextInputGroup.defaultProps = {
  isRequired: true,
  label: '',
  value: '',
  errorMessage: '',
  className: '',
  type: 'text',
  placeholder: '',
  isDisabled: false,
  rows: 1,
};
