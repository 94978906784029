import useAxiosService from '../services/axios.service';

const useDeathNoticesApi = () => {
  const { getUserHttpClient } = useAxiosService();
  const getDeathNoticeList = async (params?: any) => {
    return await getUserHttpClient()
      .get('/death-notices', { params: params ? params : {} })
      .then((response) => response);
  };
  const getObituaryList = async (params?: any) => {
    return await getUserHttpClient()
      .get('/death-notices/obituaries', { params: params ? params : {} })
      .then((response) => response);
  };
  const getDeathNoticeDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/death-notices/' + id)
      .then((response) => response);
  };

  const getObituaryDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/death-notices/obituaries/' + id)
      .then((response) => response);
  };

  const deleteDeathNoticeDetails = async (id: string) => {
    return await getUserHttpClient()
      .delete('/death-notices/' + id)
      .then((response) => response);
  };

  //update old testimonial
  const updateObituaryDetails = async (data: any, id: string) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return await getUserHttpClient()
      .post('/death-notices/obituaries/' + id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to FormData
        },
      })
      .then((response) => response);
  };

  const deleteObituaryDetails = async (id: string) => {
    return await getUserHttpClient()
      .delete('/death-notices/' + id)
      .then((response) => response);
  };

  return {
    getDeathNoticeList,
    getObituaryList,
    getDeathNoticeDetails,
    deleteDeathNoticeDetails,
    getObituaryDetails,
    updateObituaryDetails,
    deleteObituaryDetails,
  };
};

export default useDeathNoticesApi;
