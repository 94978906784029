import useAxiosService from '../services/axios.service';

const useCharitiesApi = () => {
  const { getUserHttpClient } = useAxiosService();

  //api call for charities list
  const getCharitiesList = async (params?: any) => {
    return await getUserHttpClient()
      .get('/charities', { params: params ? params : {} })
      .then((response) => response?.data);
  };

  //api call for charities by id
  const getCharitiesDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/charities/' + id)
      .then((response) => response?.data);
  };

  //api call for save charities
  const saveCharitiesDetails = async (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return await getUserHttpClient()
      .post('/charities/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to FormData
        },
      })
      .then((response) => response?.data);
  };

  //api call for update charities
  const updateCharitiesDetails = async (data: any, id: string) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return await getUserHttpClient()
      .post('/charities/' + id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to FormData
        },
      })
      .then((response) => response?.data);
  };

  //api call for delete charities
  const deleteCharitiesData = async (id: string) => {
    return await getUserHttpClient()
      .delete('/charities/' + id)
      .then((response) => response?.data);
  };

  //api call for charity categories list
  const getCharityCategories = async (params?: any) => {
    return await getUserHttpClient()
      .get('/charity-categories', { params: params ? params : {} })
      .then((response) => response?.data);
  };

  return {
    getCharitiesList,
    getCharitiesDetails,
    saveCharitiesDetails,
    updateCharitiesDetails,
    deleteCharitiesData,
    getCharityCategories,
  };
};

export default useCharitiesApi;
