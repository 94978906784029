import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useFaqsApi from 'src/api/faqs.api';
import { IFaqDetails, IFaqPayload } from 'src/datatypes/Faq';

// initial / template state of faq redux slice
const initialState: IFaqPayload = {
  faqList: {
    loading: false,
    error: false,
    success: false,
    data: {
      current_page: 0,
      total: 0,
      data: [],
    },
  },
  faqDetails: {
    loading: false,
    error: false,
    success: false,
    data: {
      id: 0,
      question_text: '',
      answer_text: '',
      type: '',
      status: '',
    },
  },
  saveUpdateStatus: {
    success: false,
    loading: false,
    error: false,
  },
  deleteFaqStatus: {
    success: false,
    loading: false,
    error: false,
  },
};

//redux slice for faq
export const faqSlice = createSlice({
  name: 'faq',
  initialState,
  // reducers that don't require API calls
  reducers: {
    resetFaqData: (state) => {
      state.faqDetails = initialState.faqDetails;
      state.saveUpdateStatus = initialState.saveUpdateStatus;
      state.deleteFaqStatus = initialState.deleteFaqStatus;
    },
  },
  // reducers that require API calls
  extraReducers: (builder) => {
    builder
      .addCase(fetchFaqList.pending, (state) => {
        state.faqList.loading = true;
        state.faqList.error = false;
        state.faqList.success = false;
        state.faqList.data = initialState.faqList.data;
      })
      .addCase(fetchFaqList.fulfilled, (state, { payload }) => {
        state.faqList.data.data = payload.faqs;
        state.faqList.data.current_page = payload.current_page ?? 0;
        state.faqList.data.total = payload.total ?? 0;
        state.faqList.loading = false;
        state.faqList.success = true;
      })
      .addCase(fetchFaqList.rejected, (state) => {
        state.faqList.loading = false;
        state.faqList.error = true;
        state.faqList.data = initialState.faqList.data;
      })
      .addCase(fetchFaqDetails.pending, (state) => {
        state.faqDetails.loading = true;
        state.faqDetails.error = false;
        state.faqDetails.success = false;
        state.faqDetails.data = initialState.faqDetails.data;
      })
      .addCase(fetchFaqDetails.fulfilled, (state, { payload }) => {
        state.faqDetails.data = payload;
        state.faqDetails.loading = false;
        state.faqDetails.success = true;
      })
      .addCase(fetchFaqDetails.rejected, (state) => {
        state.faqDetails.loading = false;
        state.faqDetails.error = true;
        state.faqDetails.data = initialState.faqDetails.data;
      })
      .addCase(saveUpdateFaq.pending, (state) => {
        state.saveUpdateStatus.loading = true;
        state.saveUpdateStatus.error = false;
        state.saveUpdateStatus.success = false;
      })
      .addCase(saveUpdateFaq.fulfilled, (state) => {
        state.saveUpdateStatus.loading = false;
        state.saveUpdateStatus.success = true;
      })
      .addCase(saveUpdateFaq.rejected, (state) => {
        state.saveUpdateStatus.loading = false;
        state.saveUpdateStatus.error = true;
      })
      .addCase(deleteFaq.pending, (state) => {
        state.deleteFaqStatus.loading = true;
        state.deleteFaqStatus.error = false;
        state.deleteFaqStatus.success = false;
      })
      .addCase(deleteFaq.fulfilled, (state) => {
        state.deleteFaqStatus.loading = false;
        state.deleteFaqStatus.success = true;
      })
      .addCase(deleteFaq.rejected, (state) => {
        state.deleteFaqStatus.loading = false;
        state.deleteFaqStatus.error = true;
      });
  },
});

// Async API Hooks

/**
 * Fetch Faq List from API and save in redux store
 */
export const fetchFaqList = createAsyncThunk(
  'faq/fetchFaqList',
  async (data: any, { rejectWithValue }) => {
    const payload = JSON.parse(JSON.stringify(data));
    for (const key in payload.params) {
      if (payload.params[key] === '') {
        delete payload.params[key];
      }
    }

    const response = await useFaqsApi().getFaqsList(payload);

    // The value we return becomes the `fulfilled` action payload
    if (response.success === true) {
      return response;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Fetch Faq Details from API and save in redux store
 */
export const fetchFaqDetails = createAsyncThunk(
  'faq/fetchFaqDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useFaqsApi().getFaqById(id);

    // The value we return becomes the `fulfilled` action payload
    if (response.success === true) {
      return response?.faq;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Save Faq Details
 */
export const saveUpdateFaq = createAsyncThunk(
  'faq/saveUpdateFaq',
  async (data: IFaqDetails, { rejectWithValue }) => {
    const payload = JSON.parse(JSON.stringify(data));
    const id = payload?.id;
    if (payload['id']) {
      delete payload['id'];
    }
    if (payload['created_at']) {
      delete payload['created_at'];
    }
    if (payload['updated_at']) {
      delete payload['updated_at'];
    }

    const response = id
      ? await useFaqsApi().updateFaq(payload, String(id))
      : await useFaqsApi().saveFaq(payload);

    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response?.success === true) {
      return response.faq;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Delete Faq
 */
export const deleteFaq = createAsyncThunk(
  'faq/deleteFaq',
  async (id: string, { rejectWithValue }) => {
    const response = await useFaqsApi().deleteFaq(id);

    // The value we return becomes the `fulfilled` action payload
    if (response?.success === true) {
      return response.faq;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const { resetFaqData } = faqSlice.actions;

export default faqSlice.reducer;
