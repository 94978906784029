import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TextInput from '../common/TextInput';
import FowButton from '../common/FowButton';
import { ITerminologySearch } from 'src/datatypes/Terminology';

// interface for the component
interface IProps {
  terminologySearch: ITerminologySearch;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onTerminologySearch: () => void;
  resetTerminologySearch: () => void;
  isFilterApplied: boolean;
  errors: ITerminologySearch;
}

const TerminologySearch = ({
  terminologySearch,
  handleInputChange,
  onTerminologySearch,
  resetTerminologySearch,
  isFilterApplied,
  errors,
}: IProps) => {
  return (
    <div className="charity-form">
      <Row>
        <Col lg={6} md={6} sm={12}>
          <TextInput
            name="term"
            placeholder="Term"
            value={String(terminologySearch.term)}
            onChange={(e) => handleInputChange(e)}
            label="Term"
            errorMessage={String(errors.term)}
            isRequired={false}
          />
        </Col>
        <Col lg={6} md={6} sm={12}>
          <TextInput
            name="alternate_term"
            placeholder="Alternate Term"
            value={String(terminologySearch.alternate_term)}
            onChange={(e) => handleInputChange(e)}
            label="Alternate Term"
            errorMessage={String(errors.alternate_term)}
            isRequired={false}
          />
        </Col>
        <Col lg={12}>
          <TextInput
            name="description"
            placeholder="Description"
            value={String(terminologySearch.description)}
            onChange={(e) => handleInputChange(e)}
            label="Description"
            errorMessage={String(errors.description)}
            isRequired={false}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col lg={12}>
          <div className="d-flex gap-3 justify-content-between w-100">
            <FowButton variant="success" onClick={onTerminologySearch}>
              <i className="fa fa-search mr-2"></i> Search
            </FowButton>
            {isFilterApplied && (
              <FowButton
                variant="outline-secondary"
                onClick={resetTerminologySearch}
              >
                <i className="fa fa-undo mr-2"></i> Reset
              </FowButton>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TerminologySearch;
