import { Dtspinner, dtCustomStyle } from '../../helpers/DataTableHelper';
import { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useAppDispatch } from 'src/redux/hooks';
import DeleteModal from './DeleteModal';
import { PageOptions } from 'src/constants/Common';
import {
  IWillStatusColumn,
  IWillStatusPagination,
  IWillStatusPayload,
  IWillStatusRow,
  IShowDeleteWillStatusModal,
} from 'src/datatypes/WillStatus';
import { deleteWillStatusData } from 'src/redux/slices/willStatusSlice';
import moment from 'moment';

interface IProps {
  willStatusList: IWillStatusPayload['willStatusList'];
  deleteWillStatusDetail: IWillStatusPayload['deleteWillStatusStatus'];
  showDeleteModal: IShowDeleteWillStatusModal;
  setShowDeleteModal: React.Dispatch<
    React.SetStateAction<IShowDeleteWillStatusModal>
  >;
  setPagination: React.Dispatch<React.SetStateAction<IWillStatusPagination>>;
}

const Listing = ({
  willStatusList,
  deleteWillStatusDetail,
  showDeleteModal,
  setShowDeleteModal,
  setPagination,
}: IProps) => {
  const dispatch = useAppDispatch();

  //siwtch for delete modal
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeleteWillStatusModal) => ({
      ...prev,
      show: !showDeleteModal.show,
      ...(showDeleteModal.show ? { idDetails: [] } : {}),
    }));
  };

  // selected will
  const selectedWillStatusId = (rows: IWillStatusRow[]) => {
    setShowDeleteModal((prev: IShowDeleteWillStatusModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  // handle delete will
  const handleDelete = () => {
    const selectedIds = showDeleteModal.idDetails
      .map((willDetails: IWillStatusRow) => willDetails.id)
      .join(',');
    dispatch(deleteWillStatusData(selectedIds));
  };

  // handle delete modal open
  const openDeleteModal = (row: IWillStatusRow) => {
    selectedWillStatusId([row]);
    toggleDeleteModal();
  };

  //columns for listing
  const columns: IWillStatusColumn[] = [
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: IWillStatusRow) => row.actions,
      center: true,
      compact: true,
      width: '8.5rem',
      cell: (row: IWillStatusRow) => (
        <div className="mx-auto" style={{ display: 'flex', gap: '10px' }}>
          <a
            title="View"
            className="btn btn-secondary btn-sm"
            href={`/will-creators/${row?.id}`}
            key="view"
          >
            <i className="fa fa-eye"></i>
          </a>
          <Button
            title="Delete"
            className="btn btn-danger btn-sm"
            key="delete"
            onClick={() => openDeleteModal(row)}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
    {
      id: 'user_name',
      name: 'Full Name',
      selector: (row: IWillStatusRow) => row.first_name,
      sortable: true,
      maxWidth: '44rem',
      cell: (row: IWillStatusRow) => {
        return `${row.first_name} ${row.middle_name} ${row.last_name}`;
      },
    },
    {
      id: 'details',
      name: 'Contact Details',
      selector: (row: IWillStatusRow) => row.details,
      sortable: true,
      maxWidth: '44rem',
      cell: (row: IWillStatusRow) => {
        return (
          <div>
            {row['email']}
            <br />
            {row['phone']}
          </div>
        );
      },
    },
    {
      id: 'last_answered_page',
      name: 'Last Answered Page',
      selector: (row: IWillStatusRow) => row.last_answered_page,
      sortable: true,
      cell: (row: IWillStatusRow) =>
        row['status'] === 'in_progress' ? row['last_answered_page'] : '',
    },
    {
      id: 'hasDocument',
      name: 'Has Document Created?',
      selector: (row: IWillStatusRow) => row.document_file,
      maxWidth: '8rem',
      sortable: true,
      center: true,
      cell: (row: IWillStatusRow) =>
        row.document_file ? (
          <i className="fa fa-check"></i>
        ) : (
          <i className="fa fa-times"></i>
        ),
    },
    {
      id: 'status',
      name: 'Will Status',
      selector: (row: IWillStatusRow) => row.status,
      sortable: true,
      center: true,
      cell: (row: IWillStatusRow) => {
        switch (row?.status) {
          case 'finished':
            return <span className="badge badge-success">Finished</span>;
          case 'in_progress':
            if (row.document_file) {
              return <span className="badge badge-info">Updating</span>;
            } else {
              return <span className="badge badge-secondary">In Progress</span>;
            }
          default:
            return <span className="badge badge-warning">Not yet started</span>;
        }
      },
    },
    {
      name: 'Created At',
      selector: (row: IWillStatusRow) => row['created_at'],
      sortable: true,
      cell: (row: IWillStatusRow) => (
        <span className="white-space-pre">
          {moment
            .utc(moment(row['created_at']))
            .local()
            ?.format('MMM DD, YYYY[\r\n]h:mm a')}
        </span>
      ),
      width: '9.5rem',
      right: true,
    },
    {
      name: 'Updated At',
      selector: (row: IWillStatusRow) => row['updated_at'],
      sortable: true,
      cell: (row: IWillStatusRow) => (
        <span className="white-space-pre">
          {moment
            .utc(moment(row['updated_at']))
            .local()
            ?.format('MMM DD, YYYY[\r\n]h:mm a')}
        </span>
      ),
      width: '9.5rem',
      right: true,
    },
  ];

  // handle delete modal close on success delete
  useEffect(() => {
    if (deleteWillStatusDetail?.success) {
      setShowDeleteModal((prev: IShowDeleteWillStatusModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deleteWillStatusDetail?.success]);

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={willStatusList.loading}
              keyField="id"
              progressComponent={<Dtspinner />}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              paginationServer
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={willStatusList.data?.total}
              data={willStatusList.data?.data}
              customStyles={dtCustomStyle}
              onChangePage={(page: number) =>
                setPagination((prev: IWillStatusPagination) => ({
                  ...prev,
                  page: page,
                }))
              }
              onChangeRowsPerPage={(new_per_page: number) =>
                setPagination((prev: IWillStatusPagination) => ({
                  ...prev,
                  per_page: new_per_page,
                }))
              }
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteModal.show}
          selectedIdDetails={showDeleteModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteWillStatusDetail={deleteWillStatusDetail}
        />
      </div>
    </div>
  );
};

export default Listing;
