import { ValidateField } from '../../helpers/ValidatorHelper';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { IRouteParams } from '../../datatypes/CommonTypes';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import _forEach from 'lodash/forEach';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import SelectInput from '../../components/common/SelectInput';
import TextInput from '../../components/common/TextInput';
import RadioGroupInput from '../../components/common/RadioGroupInput';
import {
  templateQuestionDependencyDetails,
  templateQuestionDetails,
  templateErrorQuestionDetails,
  questionValidations,
  pageStatusOptions,
} from 'src/constants/Questions';
import {
  IQuestionDependency,
  IQuestionDetails,
  IQuestionPayload,
  //   IQuestionRow,
} from 'src/datatypes/Questions';
import {
  fetchQuestionDetails,
  saveUpdateQuestion,
  fetchQuestionsList,
} from 'src/redux/slices/questionsSlice';

interface IDependencyProps {
  dependency: IQuestionDependency;
  index: number;
  error: IQuestionDetails;
  handleInputs(
    e: { target: { name: string; value: string | number } },
    index: number
  ): void;
  pageType: 'new' | 'edit' | 'view';
  removeDependency(index: number): void;
}

const DependencySection = ({
  dependency,
  index,
  error,
  handleInputs,
  removeDependency,
  pageType,
}: IDependencyProps) => {
  const dispatch = useAppDispatch();

  const { questionList } = useAppSelector<IQuestionPayload>(
    (state) => state.questions
  );

  const getQuestionsListData = () => {
    dispatch(fetchQuestionsList());
  };

  useEffect(() => {
    if (!questionList.loading && questionList.data.data.length === 0) {
      getQuestionsListData();
    }
    // eslint-disable-next-line
  }, [questionList]);

  return (
    <Row className="align-items-center">
      <Col lg={5} md={5} sm={12}>
        <SelectInput
          name="parent_id"
          placeholder="Select Question"
          value={dependency.parent_id}
          optionLabel="question_text"
          optionValue="id"
          options={questionList?.data?.data ?? []}
          handleSelectChange={(e) => handleInputs(e, index)}
          label="Question"
          isRequired={true}
          errorMessage={String(error?.dependencyQuestions?.[index]?.parent_id)}
          isDisabled={pageType === 'view'}
        />
      </Col>
      <Col lg={5} md={5} sm={12}>
        <TextInput
          controlId="answer"
          label="Answer"
          className="form-field"
          placeholder="Enter answer"
          name="answer"
          value={dependency.answer}
          onChange={(e) => handleInputs(e, index)}
          errorMessage={String(error?.dependencyQuestions?.[index]?.answer)}
          isRequired={true}
          isDisabled={pageType === 'view'}
        />
      </Col>
      <Col lg={2} md={2} sm={12}>
        <Button
          variant="danger"
          type="button"
          style={{ width: '100%', marginTop: '13px' }}
          disabled={pageType === 'view'}
          onClick={() => removeDependency(index)}
        >
          DELETE
        </Button>
      </Col>
    </Row>
  );
};

const NewQuestionDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id }: IRouteParams = useParams();
  const location = useLocation();

  const { questionDetails, saveUpdateStatus } =
    useAppSelector<IQuestionPayload>((state) => state.questions);

  const [pageType, setPageType] = useState<'new' | 'edit' | 'view'>('new');

  const [formData, setFormData] = useState<IQuestionDetails>({
    ...templateQuestionDetails,
  });

  const [errors, setErrors] = useState<IQuestionDetails>(
    templateErrorQuestionDetails
  );

  const validations = useMemo(() => {
    return {
      ...questionValidations,
    };
  }, []);

  const getDisplayName = (key: string) => {
    switch (key) {
      case 'question_code':
        return 'Question Code';
      case 'page_id':
        return 'Page';
      case 'question_text':
        return 'Question Text';
      case 'parent_id':
        return 'Dependency Question';
      default:
        return key;
    }
  };
  //handle normal input
  const handleInputs = (e: any) => {
    const name = e.target.name;
    let value: string | number;
    let error: string | undefined | null;

    // get value from checkbox / others
    if (e.target.type === 'checkbox') {
      value = e.target.checked ? e.target.defaultValue : '';
    } else {
      value = e.target.value;
    }

    const fieldName = getDisplayName(name);

    error = ValidateField(fieldName, value, validations[name]) ?? '';

    //set errors
    if (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }

    //set form data
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  //handle dependency input
  const handleDependencyInputs = (
    e: { target: { name: string; value: string | number } },
    index: number
  ) => {
    const { name, value } = e.target;

    const fieldName = getDisplayName(name);

    const error = ValidateField(fieldName, value, validations[name]);

    const newErrorData = JSON.parse(JSON.stringify(errors));
    const newFormData = JSON.parse(JSON.stringify(formData));

    newFormData.dependencyQuestions[index][name] = value;
    newErrorData.dependencyQuestions[index][name] = error ?? '';

    //set error and form data
    setErrors(newErrorData);
    setFormData(newFormData);
  };

  //check if dependency has error
  const checkIfDepedencyHasError = (data: IQuestionDependency[]) => {
    let hasErrors = false;
    const newErrorData = JSON.parse(JSON.stringify(errors));

    data?.forEach((dependent: IQuestionDependency, index: number) => {
      _forEach(dependent, (value, key) => {
        const fieldName = getDisplayName(key);
        const error = ValidateField(fieldName, value, validations[key]);
        if (error) {
          newErrorData.dependencyQuestions[index][key] = error;
          hasErrors = true;
        }
      });
    });

    setErrors(newErrorData);
    return hasErrors;
  };

  //check if questions - form has any error
  const hadFormError = () => {
    let hasErrors = false;
    const newFormErrors = JSON.parse(JSON.stringify(errors));
    _forEach(formData, (value, key) => {
      if (key !== 'dependencyQuestions') {
        const error = ValidateField(key, value, validations[key]);
        if (error) {
          newFormErrors[key] = error;
          hasErrors = true;
        } else {
          newFormErrors[key] = '';
        }
      } else {
        if (typeof value === 'object' && checkIfDepedencyHasError(value)) {
          hasErrors = true;
        }
      }
    });
    setErrors(newFormErrors);
    return hasErrors;
  };

  //handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!hadFormError()) {
      let payload = { data: { ...formData }, id: id };
      dispatch(saveUpdateQuestion(payload));
    }
  };

  //function to add a dependency question to the end of the form.
  const addDependency = () => {
    const newFormData = JSON.parse(JSON.stringify(formData));
    newFormData.dependencyQuestions.push(templateQuestionDependencyDetails);

    const newErrorData = JSON.parse(JSON.stringify(errors));
    newErrorData.dependencyQuestions.push({
      parent_id: '',
      answer: '',
    });

    setFormData(newFormData);
    setErrors(newErrorData);
  };

  // function to remove a given dependency from form
  const removeDependency = (index: number) => {
    const newFormData = JSON.parse(JSON.stringify(formData));
    const newErrorData = JSON.parse(JSON.stringify(errors));
    newFormData.dependencyQuestions.splice(index, 1);
    newErrorData.dependencyQuestions.splice(index, 1);

    setFormData(newFormData);
    setErrors(newErrorData);
  };

  // function to get question details
  const getQuestionDetails = async () => {
    if (id && id !== 'new') {
      dispatch(fetchQuestionDetails(id));
      const containsView = location.pathname.includes('view');
      if (containsView) {
        setPageType('view');
      } else {
        setPageType('edit');
      }
    }
  };

  // Component to render Edit button in page title if currently in view state.
  const EditButtonComponent = () => {
    if (pageType === 'view') {
      return (
        <Button
          variant="primary"
          type="button"
          onClick={() => navigate(`/questions/${id}/edit`)}
        >
          Edit
        </Button>
      );
    } else {
      return null;
    }
  };

  //on page load
  useEffect(() => {
    setFormData({
      ...templateQuestionDetails,
    });
    setErrors({
      ...templateErrorQuestionDetails,
    });
    getQuestionDetails();
    // eslint-disable-next-line
  }, []);

  // on successful update / save
  useEffect(() => {
    if (saveUpdateStatus?.success) {
      toastSuccessMessage(
        `Question ${pageType === 'edit' ? 'updated' : 'saved'} successfully!`
      );
      navigate('/questions');
    }
    // eslint-disable-next-line
  }, [saveUpdateStatus]);

  //on successful question details fetch
  useEffect(() => {
    if (questionDetails?.success && questionDetails?.data) {
      const newFormData = JSON.parse(
        JSON.stringify({
          ...questionDetails.data,
          description: questionDetails.data?.description ?? '',
          explanation: questionDetails.data?.explanation ?? '',
          inputs: questionDetails.data?.inputs ?? '',
        })
      );
      newFormData.dependencyQuestions =
        newFormData.dependencies?.map((item: IQuestionDependency) => {
          return {
            parent_id: item.parent_id,
            answer: item.answer,
            id: item.id,
          };
        }) ?? [];
      delete newFormData.dependencies;
      setFormData(newFormData);

      setErrors({
        ...templateErrorQuestionDetails,
        dependencyQuestions:
          Array(newFormData.dependencyQuestions?.length).fill({
            parent_id: '',
            answer: '',
          }) ?? [],
      });
    }
  }, [questionDetails]);

  return (
    <div className="questions-details profile-details">
      <PageTitle
        heading={
          pageType === 'new'
            ? 'New Question'
            : `Question : ${questionDetails?.data?.question_code ?? ''}`
        }
        buttonName="Listing"
        buttonClick={() => navigate('/questions')}
        customButton={<EditButtonComponent />}
      />
      {questionDetails.loading ? (
        <Loader isFull />
      ) : !!questionDetails ? (
        <Card>
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Body>
              <Row className="align-items-center">
                <Col lg={6} md={12}>
                  <TextInput
                    controlId="question_code"
                    label="Question Code"
                    className="form-field"
                    placeholder="Question Code"
                    name="question_code"
                    value={formData.question_code}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.question_code}
                    isRequired={true}
                    isDisabled={pageType === 'view'}
                  />
                </Col>
                <Col lg={6} md={12}>
                  <TextInput
                    controlId="page_id"
                    label="Page ID"
                    className="form-field"
                    placeholder="Page ID"
                    name="page_id"
                    value={formData.page_id}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.page_id}
                    isRequired={true}
                    isDisabled={pageType === 'view'}
                  />
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col lg={12} md={12}>
                  <TextInput
                    controlId="question_text"
                    label="Question Text"
                    className="form-field"
                    placeholder="Question Text"
                    name="question_text"
                    value={formData.question_text}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.question_text}
                    isRequired={true}
                    isDisabled={pageType === 'view'}
                    type="textarea"
                    rows={2}
                  />
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col lg={12} md={12}>
                  <TextInput
                    controlId="inputs"
                    label="Inputs"
                    className="form-field"
                    placeholder="Enter stringified JSON data."
                    name="inputs"
                    value={formData.inputs}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.inputs}
                    isRequired={true}
                    isDisabled={pageType === 'view'}
                    type="textarea"
                    rows={4}
                  />
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col lg={12} md={12}>
                  <TextInput
                    controlId="description"
                    label="Description"
                    className="form-field"
                    placeholder="Enter description."
                    name="description"
                    value={formData.description}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.description}
                    isRequired={false}
                    isDisabled={pageType === 'view'}
                    type="textarea"
                    rows={4}
                  />
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col lg={12} md={12}>
                  <TextInput
                    controlId="explanation"
                    label="Pop-up Text"
                    className="form-field"
                    placeholder="Enter pop up / explanation text."
                    name="explanation"
                    value={formData.explanation}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.explanation}
                    isDisabled={pageType === 'view'}
                    isRequired={false}
                    type="textarea"
                    rows={4}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={12}>
                  <RadioGroupInput
                    name="status"
                    value={formData.status}
                    label="Status"
                    options={pageStatusOptions}
                    onChange={(e) =>
                      pageType === 'view' ? {} : handleInputs(e)
                    }
                    isRequired={true}
                    errorMessage={String(errors?.status)}
                    controlId="status"
                    // isDisabled={pageType === 'view'}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-between align-items-center">
                <h4>Dependency Questions</h4>
                <Button
                  type="button"
                  variant="primary"
                  disabled={pageType === 'view'}
                  onClick={addDependency}
                  style={{ width: '14.2%' }}
                >
                  ADD
                </Button>
              </div>
              {formData.dependencyQuestions &&
                formData?.dependencyQuestions?.map(
                  (dependency: IQuestionDependency, index: number) => (
                    <div style={{ paddingBottom: '20px' }} key={index}>
                      <DependencySection
                        dependency={dependency}
                        handleInputs={handleDependencyInputs}
                        index={index}
                        error={errors}
                        pageType={pageType}
                        removeDependency={removeDependency}
                      />
                    </div>
                  )
                )}
            </Card.Body>

            <Card.Footer>
              <Row className="align-items-center">
                <Col lg={12} md={12}>
                  {pageType !== 'view' && (
                    <Button
                      variant="primary"
                      type="submit"
                      style={{ marginRight: '10px' }}
                      disabled={saveUpdateStatus?.loading}
                    >
                      {pageType === 'new' ? 'Save' : 'Update'}
                    </Button>
                  )}
                  <Button
                    variant="secondary"
                    type="link"
                    href="/questions"
                    disabled={saveUpdateStatus?.loading}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Form>
        </Card>
      ) : (
        <NotFound
          heading="Page Not Found"
          subHeading="Please try again after sometime"
        />
      )}
    </div>
  );
};

export default NewQuestionDetails;
