import useWillStatusApi from 'src/api/willStatus.api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IWillStatusPayload } from 'src/datatypes/WillStatus';

const initialState: IWillStatusPayload = {
  willStatusList: {
    loading: false,
    error: false,
    success: false,
    data: {
      data: [],
      current_page: 0,
      first_page_url: '',
      from: 0,
      last_page: 0,
      path: '',
      per_page: 0,
      prev_page_url: '',
      to: 0,
      total: 0,
    },
  },
  willStatusDetails: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  deleteWillStatusStatus: { loading: false, success: false, error: false },
};

export const willStatusSlice = createSlice({
  name: 'willStatus',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetWillStatusData: (state: IWillStatusPayload) => {
      state.deleteWillStatusStatus = initialState.deleteWillStatusStatus;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchWillStatusList.pending, (state) => {
        state.willStatusList.loading = true;
        state.willStatusList.error = false;
        state.willStatusList.success = false;
      })
      .addCase(fetchWillStatusList.fulfilled, (state, { payload }) => {
        state.willStatusList.data = payload?.data;
        state.willStatusList.loading = false;
        state.willStatusList.success = true;
      })
      .addCase(fetchWillStatusList.rejected, (state) => {
        state.willStatusList.loading = false;
        state.willStatusList.error = true;
      })
      .addCase(fetchWillStatusDetails.pending, (state) => {
        state.willStatusDetails.loading = true;
        state.willStatusDetails.error = false;
        state.willStatusDetails.success = false;
        state.willStatusDetails.data = initialState.willStatusDetails.data;
      })
      .addCase(fetchWillStatusDetails.fulfilled, (state, { payload }) => {
        state.willStatusDetails.data = payload;
        state.willStatusDetails.loading = false;
        state.willStatusDetails.success = true;
      })
      .addCase(fetchWillStatusDetails.rejected, (state) => {
        state.willStatusDetails.loading = false;
        state.willStatusDetails.error = true;
      })
      .addCase(deleteWillStatusData.pending, (state) => {
        state.deleteWillStatusStatus.loading = true;
        state.deleteWillStatusStatus.success = false;
        state.deleteWillStatusStatus.error = false;
      })
      .addCase(deleteWillStatusData.fulfilled, (state) => {
        state.deleteWillStatusStatus.loading = false;
        state.deleteWillStatusStatus.success = true;
      })
      .addCase(deleteWillStatusData.rejected, (state) => {
        state.deleteWillStatusStatus.loading = false;
        state.deleteWillStatusStatus.error = true;
      });
  },
});

// functions to help data manipulation and formatting

function deleteEmptyKeys(obj: any) {
  if (typeof obj === 'object') {
    for (let key in obj) {
      if (
        obj.hasOwnProperty(key) &&
        (obj[key] === null || obj[key] === undefined || obj[key] === '')
      ) {
        delete obj[key];
      }
    }
  }
  return obj;
}

function convertDateString(dateString: string) {
  // Split the date string by "/"
  const parts = dateString.split('/');

  // Ensure we have three parts (day, month, year)
  if (parts.length !== 3) {
    throw new Error('Invalid date format');
  }

  // Reformat the date parts and join them with "-"
  const year = parts[2].length === 4 ? parts[2] : '20' + parts[2];
  const month = parts[0].length === 1 ? '0' + parts[0] : parts[0];
  const day = parts[1].length === 1 ? '0' + parts[1] : parts[1];

  return `${year}-${month}-${day}`;
}

// Async API Hooks

/**
 * Fetch Living Will List from API and save in redux store
 */
export const fetchWillStatusList = createAsyncThunk(
  'willStatus/fetchWillStatusList',
  async (data: any, { rejectWithValue }) => {
    data.params = deleteEmptyKeys(data.params);

    // convert date string to api payload format.
    if (data.params['created_from']) {
      data.params['created_from'] = convertDateString(
        data.params['created_from']
      );
    }
    if (data.params['created_to']) {
      data.params['created_to'] = convertDateString(data.params['created_to']);
    }

    const response = await useWillStatusApi().getWillStatusList(data.params);
    // The value we returns becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Delete Living Will Details
 */
export const deleteWillStatusData = createAsyncThunk(
  'willStatus/deleteWillStatusData',
  async (id: string, { rejectWithValue }) => {
    const response = await useWillStatusApi().deleteWillStatusData(id);
    // The value we returns becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Fetch Living Will Details from API and save in redux store
 */
export const fetchWillStatusDetails = createAsyncThunk(
  'willStatus/fetchWillStatusDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useWillStatusApi().getWillStatusDetails(id);

    if (response.status === 200) {
      const willDetails = JSON.parse(JSON.stringify(response?.data?.data));

      return willDetails;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const { resetWillStatusData } = willStatusSlice.actions;

export default willStatusSlice.reducer;
