import {
  IObituaryField,
  IObituaryDetails,
  IObituaryValidations,
} from 'src/datatypes/Obituaries';

// initial state for form and error
export const templateObituaryDetails: IObituaryDetails = {
  id: '',
  first_name: '',
  last_name: '',
  middle_name: '',
  obituary: '',
  birthdate: '',
  deathdate: '',
  contact_name: '',
  contact_email: '',
  death_img: '', //to upload
  death_image: '',//to show as preview
  has_obituary: 1,
  has_funeral_detail: 0,
  posted_date: '',
  location: '',
  city: '',
  state_id: 0,
};

// validations for form
export const obituaryValidations: IObituaryValidations = {
  id: '',
  first_name: 'required|nameValidate|min:2|max:50',
  last_name: 'required|nameValidate|min:2|max:50',
  middle_name: 'nameValidate',
  obituary: 'required',
  birthdate: 'required',
  deathdate: 'required',
  contact_name: 'required',
  contact_email: 'required',
  has_obituary: 'required',
  city: 'required',
  state: 'required',
  death_img: '',
  charity: '',
};

export const searchObituaryField: IObituaryField = {
  first_name: '',
  last_name: '',
  created_date_from: '',
  created_date_to: '',
  state_id: '',
  city: '',
};

export const obituaryListPagination = {
  per_page: 50,
  page: 1,
};
