import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IRouteParams } from 'src/datatypes/CommonTypes';
import { IUsersPayload } from 'src/datatypes/Users';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { Button, Card, Table } from 'react-bootstrap';
import { fetchMemberUserDetails } from 'src/redux/slices/usersSlice';
import { toastErrorMessage } from '../../components/common/ToastMessage';

const MemberDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id }: IRouteParams = useParams();

  const { memberDetails } = useAppSelector<IUsersPayload['members']>(
    (state) => state.users.members
  );

  const { data } = memberDetails;

  const getMemberUserDetails = () => {
    if (id) {
      dispatch(fetchMemberUserDetails(id));
    }
  };

  useEffect(() => {
    getMemberUserDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (memberDetails?.success) {
      if (memberDetails?.data?.user_type === 'admin') {
        toastErrorMessage('User is not a member');
        navigate('/member-users');
      }
    } else if (memberDetails?.error) {
      navigate('/member-users');
    }
    // eslint-disable-next-line
  }, [memberDetails]);

  return (
    <div className="user-details profile-details">
      <PageTitle
        heading={`View Member - ${id} - ${data?.first_name ?? ''} ${
          data?.last_name ?? ''
        }`}
        buttonName="Listing"
        buttonClick={() => {
          navigate('/member-users');
        }}
      />

      {memberDetails.loading ? (
        <Loader isFull />
      ) : !!memberDetails ? (
        <Card>
          <Card.Body>
            <Table className="details-table" bordered hover responsive>
              <tbody>
                <tr>
                  <th className="details-title">First Name</th>
                  <td className="details-value">
                    {data?.first_name ?? 'Not Available'}
                  </td>
                </tr>
                <tr>
                  <th className="details-title">Middle Name</th>
                  <td className="details-value">
                    {data?.middle_name ?? 'Not Available'}
                  </td>
                </tr>
                <tr>
                  <th className="details-title">Last Name</th>
                  <td className="details-value">
                    {data?.last_name ?? 'Not Available'}
                  </td>
                </tr>
                <tr>
                  <th className="details-title">Gender</th>
                  <td className="details-value">
                    {data?.gender ?? 'Not Available'}
                  </td>
                </tr>
                <tr>
                  <th className="details-title">Birthdate</th>
                  <td className="details-value">
                    {data?.birthdate ?? 'Not Available'}
                  </td>
                </tr>
                <tr>
                  <th className="details-title">Mobile No</th>
                  <td className="details-value">
                    {data?.phone ?? 'Not Available'}
                  </td>
                </tr>
                <tr>
                  <th className="details-title">Email</th>
                  <td className="details-value">
                    {data?.email ?? 'Not Available'}
                  </td>
                </tr>
                <tr>
                  <th className="details-title">Address</th>
                  <td className="details-value">
                    {`${data?.address_1 ?? ''} ${data?.address_2 ?? ''},`}
                    <br />
                    {`${data?.city ?? ''}, ${data?.state ?? ''}, ${
                      data?.country ?? ''
                    }, ${data?.zipcode ?? ''}`}
                  </td>
                </tr>
                <tr>
                  <th className="details-title">Bio</th>
                  <td className="details-value">
                    {data?.bio ?? 'Not Available'}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer>
            <Button variant="secondary" onClick={() => navigate(-1)}>
              Back
            </Button>
          </Card.Footer>
        </Card>
      ) : (
        <NotFound
          heading="User Not Found"
          subHeading="Please try again after sometime"
        />
      )}
    </div>
  );
};

export default MemberDetails;
