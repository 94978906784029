import { ValidateField } from '../../helpers/ValidatorHelper';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  testimonialCategoryOptions,
  testimonialDetailsField,
  testimonialDetailsValidations,
} from '../../constants/Testimonial';
import { IRouteParams } from '../../datatypes/CommonTypes';
import {
  ITestimonialDetails,
  ITestimonialPayload,
} from '../../datatypes/Testimonial';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  fetchTestimonialDetails,
  saveTestimonialDetails,
  updateTestimonialDetails,
} from '../../redux/slices/testimonialSlice';
import _forEach from 'lodash/forEach';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import TextInput from '../../components/common/TextInput';
import FileInput from '../../components/common/FileInput';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import SelectInput from '../../components/common/SelectInput';
import { fetchStates } from 'src/redux/slices/locationSlice';
import { ILocationPayload } from 'src/datatypes/Location';

const TestimonialDetails = () => {
  //route related hooks
  const location = useLocation();
  const navigate = useNavigate();
  const { id }: IRouteParams = useParams();

  //redux
  const dispatch = useAppDispatch();

  const { testimonialDetails, saveUpdateStatus } =
    useAppSelector<ITestimonialPayload>((state) => state.testimonial);

  //get state details from redux
  const stateOptions = useAppSelector<ILocationPayload['states']['data']>(
    (state) => state.location.states.data
  );
  const stateLoading = useAppSelector<
    ILocationPayload['states']['stateLoading']
  >((state) => state.location.states.stateLoading);

  //form related states

  const [testimonialType, setTestimonialType] = useState('new');
  const [filePreview, setFilePreview] = useState<string>('');

  const [formData, setFormData] = useState<ITestimonialDetails>({
    ...testimonialDetailsField,
  });

  const [errors, setErrors] = useState<ITestimonialDetails>({
    ...testimonialDetailsField,
  });

  //validations master
  const validations = useMemo(() => {
    return {
      ...testimonialDetailsValidations,
    };
  }, []);

  //handle file upload to help with file preview
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const allowedFileExtensions = ['.png', '.jpeg', '.jpg'];

      // Get the file extension from the file name
      const fileExtension = file.name.substring(file.name.lastIndexOf('.'));

      if (allowedFileExtensions.includes(fileExtension.toLowerCase())) {
        const reader = new FileReader();

        //create and set file preview path
        reader.onload = (event) => {
          if (event.target && typeof event.target.result === 'string') {
            setFilePreview(event.target.result);
          }
        };

        reader.readAsDataURL(file);

        handleInputs(e);
      } else {
        setFormData((prevData) => ({
          ...prevData,
          profile_photo: '',
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          profile_photo: 'Please upload a valid file type (jpeg, jpg, png).',
        }));
        setFilePreview('');
      }
    }
  };

  //initialize api call on page load for edit / view mode
  const getTestimonialDetailsData = () => {
    if (id && id !== 'new') {
      dispatch(fetchTestimonialDetails(id));
      if (location.pathname.includes('edit')) {
        setTestimonialType('edit');
      } else {
        setTestimonialType('view');
      }
    }
  };

  //handle form inputs
  const handleInputs = (e: any) => {
    const name = e.target.name;
    let value = e.target.value;

    //input handling for non-file inputs
    if (name !== 'profile_photo') {
      const error = ValidateField(name, value, validations[name]);
      setFormData((prevData) => ({ ...prevData, [name]: value }));

      if (error) {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      }
    } else {
      //input handling for file
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      setFormData((prevData) => ({ ...prevData, [name]: e.target.files[0] }));
    }
  };

  // check for form errors on submission
  const hasFormError = () => {
    let hasErrors = false;
    _forEach(formData, (value, key) => {
      if (key !== 'profile_photo') {
        const error = ValidateField(key, value, validations[key]);
        if (error) {
          setErrors((prevErrors) => ({ ...prevErrors, [key]: error }));
          hasErrors = true;
        }
      } else {
        if (!(formData[key] instanceof File) && formData[key] !== null) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [key]: 'Please upload a valid file type (jpeg, jpg, png).',
          }));
          hasErrors = true;
        }
      }
    });

    return hasErrors;
  };

  // handle form submission
  const handleSubmit = async (e: React.FocusEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!hasFormError()) {
      let payload = { ...formData };
      //check here if in case of edit it takes id from url
      if (testimonialType === 'edit') {
        payload.id = id;
      }

      // handle dispatch for api calls in case of edit or create
      if (testimonialType === 'new') {
        dispatch(saveTestimonialDetails(payload));
      } else {
        delete payload.date;
        dispatch(updateTestimonialDetails(payload));
      }
    }
  };

  //handle cancel button click
  const handleCancelClick = () => {
    //clear forms and errors
    setFormData({
      ...testimonialDetailsField,
    });
    setErrors({
      ...testimonialDetailsField,
    });

    navigate('/testimonials');
  };

  // Component to render Edit button in page title if currently in view state.
  const EditButtonComponent = () => {
    if (testimonialType === 'view') {
      return (
        <Button
          variant="primary"
          type="button"
          onClick={() => navigate(`/testimonials/${id}/edit`)}
        >
          Edit
        </Button>
      );
    } else {
      return null;
    }
  };

  //handle setting up states on page load
  useEffect(() => {
    setFormData({
      ...testimonialDetailsField,
    });
    if (testimonialDetailsField.profile_photo) {
      setFilePreview(String(testimonialDetailsField.profile_photo));
    }
    getTestimonialDetailsData();

    //fire api call to fetch states
    dispatch(fetchStates());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //handle setting up states on edit / view mode after API call
  useEffect(() => {
    //handle successful api call
    if (testimonialDetails.success) {
      //set form data
      setFormData({ ...testimonialDetails.data });

      //set url for profile picture preview if it exists
      if (testimonialDetails.data.profile_photo) {
        setFilePreview(String(testimonialDetails.data.profile_photo));
      }
    } else if (testimonialDetails.error) {
      // handle failed api call

      setFormData({
        ...testimonialDetailsField,
      });

      navigate('/testimonials');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, testimonialDetails]);

  //handle successful save / update api call
  useEffect(() => {
    if (saveUpdateStatus.success) {
      toastSuccessMessage(`
      Testimonial ${
        testimonialType === 'new' ? 'created' : 'updated'
      } successfully
      `);

      navigate('/testimonials');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveUpdateStatus]);

  useEffect(() => {
    if (id) {
      dispatch(fetchTestimonialDetails(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="testimonial-details profile-details">
      <PageTitle
        heading={
          testimonialType === 'new'
            ? 'Create Testimonial'
            : `Testimonial : ${id}`
        }
        buttonName="Listing"
        buttonClick={() => navigate('/testimonials')}
        customButton={<EditButtonComponent />}
      />

      {testimonialDetails.loading ? (
        <Loader isFull />
      ) : !!testimonialDetails ? (
        <Card className="">
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Body>
              <Row className="align-items-center">
                <Col lg={4} md={12} className="profile-pic-logo-section">
                  {filePreview ? (
                    <div className="profile-pic-logo-wrapper">
                      <img
                        src={filePreview}
                        className="profile-pic-logo-image"
                        alt="Profile Pic"
                      />
                    </div>
                  ) : (
                    <div className="profile-pic-logo-image-NA">
                      Image Not Available
                    </div>
                  )}
                </Col>
                <Col lg={8} md={12}>
                  <TextInput
                    controlId="name"
                    label="Name"
                    className="form-field"
                    placeholder="Name"
                    name="name"
                    value={formData.name}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.name}
                    isDisabled={testimonialType === 'view'}
                  />
                  <TextInput
                    controlId="testimonial_text"
                    label="Testimonial"
                    className="form-field"
                    placeholder="Testimonial"
                    name="testimonial_text"
                    value={formData.testimonial_text}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.testimonial_text}
                    isDisabled={testimonialType === 'view'}
                  />
                  {testimonialType !== 'view' && (
                    <FileInput
                      controlId="profile_photo"
                      label="Profile Pic"
                      className="form-field"
                      placeholder="Profile Pic"
                      name="profile_photo"
                      value={formData.profile_photo}
                      onChange={(e) => handleFileChange(e)}
                      errorMessage={String(errors?.profile_photo)}
                      isRequired={true}
                    />
                  )}
                  <Row>
                    <Col lg={6} md={12}>
                      <SelectInput
                        name="category"
                        placeholder="Select Category"
                        value={formData.category}
                        optionLabel="label"
                        optionValue="value"
                        options={testimonialCategoryOptions}
                        handleSelectChange={(e) => handleInputs(e)}
                        label="Category"
                        isDisabled={testimonialType === 'view'}
                      />
                    </Col>
                    <Col lg={6} md={12}>
                      <SelectInput
                        name="location"
                        placeholder="Select Location"
                        value={formData.location}
                        optionLabel="state_name"
                        optionValue="id"
                        options={stateOptions}
                        isLoading={stateLoading}
                        handleSelectChange={(e) => handleInputs(e)}
                        label="Location"
                        isDisabled={testimonialType === 'view'}
                      />
                    </Col>
                  </Row>
                  {testimonialType === 'view' && (
                    <TextInput
                      controlId="date"
                      label="Created Date"
                      className="form-field"
                      placeholder="Created Date"
                      name="date"
                      value={formData.date}
                      onChange={(e) => handleInputs(e)}
                      errorMessage={errors?.date}
                      isDisabled={testimonialType === 'view'}
                    />
                  )}
                  <Card.Footer>
                    {testimonialType !== 'view' && (
                      <Button
                        variant="primary"
                        className="mr-1"
                        type="submit"
                        disabled={saveUpdateStatus.loading}
                      >
                        {testimonialType === 'new' ? 'Create' : 'Update'}
                      </Button>
                    )}
                    <Button
                      variant="secondary"
                      onClick={handleCancelClick}
                      disabled={saveUpdateStatus.loading}
                    >
                      {testimonialType === 'view' ? 'Close' : 'Cancel'}
                    </Button>
                  </Card.Footer>
                </Col>
              </Row>
            </Card.Body>
          </Form>
        </Card>
      ) : (
        <NotFound
          heading="Testimonial Details"
          subHeading="Testimonial Details not found"
        />
      )}
    </div>
  );
};

export default TestimonialDetails;
