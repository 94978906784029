import useAxiosService from '../services/axios.service';

const useMetasApi = () => {
  const { getUserHttpClient } = useAxiosService();

  //get pages list
  const getMetasList = async () => {
    return await getUserHttpClient()
      .get('/metadata')
      .then((response) => {
        return response;
      });
  };

  //get meta by id
  const getMetaDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/metadata/' + id)
      .then((response) => response);
  };

  //save new meta
  const saveMetaDetails = async (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return await getUserHttpClient()
      .post('/metadata', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to FormData
        },
      })
      .then((response) => response);
  };

  // update meta details
  const updateMetaDetails = async (data: any, id: string) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return await getUserHttpClient()
      .post('/metadata/' + id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to FormData
        },
      })
      .then((response) => response);
  };

  // delete meta details
  const deleteMetaDetails = async (id: string) => {
    return await getUserHttpClient()
      .delete('/metadata/' + id)
      .then((response) => response);
  };

  return {
    getMetasList,
    getMetaDetails,
    saveMetaDetails,
    updateMetaDetails,
    deleteMetaDetails,
  };
};

export default useMetasApi;
