import { ValidateField } from '../../helpers/ValidatorHelper';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  optionsTypes,
  templateDetailsField,
  templateDetailsValidations,
} from 'src/constants/Template';
import { IRouteParams } from 'src/datatypes/CommonTypes';
import { ITemplateDetails, ITemplatePayload } from 'src/datatypes/Template';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import {
  fetchTemplateDetails,
  saveTemplateDetails,
  updateTemplateDetails,
} from 'src/redux/slices/templateSlice';
import _forEach from 'lodash/forEach';
import { Button, Card, Form } from 'react-bootstrap';
import TextInput from '../../components/common/TextInput';
import SelectInput from '../../components/common/SelectInput';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import EditorInput from '../../components/common/EditorInput';
import CheckBoxInput from '../../components/common/CheckBoxInput';

const TemplateDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id }: IRouteParams = useParams();

  const editorConfig = {
    readonly: false,
    height: 300,
    toolbarButtonSize: 'small' as const, // Change this to a valid value like 'small' or 'middle'
  };

  const { templateDetails, saveUpdateStatus } =
    useAppSelector<ITemplatePayload>((state) => state.template);

  const [templateType, setTemplateType] = useState('new');

  const [formData, setFormData] = useState<ITemplateDetails>({
    ...templateDetailsField,
  });

  const [errors, setErrors] = useState<ITemplateDetails>({
    ...templateDetailsField,
  });

  const [initialEditorContent, setInitialEditorContent] = useState('');

  const validations = useMemo(() => {
    return {
      ...templateDetailsValidations,
    };
  }, []);

  const handleInputs = (e: any) => {
    const name = e.target.name;
    let value: string | boolean;
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }
    // let data = {...formData};
    const error = ValidateField(
      name === 'code' ? 'Page title' : name,
      value,
      validations[name]
    );
    if (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
      if (name === 'status') {
        setFormData((prevData) => ({
          ...prevData,
          [name]: e.target.checked ? 'active' : 'inactive',
        }));
      } else {
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      }
    } else {
      if (name === 'status') {
        setFormData((prevData) => ({
          ...prevData,
          [name]: e.target.checked ? 'active' : 'inactive',
        }));
      } else {
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      }
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
  };

  const hasFormError = () => {
    let hasErrors = false;
    _forEach(formData, (value, key) => {
      const error = ValidateField(key, value, validations[key]);
      if (error) {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: error }));
        hasErrors = true;
      }
    });
    return hasErrors;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!hasFormError()) {
      let payload = {
        ...formData,
        status: formData.status === 'active' ? 'active' : 'inactive',
      };
      if (templateType === 'new') {
        dispatch(saveTemplateDetails(payload));
      } else {
        dispatch(updateTemplateDetails(payload));
      }
    }
  };

  useEffect(() => {
    setFormData({
      ...templateDetailsField,
    });
    getTemplateDetailsData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (templateDetails.success) {
      const templateData: ITemplateDetails = {
        code: templateDetails?.data?.code ?? '',
        subject: templateDetails?.data?.subject ?? '',
        content: templateDetails?.data?.content ?? '',
        id: templateDetails?.data?.id ?? '',
        slug: templateDetails?.data?.slug ?? '',
        status: templateDetails?.data?.status ?? '',
        title: templateDetails?.data?.title ?? '',
        type: templateDetails?.data?.type ?? '',
      };
      setFormData({ ...templateData });
      setInitialEditorContent(templateData?.content);
    } else if (templateDetails.error) {
      navigate('/templates');
    }
    // eslint-disable-next-line
  }, [templateDetails]);

  useEffect(() => {
    if (saveUpdateStatus.success) {
      toastSuccessMessage(
        `Template ${
          templateType === 'new' ? 'created' : 'updated'
        } successfully`
      );
      navigate('/templates');
    }
    // eslint-disable-next-line
  }, [saveUpdateStatus]);

  const getTemplateDetailsData = () => {
    if (id && id !== 'new') {
      dispatch(fetchTemplateDetails(id));
      setTemplateType('edit');
    }
  };

  return (
    <div className="template-details">
      <PageTitle
        heading={
          templateType === 'new'
            ? 'New Template'
            : ` Template : ${templateDetails?.data?.title}`
        }
        buttonName="Listing"
        buttonClick={() => navigate('/templates')}
      />

      {templateDetails.loading ? (
        <Loader isFull />
      ) : !!templateDetails ? (
        <Card>
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Body>
              <TextInput
                controlId="title"
                label="Title"
                className="form-field"
                placeholder="Title"
                name="title"
                value={formData.title}
                onChange={(e) => handleInputs(e)}
                errorMessage={errors?.title}
              />
              <TextInput
                controlId="slug"
                label="Slug"
                className="form-field"
                placeholder="Slug"
                name="slug"
                value={formData.slug}
                onChange={(e) => handleInputs(e)}
                errorMessage={errors?.slug}
              />
              <TextInput
                controlId="code"
                label="Page Title"
                className="form-field"
                placeholder="Page Title"
                name="code"
                value={formData.code}
                onChange={(e) => handleInputs(e)}
                errorMessage={errors?.code}
              />
              <TextInput
                controlId="subject"
                label="Subject"
                className="form-field"
                placeholder="Subject"
                name="subject"
                value={formData.subject}
                onChange={(e) => handleInputs(e)}
                errorMessage={errors?.subject}
              />
              <SelectInput
                name="type"
                placeholder="Type"
                value={formData.type}
                options={optionsTypes}
                handleSelectChange={(e) => handleInputs(e)}
                label="Template Type"
                errorMessage={errors?.type}
              />
              <EditorInput
                initialValue={initialEditorContent}
                value={formData.content}
                config={editorConfig}
                controlId="content"
                label="Content"
                errorMessage={errors?.content}
                onChange={(e) => {
                  if (e) {
                    handleInputs({
                      target: {
                        name: 'content',
                        value: e,
                        type: 'string',
                      },
                    });
                  }
                }}
              />
              <CheckBoxInput
                name="status"
                value={formData.status === 'active' ? true : false}
                label="Status"
                onChange={(e) => handleInputs(e)}
                errorMessage={errors?.status}
              />
            </Card.Body>
            <Card.Footer>
              <Button variant="primary" className="mr-1" type="submit">
                {templateType === 'new' ? 'Save' : 'Update'}
              </Button>
              <Button variant="secondary" type="link" href="/templates">
                Cancel
              </Button>
            </Card.Footer>
          </Form>
        </Card>
      ) : (
        <NotFound
          heading="Template Not Found"
          subHeading="Please try again after sometime"
        />
      )}
    </div>
  );
};

export default TemplateDetails;
