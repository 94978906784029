import Modal from 'react-bootstrap/Modal';
import FowButton from '../common/FowButton';
import { IFaqPayload, IFaqRow } from 'src/datatypes/Faq';

interface IProps {
  show: boolean;
  toggleDeleteModal: () => void;
  handleSubmit: () => void;
  selectedIdDetails: IFaqRow[];
  deleteFaqDetail: IFaqPayload['deleteFaqStatus'];
}

const DeleteModal = ({
  show,
  toggleDeleteModal,
  handleSubmit,
  selectedIdDetails,
  deleteFaqDetail,
}: IProps) => {
  return (
    <Modal show={show} onHide={toggleDeleteModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Do you want to delete faq ?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="delete-template">
          You will delete
          {selectedIdDetails?.length > 0 &&
            selectedIdDetails.map((faqDetail: IFaqRow, index: number) => (
              <span className="mx-2">
                {faqDetail.question_text}
                {selectedIdDetails?.length !== index + 1 && ','}
              </span>
            ))}
          faq !
        </div>
        <div></div>
      </Modal.Body>
      <Modal.Footer>
        <FowButton variant="secondary" onClick={toggleDeleteModal}>
          Close
        </FowButton>
        <FowButton
          variant="danger"
          onClick={handleSubmit}
          isLoading={deleteFaqDetail?.loading}
        >
          Delete
        </FowButton>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
