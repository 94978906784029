import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useTestimonialsApi from 'src/api/testimonials.api';
import {
  ITestimonialDetails,
  ITestimonialPayload,
} from 'src/datatypes/Testimonial';

// initial / template state of testimonial redux slice
const initialState: ITestimonialPayload = {
  testimonialList: {
    loading: false,
    data: {
      data: [],
      current_page: 0,
      total: 0,
    },
    error: false,
    success: false,
  },
  testimonialDetails: {
    loading: false,
    data: {
      id: '',
      name: '',
      testimonial_text: '',
      profile_photo: '',
    },
    error: false,
    success: false,
  },
  saveUpdateStatus: {
    loading: false,
    success: false,
    error: false,
  },
  deleteTestimonialStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

// redux slice for testimonials
export const testimonialsSlice = createSlice({
  name: 'testimonials',
  initialState,
  // reducers that don't require API calls
  reducers: {
    resetTestimonialData: (state: ITestimonialPayload) => {
      state.saveUpdateStatus = initialState.saveUpdateStatus;
      state.deleteTestimonialStatus = initialState.deleteTestimonialStatus;
      state.testimonialDetails = initialState.testimonialDetails;
    },
  },
  // reducers that require API calls
  extraReducers: (builder) => {
    builder
      .addCase(fetchTestimonialsList.pending, (state) => {
        state.testimonialList.loading = true;
        state.testimonialList.error = false;
        state.testimonialList.success = false;
        state.testimonialList.data = initialState.testimonialList.data;
      })
      .addCase(fetchTestimonialsList.fulfilled, (state, { payload }) => {
        state.testimonialList.data.data = payload.testimonials;
        state.testimonialList.data.current_page = payload.current_page ?? 0;
        state.testimonialList.data.total = payload.total ?? 0;
        state.testimonialList.loading = false;
        state.testimonialList.error = false;
        state.testimonialList.success = true;
      })
      .addCase(fetchTestimonialsList.rejected, (state) => {
        state.testimonialList.loading = false;
        state.testimonialList.error = true;
        state.testimonialList.success = false;
        state.testimonialList.data = initialState.testimonialList.data;
      })
      .addCase(fetchTestimonialDetails.pending, (state) => {
        state.testimonialDetails.loading = true;
        state.testimonialDetails.error = false;
        state.testimonialDetails.success = false;
      })
      .addCase(fetchTestimonialDetails.fulfilled, (state, { payload }) => {
        state.testimonialDetails.data = payload;
        state.testimonialDetails.success = true;
        state.testimonialDetails.error = false;
        state.testimonialDetails.loading = false;
      })
      .addCase(fetchTestimonialDetails.rejected, (state) => {
        state.testimonialDetails.loading = false;
        state.testimonialDetails.error = true;
        state.testimonialDetails.success = false;
      })
      .addCase(saveTestimonialDetails.pending, (state) => {
        state.saveUpdateStatus.loading = true;
        state.saveUpdateStatus.error = false;
        state.saveUpdateStatus.success = false;
      })
      .addCase(saveTestimonialDetails.fulfilled, (state) => {
        state.saveUpdateStatus.success = true;
        state.saveUpdateStatus.error = false;
        state.saveUpdateStatus.loading = false;
      })
      .addCase(saveTestimonialDetails.rejected, (state) => {
        state.saveUpdateStatus.loading = false;
        state.saveUpdateStatus.error = true;
        state.saveUpdateStatus.success = false;
      })
      .addCase(updateTestimonialDetails.pending, (state) => {
        state.saveUpdateStatus.loading = true;
        state.saveUpdateStatus.error = false;
        state.saveUpdateStatus.success = false;
      })
      .addCase(updateTestimonialDetails.fulfilled, (state) => {
        state.saveUpdateStatus.success = true;
        state.saveUpdateStatus.error = false;
        state.saveUpdateStatus.loading = false;
      })
      .addCase(updateTestimonialDetails.rejected, (state) => {
        state.saveUpdateStatus.loading = false;
        state.saveUpdateStatus.error = true;
        state.saveUpdateStatus.success = false;
      })
      .addCase(deleteTestimonialData.pending, (state) => {
        state.deleteTestimonialStatus.success = false;
        state.deleteTestimonialStatus.loading = true;
        state.deleteTestimonialStatus.error = false;
      })
      .addCase(deleteTestimonialData.fulfilled, (state) => {
        state.deleteTestimonialStatus.success = true;
        state.deleteTestimonialStatus.loading = false;
        state.deleteTestimonialStatus.error = false;
      })
      .addCase(deleteTestimonialData.rejected, (state) => {
        state.deleteTestimonialStatus.success = false;
        state.deleteTestimonialStatus.loading = false;
        state.deleteTestimonialStatus.error = true;
      });
  },
});

// Async API Hooks

/**
 * Fetch Testimonials List from API and save in redux store
 */
export const fetchTestimonialsList = createAsyncThunk(
  'testimonials/fetchTestimonialsList',
  async (_, { rejectWithValue }) => {
    const response = await useTestimonialsApi().getTestimonialsList();
    // The value we returns becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Fetch Testimonial Details for the from API and save in redux store
 */
export const fetchTestimonialDetails = createAsyncThunk(
  'testimonials/fetchTestimonialDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useTestimonialsApi().getTestimonialDetails(id);
    // The value we returns becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data?.testimonial;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Save Testimonial Details
 */
export const saveTestimonialDetails = createAsyncThunk(
  'testimonials/saveTestimonialDetails',
  async (data: ITestimonialDetails, { rejectWithValue }) => {
    data['date'] = new Date().toISOString().slice(0, 10);
    const response = await useTestimonialsApi().saveTestimonialDetails(data);
    // The value we returns becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Update Testimonial Details
 */
export const updateTestimonialDetails = createAsyncThunk(
  'testimonials/updateTestimonialDetails',
  async (data: ITestimonialDetails, { rejectWithValue }) => {
    if (typeof data['profile_photo'] !== 'object') {
      delete data['profile_photo'];
    }
    const id = data.id;
    delete data.id;
    const response = await useTestimonialsApi().updateTestimonialDetails(
      data,
      String(id)
    );
    // The value we returns becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Delete Testimonial API
 */
export const deleteTestimonialData = createAsyncThunk(
  'testimonials/deleteTestimonial',
  async (id: string, { rejectWithValue }) => {
    const response = await useTestimonialsApi().deleteTestimonialData(id);
    // The value we returns becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const { resetTestimonialData } = testimonialsSlice.actions;

export default testimonialsSlice.reducer;
