import { ValidateField } from '../../helpers/ValidatorHelper';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import FowButton from '../../components/common/FowButton';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { IRouteParams } from 'src/datatypes/CommonTypes';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import _forEach from 'lodash/forEach';

import {
  templateFaqDetailsField,
  faqDetailsValidations,
  statusOptions,
  typeOptions,
} from 'src/constants/Faq';
import { IFaqDetails, IFaqPayload } from 'src/datatypes/Faq';
import {
  fetchFaqDetails,
  resetFaqData,
  saveUpdateFaq,
} from 'src/redux/slices/faqSlice';
import TextInput from '../../components/common/TextInput';
import RadioGroupInput from '../../components/common/RadioGroupInput';
import SelectInput from '../../components/common/SelectInput';

const FaqsDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id }: IRouteParams = useParams();
  const location = useLocation();

  const { faqDetails, saveUpdateStatus } = useAppSelector<IFaqPayload>(
    (state) => state.faqs
  );

  const [faqType, setFaqType] = useState<string>('new');

  const [formData, setFormData] = useState<IFaqDetails>(
    templateFaqDetailsField
  );

  const [errors, setErrors] = useState<IFaqDetails>(templateFaqDetailsField);

  const validations = useMemo(() => faqDetailsValidations, []);

  // function to handle inputs
  const handleInputs = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    let value: string | number;
    let error: string | undefined | null;

    //get value from checkbox / others
    if (e.target.type === 'checkbox') {
      value = e.target.checked ? e.target.defaultValue : '';
    } else {
      value = e.target.value;
    }
    const fieldName = e.target.name;
    //check for errors
    error = ValidateField(fieldName, value, validations[name]);

    //set form data
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    //set errors
    if (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
  };

  // function to check for errors on form submit
  const hasFormError = () => {
    let hasError = false;
    _forEach(formData, (value, key) => {
      //set field name
      const fieldName = key;

      //check for errors
      const error = ValidateField(fieldName, value, validations[key]);

      //set errors
      if (error) {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: error }));
        hasError = true;
      }
    });

    // return errors status
    return hasError;
  };

  // function to handle form submit
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    //check if form has errors
    if (!hasFormError()) {
      // if no errors call api
      dispatch(saveUpdateFaq(formData));
    }
  };

  //function to get faq details and set faq type
  const getFaqDetailsData = async () => {
    // check for id
    if (id && id !== 'new') {
      dispatch(fetchFaqDetails(id));

      // check for mode edit / view
      const containsView = location.pathname.includes('view');
      if (containsView) {
        setFaqType('view');
      } else {
        setFaqType('edit');
      }
    } else {
      // set data for new entry
      setFaqType('new');
    }
  };

  // Component to render Edit button in page title if currently in view state.
  const EditButtonComponent = () => {
    if (faqType === 'view') {
      return (
        <Button
          variant="primary"
          type="button"
          onClick={() => navigate(`/faqs/${id}/edit`)}
        >
          Edit
        </Button>
      );
    } else {
      return null;
    }
  };

  // useEffect to get faq details
  useEffect(() => {
    getFaqDetailsData();
    // eslint-disable-next-line
  }, []);

  // check for success and error
  useEffect(() => {
    if (saveUpdateStatus.success) {
      toastSuccessMessage(
        `Faq Successfully ${faqType === 'new' ? 'Created' : 'Updated'}`
      );
      dispatch(resetFaqData());
      navigate('/faqs');
    }
    if (saveUpdateStatus.error) {
      dispatch(resetFaqData());
    }
    // eslint-disable-next-line
  }, [saveUpdateStatus]);

  useEffect(() => {
    if (!faqDetails.loading && faqDetails?.success) {
      setFormData(faqDetails?.data);
    }
    // eslint-disable-next-line
  }, [faqDetails]);

  return (
    <div className="faqs-details">
      <PageTitle
        heading={
          faqType === 'new'
            ? 'New Faq'
            : ` Faq : ${faqDetails?.data?.question_text ?? ''}`
        }
        buttonName="Listing"
        buttonClick={() => navigate('/faqs')}
        customButton={<EditButtonComponent />}
      />

      {faqDetails?.loading ? (
        <Loader isFull />
      ) : !!faqDetails ? (
        <Card>
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Body>
              <Row className="align-items-center">
                <Col lg={12} md={12}>
                  <TextInput
                    controlId="question_text"
                    label="Question Text"
                    className="form-field"
                    placeholder="Question Text"
                    name="question_text"
                    value={formData.question_text}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.question_text}
                    isRequired={true}
                    isDisabled={faqType === 'view'}
                  />
                </Col>
                <Col lg={12} md={12}>
                  <TextInput
                    controlId="answer_text"
                    label="Answer Text"
                    className="form-field"
                    placeholder="Enter Answer Text"
                    name="answer_text"
                    value={formData.answer_text}
                    onChange={handleInputs}
                    errorMessage={errors.answer_text}
                    isRequired={true}
                    type="textarea"
                    isDisabled={faqType === 'view'}
                    rows={4}
                  />
                </Col>
                <Col
                  lg={faqType === 'view' ? 12 : 6}
                  md={faqType === 'view' ? 12 : 6}
                >
                  <SelectInput
                    name="type"
                    placeholder="Select Category"
                    value={formData.type}
                    optionLabel="label"
                    optionValue="value"
                    options={typeOptions}
                    handleSelectChange={(e) => handleInputs(e)}
                    label="Category"
                    isRequired={true}
                    isDisabled={faqType === 'view'}
                  />
                </Col>
                <Col
                  lg={faqType === 'view' ? 12 : 6}
                  md={faqType === 'view' ? 12 : 6}
                >
                  <RadioGroupInput
                    name="status"
                    value={formData.status}
                    label="Status"
                    options={statusOptions}
                    onChange={(e) =>
                      faqType === 'view' ? {} : handleInputs(e)
                    }
                    isRequired={true}
                    errorMessage={String(errors?.status)}
                    controlId="status"
                    // isDisabled={pageType === 'view'}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              {faqType !== 'view' && (
                <FowButton
                  variant="primary"
                  onClick={handleSubmit}
                  isLoading={saveUpdateStatus.loading}
                >
                  {faqType === 'new' ? 'Save' : 'Update'}
                </FowButton>
              )}
              <FowButton
                variant="secondary"
                onClick={() => {
                  dispatch(resetFaqData());
                  navigate('/faqs');
                }}
                isDisabled={saveUpdateStatus.loading}
              >
                Cancel
              </FowButton>
            </Card.Footer>
          </Form>
        </Card>
      ) : (
        <NotFound
          heading="Faq Not Found"
          subHeading="Please try again after sometime."
        />
      )}
    </div>
  );
};

export default FaqsDetails;
