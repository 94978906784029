import Modal from 'react-bootstrap/Modal';
import FowButton from '../common/FowButton';
import { ILocationPayload, IStateRow } from 'src/datatypes/Location';

interface IProps {
  show: boolean;
  toggleDeleteModal: () => void;
  handleSubmit: () => void;
  selectedIdDetails: IStateRow[];
  deleteStateDetails: ILocationPayload['states']['deleteStateStatus'];
}

const DeleteModal = ({
  show,
  toggleDeleteModal,
  handleSubmit,
  selectedIdDetails,
  deleteStateDetails,
}: IProps) => {
  return (
    <>
      <Modal show={show} onHide={toggleDeleteModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Do you want to delete state?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-state">
            You will delete
            {selectedIdDetails?.length > 0 &&
              selectedIdDetails.map((stateDetail: IStateRow, index: number) => (
                <span className="mx-2" key={index}>
                  {stateDetail.state_name}
                </span>
              ))}
            state!
          </div>
          <div></div>
        </Modal.Body>
        <Modal.Footer>
          <FowButton variant="secondary" onClick={toggleDeleteModal}>
            Close
          </FowButton>
          <FowButton
            variant="danger"
            onClick={handleSubmit}
            isLoading={deleteStateDetails?.loading}
          >
            Delete
          </FowButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
