import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useDashboardApi from 'src/api/dashboard.api';
import { IDashboardPayload } from 'src/datatypes/Dashboard';

// initial / template state of dashboard redux slice
const initialState: IDashboardPayload = {
  dashboardData: {
    loading: false,
    error: false,
    success: false,
    data: {
      invoiceData: {
        data: [],
        total: 0,
      },
      missingWillData: {
        data: [],
        total: 0,
      },
      willCreatorsData: {
        data: [],
        total: 0,
      },
    },
  },
};

// redux slice for dashboard
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  // reducers that don't require API calls
  reducers: {},
  // reducers that require API calls
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.dashboardData = { ...initialState.dashboardData, loading: true };
      })
      .addCase(fetchDashboardData.fulfilled, (state, { payload }) => {
        state.dashboardData = {
          loading: false,
          error: false,
          success: payload.success,
          data: {
            invoiceData: payload.data.invoices,
            missingWillData: payload.data.missingWillSearches,
            willCreatorsData: payload.data.memberWills,
          },
        };
      })
      .addCase(fetchDashboardData.rejected, (state) => {
        state.dashboardData = {
          ...initialState.dashboardData,
          loading: false,
          error: true,
        };
      });
  },
});

// Async API Hooks

/**
 * Fetch Dashboard Lists from API and save in redux store
 */
export const fetchDashboardData = createAsyncThunk(
  'dashboard/fetchDashboardData',
  async (_, { rejectWithValue }) => {
    const response = await useDashboardApi().getDashboardListings();

    // The value we returns becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export default dashboardSlice.reducer;
