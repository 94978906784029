import { useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../../components/common/PageTitle';
import { useEffect } from 'react';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { IRouteParams } from 'src/datatypes/CommonTypes';
import { IDeathNoticePayload } from 'src/datatypes/DeathNotice';
import { fetchDeathNoticeDetails } from 'src/redux/slices/deathNoticeSlice';
import { Col, Row, Table } from 'react-bootstrap';

const DeathNoticeDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id }: IRouteParams = useParams();

  const { deathNoticeDetails } = useAppSelector<IDeathNoticePayload>(
    (state) => state.deathNotice
  );

  const { data } = deathNoticeDetails;

  useEffect(() => {
    getDeathNoticeDetailsData();
    // eslint-disable-next-line
  }, []);

  const getDeathNoticeDetailsData = () => {
    if (id) {
      dispatch(fetchDeathNoticeDetails(id));
    }
  };

  return (
    <div className="death-notice-details">
      <PageTitle
        heading={`View Death Notice ${
          deathNoticeDetails?.data?.invoice_information?.invoice_number
            ? deathNoticeDetails?.data?.invoice_information?.invoice_number
            : ''
        }`}
        buttonName="Listing"
        buttonClick={() => navigate('/death-notices')}
      />

      {deathNoticeDetails.loading ? (
        <Loader isFull />
      ) : !!deathNoticeDetails ? (
        <>
          <h4>Personal Details</h4>
          <Row>
            {data?.death_img && (
              <Col lg={6} md={6}>
                <img
                  src={data?.death_img}
                  className={'death-notice-death-image'}
                  alt={`${data?.first_name} ${data?.last_name}`}
                />
              </Col>
            )}
            <Col lg={data?.death_img ? 6 : 12} md={6}>
              <Table
                className="death-notice-details-table table table-hover table-bordered"
                bordered
                hover
              >
                <tbody>
                  <tr>
                    <th className="death-notice-personal-details-title">ID</th>
                    <td className="death-notice-personal-details-value">
                      {id}
                    </td>
                  </tr>
                  <tr>
                    <th className="death-notice-personal-details-title">
                      First Name
                    </th>
                    <td className="death-notice-personal-details-value">
                      {data?.first_name ?? 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th className="death-notice-personal-details-title">
                      Middle Name
                    </th>
                    <td className="death-notice-personal-details-value">
                      {data?.middle_name ?? 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th className="death-notice-personal-details-title">
                      Last Name
                    </th>
                    <td className="death-notice-personal-details-value">
                      {data?.last_name ?? 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th className="death-notice-personal-details-title">
                      Email Address
                    </th>
                    <td className="death-notice-personal-details-value">
                      {data?.email ?? 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th className="death-notice-personal-details-title">
                      Birth Date
                    </th>
                    <td className="death-notice-personal-details-value">
                      {data?.birthdate ?? 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th className="death-notice-personal-details-title">
                      Death Date
                    </th>
                    <td className="death-notice-personal-details-value">
                      {data?.deathdate ?? 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th className="death-notice-personal-details-title">
                      Location
                    </th>
                    <td className="death-notice-personal-details-value">
                      {data?.location ?? 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th className="death-notice-personal-details-title">
                      Posted On
                    </th>
                    <td className="death-notice-personal-details-value">
                      {data?.posted_date ?? 'N/A'}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          {data?.has_obituary === 1 && (
            <div style={{ marginTop: '0.5rem' }}>
              <h4>Obituary Details</h4>
              <Table
                className="death-notice-details-table table table-hover table-bordered"
                bordered
                hover
              >
                <tbody>
                  <tr>
                    <th className="death-notice-personal-details-title">
                      Obituary
                    </th>
                    <td className="death-notice-personal-details-value">
                      {data?.obituary ?? 'N/A'}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
          {data?.has_funeral_detail === 1 && (
            <>
              <h4>Funeral Details</h4>
              <Table
                className="death-notice-details-table table table-hover table-bordered"
                bordered
                hover
              >
                <tbody>
                  <tr>
                    <th className="text-left">Home</th>
                    <td>{data?.funeral_home ?? 'N/A'}</td>
                    <th className="text-left">Funeral Date / Time</th>
                    <td>{data?.funeral_datetime ?? 'N/A'}</td>
                    <th className="text-left">Phone No</th>
                    <td>{data?.phone ?? 'N/A'}</td>
                  </tr>
                  <tr>
                    <th className="text-left">Death Cause</th>
                    <td colSpan={2}>{data?.deathCause ?? 'N/A'}</td>
                    <th className="text-left">Charity</th>
                    <td colSpan={2}>{data?.charity ?? 'N/A'}</td>
                  </tr>
                  <tr>
                    <th className="text-left">Address</th>
                    <td colSpan={5}>
                      {' '}
                      {`${data?.funeral_address}`}
                      <br />
                      {`${data?.funeral_city}, ${data?.funeral_county} ${data?.funeral_state} ${data?.funeral_zipcode}`}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </>
      ) : (
        <NotFound
          heading="Death Notice Details Not Found!"
          subHeading="Please try again after sometime."
        />
      )}
    </div>
  );
};

export default DeathNoticeDetails;
