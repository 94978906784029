import PageTitle from '../../components/common/PageTitle';
import { useState, useEffect } from 'react';
import {
  usersListPagination,
  usersSearchValidations,
  usersSearchField,
} from 'src/constants/Users';
import { IUsersPagination, IUsersPayload } from 'src/datatypes/Users';
import { fetchMemberUsersList } from 'src/redux/slices/usersSlice';
import Listing from 'src/components/member-users/Listing';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { Button } from 'react-bootstrap';
import UsersSearch from '../../components/member-users/UsersSearch';
import { ValidateField } from '../../helpers/ValidatorHelper';

const MemberListing = () => {
  const dispatch = useAppDispatch();

  const [usersSearch, setUsersSearch] = useState({
    ...usersSearchField,
  });

  const [errors, setErrors] = useState({
    ...usersSearchField,
  });

  const [lastValidSearch, setLastValidSearch] = useState({
    ...usersSearchField,
  });

  const [showAdvancedSearch, setShowAdvancedSearch] = useState({
    show: false,
    filterApplied: false,
  });

  const [pagination, setPagination] =
    useState<IUsersPagination>(usersListPagination);

  const { memberList } = useAppSelector<IUsersPayload['members']>(
    (state) => state.users.members
  );

  //function to toggle advanced search
  const toggleAdvancedSearch = () => {
    setShowAdvancedSearch({
      ...showAdvancedSearch,
      show: !showAdvancedSearch.show,
    });
  };

  //function to toggle filter applied
  const toggleFilterApplied = (isApplied: boolean) => {
    setShowAdvancedSearch({
      ...showAdvancedSearch,
      filterApplied: isApplied,
    });
  };

  //function to handle search input
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUsersSearch((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const error =
      value === ''
        ? null
        : ValidateField(name, value, String(usersSearchValidations[name]));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error || '',
    }));
  };

  //function to handle validation on search click
  const validateForm = () => {
    const hasValues = Object.keys(usersSearch).some(
      (key) => usersSearch[key] !== ''
    );
    const hasErrors = Object.keys(errors).some((key) => errors[key] !== '');
    return !hasValues || hasErrors;
  };

  //function to handle search button click
  const onUsersSearch = () => {
    const hasError = validateForm();
    if (!hasError) {
      const payload = {
        ...usersSearch,
        page: 1,
        per_page: pagination?.per_page,
      };
      dispatch(fetchMemberUsersList({ params: payload }));
      setLastValidSearch(payload);
      setPagination({
        ...pagination,
        page: 1,
      });
      toggleFilterApplied(true);
    }
  };

  //function to handle reset button click
  const resetUsersSearch = () => {
    dispatch(fetchMemberUsersList({ params: usersListPagination }));
    setUsersSearch({
      ...usersSearchField,
    });
    setLastValidSearch({
      ...usersSearchField,
    });
    setPagination(usersListPagination);
    toggleFilterApplied(false);
  };

  useEffect(() => {
    if (!memberList.loading) {
      dispatch(
        fetchMemberUsersList({
          params: {
            ...lastValidSearch,
            ...pagination,
          },
        })
      );
    }
    // eslint-disable-next-line
  }, [pagination]);

  return (
    <div className="admin-users-templates">
      <PageTitle heading="Member Users" />
      <div className="control-top-buttom mb-2">
        <Button
          variant="primary"
          className="mr-2"
          onClick={() => {
            toggleAdvancedSearch();
          }}
        >
          Advanced Search
        </Button>
      </div>
      <div className="admin-users-content">
        {showAdvancedSearch.show && (
          <UsersSearch
            errors={errors}
            handleInputChange={handleInputChange}
            usersSearch={usersSearch}
            onUserSearch={onUsersSearch}
            resetUserSearch={resetUsersSearch}
            isFilterApplied={showAdvancedSearch.filterApplied}
            isDataLoading={memberList.loading}
          />
        )}
        <Listing usersList={memberList} setPagination={setPagination} />
      </div>
    </div>
  );
};

export default MemberListing;
