import { Card, Button } from 'react-bootstrap';

const ZipcodeListing = () => {
  return (
    <Card>
      <Card.Body>
        <div className="">
          <Button href="/zipcodes/new">
            <i className="fa fa-plus"></i> NEW
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ZipcodeListing;
