import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useLivingWillFormApi from 'src/api/livingWillForm.api';
import {
  ISaveUpdateLiving,
  ILivingWillFormPayload,
} from 'src/datatypes/LivingWillForms';

// initial / template state of living will form redux slice
const initialState: ILivingWillFormPayload = {
  formList: {
    loading: false,
    data: {
      data: [],
      current_page: 0,
      total: 0,
    },
    error: false,
    success: false,
  },
  formDetails: {
    loading: false,
    data: {
      site_title: '',
      page_title: '',
      meta_title: '',
      meta_description: '',
      state: '',
      pdf_name: '',
    },
    error: false,
    success: false,
  },
  saveUpdateForm: {
    loading: false,
    error: false,
    success: false,
  },
  deleteFormStatus: {
    loading: false,
    error: false,
    success: false,
  },
};

// redux slice for living will form
export const livingWillFormSlice = createSlice({
  name: 'livingWillForm',
  initialState,
  // reducers that don't require API calls
  reducers: {
    resetFormData: (state: ILivingWillFormPayload) => {
      state.formDetails = initialState.formDetails;
      state.saveUpdateForm = initialState.saveUpdateForm;
      state.deleteFormStatus = initialState.deleteFormStatus;
    },
  },
  // reducers that require API calls
  extraReducers: (builder) => {
    builder
      .addCase(fetchLivingWillFormList.pending, (state) => {
        state.formList.loading = true;
        state.formList.error = false;
        state.formList.success = false;
      })
      .addCase(fetchLivingWillFormList.fulfilled, (state, { payload }) => {
        state.formList.data.data = payload?.livingWillForms;
        state.formList.data.current_page = payload?.current_page ?? 0;
        state.formList.data.total = payload?.total ?? 0;
        state.formList.loading = false;
        state.formList.success = true;
      })
      .addCase(fetchLivingWillFormList.rejected, (state) => {
        state.formList.loading = false;
        state.formList.error = true;
      })
      .addCase(fetchLivingWillFormDetails.pending, (state) => {
        state.formDetails.loading = true;
        state.formDetails.error = false;
        state.formDetails.success = false;
      })
      .addCase(fetchLivingWillFormDetails.fulfilled, (state, { payload }) => {
        state.formDetails.data = payload?.livingWillForms;
        state.formDetails.loading = false;
        state.formDetails.success = true;
      })
      .addCase(fetchLivingWillFormDetails.rejected, (state) => {
        state.formDetails.loading = false;
        state.formDetails.error = true;
      })
      .addCase(saveUpdateLivingWillForm.pending, (state) => {
        state.saveUpdateForm.loading = true;
        state.saveUpdateForm.error = false;
        state.saveUpdateForm.success = false;
      })
      .addCase(saveUpdateLivingWillForm.fulfilled, (state) => {
        state.saveUpdateForm.loading = false;
        state.saveUpdateForm.success = true;
      })
      .addCase(saveUpdateLivingWillForm.rejected, (state) => {
        state.saveUpdateForm.loading = false;
        state.saveUpdateForm.error = true;
      })
      .addCase(deleteLivingWillForm.pending, (state) => {
        state.deleteFormStatus.loading = true;
        state.deleteFormStatus.error = false;
        state.deleteFormStatus.success = false;
      })
      .addCase(deleteLivingWillForm.fulfilled, (state) => {
        state.deleteFormStatus.loading = false;
        state.deleteFormStatus.success = true;
      })
      .addCase(deleteLivingWillForm.rejected, (state) => {
        state.deleteFormStatus.loading = false;
        state.deleteFormStatus.error = true;
      });
  },
});

// Async API Hooks

/**
 * Fetch Living Form List from API and save in redux store
 */
export const fetchLivingWillFormList = createAsyncThunk(
  'livingWillForm/fetchLivingWillFormList',
  async (_, { rejectWithValue }) => {
    const response = await useLivingWillFormApi().getLivingWillFormList();
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Fetch Living Form Details from API and save in redux store
 */
export const fetchLivingWillFormDetails = createAsyncThunk(
  'livingWillForm/fetchLivingWillFormDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useLivingWillFormApi().getLivingWillFormDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Save Testimonial Details
 */
export const saveUpdateLivingWillForm = createAsyncThunk(
  'livingWillForm/saveUpdateLivingWillForm',
  async (data: ISaveUpdateLiving, { rejectWithValue }) => {
    if (typeof data.data['pdf_name'] !== 'object') {
      delete data.data['pdf_name'];
    }
    const id = data.id;
    delete data.id;
    const response = id
      ? await useLivingWillFormApi().updateLivingWillForm(data.data, id)
      : await useLivingWillFormApi().saveLivingWillForm(data.data);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Delete Living Will Form
 */
export const deleteLivingWillForm = createAsyncThunk(
  'livingWillForm/deleteLivingWillForm',
  async (id: string, { rejectWithValue }) => {
    const response = await useLivingWillFormApi().deleteLivingWillForm(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const { resetFormData } = livingWillFormSlice.actions;

export default livingWillFormSlice.reducer;
