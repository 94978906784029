import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import ErrorMessage from './ErrorMessage';
import { fetchCounties } from '../../redux/slices/locationSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { OptionType } from '../../datatypes/CommonTypes';
import { ILocationPayload } from 'src/datatypes/Location';

interface IProps {
  name: string;
  value: string | number | null;
  fieldKey?: string | number;
  instructions?: string;
  isLoading?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
  type?: string;
  label?: string;
  handleSelectChange: (e: any) => void;
  isRequired?: boolean;
  errorMessage?: string;
  controlId?: string;
  className?: string;
  placeholder?: string;
  selectedState?: string | number;
}

const SelectCountyInput = ({
  fieldKey,
  name,
  value,
  label,
  handleSelectChange,
  isRequired,
  errorMessage,
  controlId,
  className,
  placeholder,
  instructions,
  isClearable,
  isMulti,
  isDisabled,
  selectedState,
}: IProps) => {
  const [selectedOption, setSelectedOption] = useState<string | number | null>(
    null
  );
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState<OptionType<string | number>[]>([]);

  const states = useAppSelector<ILocationPayload['states']['data']>(
    (state) => state.location.states.data
  );
  const counties = useAppSelector<ILocationPayload['counties']>(
    (state) => state.location.counties
  );
  const countyLoading = useAppSelector<ILocationPayload['countyLoading']>(
    (state) => state.location.countyLoading
  );

  useEffect(() => {
    if (value) {
      setSelectedOption(value);
    } else {
      setSelectedOption(null);
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    const findStateCodeBillingInfo = states.find(
      (state: any) => state.id === selectedState
    );
    if (findStateCodeBillingInfo) {
      dispatch(fetchCounties(findStateCodeBillingInfo.state_abbr));
    }
    // eslint-disable-next-line
  }, [dispatch, selectedState]);

  useEffect(() => {
    if (!countyLoading) {
      setOptions(
        counties.map((county: any) => ({
          label: county.county_name,
          value: county.id,
        }))
      );
    }
    // eslint-disable-next-line
  }, [countyLoading]);

  return (
    <Form.Group key={fieldKey} controlId={controlId}>
      <div className="instruction-title">
        <Form.Label>
          {label}
          {isRequired && <span className="required">*</span>}
        </Form.Label>
        {instructions && (
          <span className="instruction-text">({instructions})</span>
        )}
      </div>
      <Select
        placeholder={placeholder}
        classNamePrefix={`fow-select fow-custom-select ${
          errorMessage ? 'error-focus-input' : ''
        } ${className ? className : ''}`}
        isDisabled={isDisabled}
        isClearable={isClearable}
        isLoading={countyLoading}
        getOptionLabel={(option: any) => option?.['label']}
        getOptionValue={(option: any) => option?.['value']}
        isMulti={isMulti}
        isSearchable={true}
        options={options}
        value={
          selectedOption
            ? options.find((option: any) => option['value'] === selectedOption)
            : null
        }
        onChange={(e: any) =>
          handleSelectChange({
            target: { value: e?.['value'], name },
          })
        }
      />
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </Form.Group>
  );
};

export default SelectCountyInput;
