import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import usePricingApi from 'src/api/pricing.api';
import { IPricingDetails, IPricingPayload } from 'src/datatypes/Pricing';

// initial redux slice state
const initialState: IPricingPayload = {
  pricingList: {
    loading: false,
    error: false,
    data: {
      data: [],
      current_page: 0,
      total: 0,
    },
  },
  pricingDetails: {
    loading: false,
    error: false,
    success: false,
    data: null,
  },
  saveUpdatePricing: {
    loading: false,
    error: false,
    success: false,
  },
  deletePricing: {
    loading: false,
    error: false,
    success: false,
  },
};

export const pricingSlice = createSlice({
  name: 'pricing',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetPricingData: (state) => {
      state.pricingDetails = initialState.pricingDetails;
      state.saveUpdatePricing = initialState.saveUpdatePricing;
      state.deletePricing = initialState.deletePricing;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchPricingList.pending, (state) => {
        state.pricingList.loading = true;
        state.pricingList.error = false;
        state.pricingList.data.data = [];
      })
      .addCase(fetchPricingList.fulfilled, (state, { payload }) => {
        state.pricingList.data.data = payload.pricing;
        state.pricingList.data.total = payload.total;
        state.pricingList.loading = false;
      })
      .addCase(fetchPricingList.rejected, (state) => {
        state.pricingList.loading = false;
        state.pricingList.error = true;
      })
      .addCase(fetchPricingDetails.pending, (state) => {
        state.pricingDetails.loading = true;
        state.pricingDetails.error = false;
        state.pricingDetails.data = null;
      })
      .addCase(fetchPricingDetails.fulfilled, (state, { payload }) => {
        state.pricingDetails.data = payload;
        state.pricingDetails.loading = false;
        state.pricingDetails.success = true;
      })
      .addCase(fetchPricingDetails.rejected, (state) => {
        state.pricingDetails.loading = false;
        state.pricingDetails.error = true;
      })
      .addCase(saveUpdatePricingDetails.pending, (state) => {
        state.saveUpdatePricing.loading = true;
        state.saveUpdatePricing.error = false;
        state.saveUpdatePricing.success = false;
      })
      .addCase(saveUpdatePricingDetails.fulfilled, (state) => {
        state.saveUpdatePricing.loading = false;
        state.saveUpdatePricing.success = true;
      })
      .addCase(saveUpdatePricingDetails.rejected, (state) => {
        state.saveUpdatePricing.loading = false;
        state.saveUpdatePricing.error = true;
      })
      .addCase(deletePricingDetails.pending, (state) => {
        state.deletePricing.loading = true;
        state.deletePricing.error = false;
        state.deletePricing.success = false;
      })
      .addCase(deletePricingDetails.fulfilled, (state) => {
        state.deletePricing.loading = false;
        state.deletePricing.success = true;
      })
      .addCase(deletePricingDetails.rejected, (state) => {
        state.deletePricing.loading = false;
        state.deletePricing.error = true;
      });
  },
});

// Async API Hooks
/**
 * Fetch Pricing List from API and save in redux store
 */
export const fetchPricingList = createAsyncThunk(
  'pricing/fetchPricingList',
  async (data: any, { rejectWithValue }) => {
    for (const key in data.params) {
      if (data.params[key] === null) {
        delete data.params[key];
      }
    }

    const response = await usePricingApi().getPricingList(data.params);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Fetch Pricing Details from API and save in redux store
 * @param id Pricing Id
 */
export const fetchPricingDetails = createAsyncThunk(
  'pricing/fetchPricingDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await usePricingApi().getPricingDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response.pricing;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Save Pricing Details from API and save in redux store
 * @param data
 */
export const saveUpdatePricingDetails = createAsyncThunk(
  'pricing/saveUpdatePricingDetails',
  async (data: IPricingDetails, { rejectWithValue }) => {
    const payload = JSON.parse(JSON.stringify(data));
    const id = payload?.id;
    if (payload['id']) {
      delete payload['id'];
    }
    const response = id
      ? await usePricingApi().updatePricingDetails(payload, String(id))
      : await usePricingApi().savePricingDetails(payload);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response.pricing;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Delete Pricing Details
 */
export const deletePricingDetails = createAsyncThunk(
  'pricing/deletePricingDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await usePricingApi().deletePricingDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response.pricing;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const { resetPricingData } = pricingSlice.actions;

export default pricingSlice.reducer;
