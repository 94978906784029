import { ValidateField } from '../../helpers/ValidatorHelper';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import PageTitle from '../../components/common/PageTitle';
import FowButton from '../../components/common/FowButton';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { IRouteParams } from 'src/datatypes/CommonTypes';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../components/common/ToastMessage';
import _forEach from 'lodash/forEach';

import {
  templatePricingDetailsField,
  pricingDetailsValidations,
  statusOptions,
} from 'src/constants/Pricing';
import { IPricingDetails, IPricingPayload } from 'src/datatypes/Pricing';
import {
  fetchPricingDetails,
  resetPricingData,
  saveUpdatePricingDetails,
} from 'src/redux/slices/pricingSlice';
import TextInput from '../../components/common/TextInput';
import RadioGroupInput from '../../components/common/RadioGroupInput';
import NumberInput from '../../components/common/NumberInput';

const PricingDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id }: IRouteParams = useParams();
  const location = useLocation();

  const { pricingDetails, saveUpdatePricing } = useAppSelector<IPricingPayload>(
    (state) => state.pricing
  );

  const [pricingType, setPricingType] = useState<string>('new');

  const [formData, setFormData] = useState<IPricingDetails>(
    templatePricingDetailsField
  );

  const [errors, setErrors] = useState<IPricingDetails>({
    ...templatePricingDetailsField,
    price: '',
    status: '',
  });

  const validations = useMemo(() => pricingDetailsValidations, []);

  const handleInputs = (e: any) => {
    const name = e.target.name;
    let value: string | boolean;
    let error: string | undefined | null;

    //get value from checkbox / others
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    const fieldName = name;

    //check for errors
    error = ValidateField(fieldName, value, validations[name]);

    //set errors
    if (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }

    //update form data
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const hasFormError = () => {
    let hasError = false;
    _forEach(formData, (value, key) => {
      const fieldName = key;
      const error = ValidateField(fieldName, value, validations[key]);
      if (error) {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: error }));
        hasError = true;
      }
    });
    return hasError;
  };

  const handleSubmit = async (
    e: any /**React.FormEvent<HTMLFormElement> */
  ) => {
    e.preventDefault();
    if (!hasFormError()) {
      dispatch(saveUpdatePricingDetails(formData));
    }
  };

  const getPricingDetailsData = () => {
    if (id && id !== 'new') {
      dispatch(fetchPricingDetails(id));

      const containsView = location.pathname.includes('view');
      if (containsView) {
        setPricingType('view');
      } else {
        setPricingType('edit');
      }
    } else {
      setFormData(templatePricingDetailsField);
    }
  };

  // Component to render Edit button in page title if currently in view state.
  const EditButtonComponent = () => {
    if (pricingType === 'view') {
      return (
        <Button
          variant="primary"
          type="button"
          onClick={() => navigate(`/pricing/${id}/edit`)}
        >
          Edit
        </Button>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    getPricingDetailsData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pricingDetails.success && pricingDetails?.data) {
      setFormData({ ...pricingDetails?.data });
    } else if (pricingDetails.error) {
      toastErrorMessage('Something went wrong. Please try again later.');
      navigate('/pricing');
    }
    // eslint-disable-next-line
  }, [pricingDetails]);

  useEffect(() => {
    if (saveUpdatePricing.success) {
      toastSuccessMessage(
        `Pricing ${pricingType === 'new' ? 'Created' : 'Updated'} successfully.`
      );
      navigate('/pricing');
    }
    // eslint-disable-next-line
  }, [saveUpdatePricing]);

  return (
    <div className="pricing-details">
      <PageTitle
        heading={
          pricingType === 'new'
            ? 'New Pricing'
            : ` Pricing : ${pricingDetails?.data?.name ?? ''}`
        }
        buttonName="Listing"
        buttonClick={() => navigate('/pricing')}
        customButton={<EditButtonComponent />}
      />

      {pricingDetails?.loading ? (
        <Loader isFull />
      ) : !!pricingDetails ? (
        <Card>
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Body>
              <Row className="align-items-center">
                <Col lg={6} md={12}>
                  <TextInput
                    controlId="name"
                    label="Name"
                    className="form-field"
                    placeholder="Name"
                    name="name"
                    value={formData.name}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.name}
                    isRequired={true}
                    isDisabled={pricingType === 'view'}
                  />
                </Col>
                <Col lg={6} md={12}>
                  <TextInput
                    controlId="code"
                    label="Code"
                    className="form-field"
                    placeholder="Code"
                    name="code"
                    value={formData.code}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={errors?.code}
                    isRequired={true}
                    isDisabled={pricingType === 'view'}
                  />
                </Col>
                <Col lg={6} md={12}>
                  <NumberInput
                    controlId="price"
                    label="Price"
                    className="form-field"
                    placeholder="Price"
                    name="price"
                    value={Number(formData.price)}
                    onChange={(e) => handleInputs(e)}
                    errorMessage={String(errors?.price)}
                    min={0}
                    max={9999999}
                    isRequired={true}
                    isDisabled={pricingType === 'view'}
                  />
                </Col>
                <Col lg={6} md={12}>
                  <RadioGroupInput
                    name="status"
                    value={formData.status}
                    label="Status"
                    options={statusOptions}
                    onChange={handleInputs}
                    isRequired={true}
                    errorMessage={String(errors?.status)}
                    controlId="status"
                    isDisabled={pricingType === 'view'}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              {pricingType !== 'view' && (
                <FowButton
                  variant="primary"
                  onClick={handleSubmit}
                  isLoading={saveUpdatePricing.loading}
                >
                  {pricingType === 'new' ? 'Save' : 'Update'}
                </FowButton>
              )}
              <FowButton
                variant="secondary"
                onClick={() => {
                  dispatch(resetPricingData());
                  navigate('/pricing');
                }}
                isLoading={saveUpdatePricing.loading}
              >
                Cancel
              </FowButton>
            </Card.Footer>
          </Form>
        </Card>
      ) : (
        <NotFound
          heading="Pricing Not Found"
          subHeading="Please try again after sometime"
        />
      )}
    </div>
  );
};

export default PricingDetails;
