import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import ErrorMessage from './ErrorMessage';

interface IProps {
  name: string;
  value: string | number | undefined | null;
  fieldKey?: string | number;
  options: any[];
  instructions?: string;
  isLoading?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
  optionLabel?: string | undefined;
  optionValue?: string | undefined;
  type?: string;
  label?: string;
  handleSelectChange: (e: any) => void;
  isRequired?: boolean;
  errorMessage?: string;
  controlId?: string;
  className?: string;
  placeholder?: string;
}

const SelectInput = ({
  fieldKey,
  name,
  value,
  label,
  handleSelectChange,
  isRequired,
  errorMessage,
  controlId,
  className,
  placeholder,
  options,
  instructions,
  isLoading,
  isMulti,
  isDisabled,
  optionLabel,
  optionValue,
}: IProps) => {
  const [selectedOption, setSelectedOption] = useState<
    string | number | null | undefined
  >(null);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);
  return (
    <Form.Group key={fieldKey} controlId={controlId}>
      <div className="instruction-title">
        <Form.Label>
          {label}
          {isRequired && <span className="required">*</span>}
        </Form.Label>
        {instructions && (
          <span className="instruction-text">({instructions})</span>
        )}
      </div>
      <Select
        placeholder={placeholder}
        classNamePrefix={`fow-select fow-custom-select ${
          errorMessage ? 'error-focus-input' : ''
        } ${className ? className : ''}`}
        isDisabled={isDisabled}
        isClearable={true}
        isLoading={isLoading}
        getOptionLabel={(option: any) => option?.[optionLabel || 'label']}
        getOptionValue={(option: any) => option?.[optionValue || 'value']}
        isMulti={isMulti}
        isSearchable={true}
        options={options}
        value={
          !!selectedOption
            ? options.find(
                (option) => option[optionValue || 'value'] === selectedOption
              )
            : ''
        }
        onChange={(e: any) =>
          handleSelectChange({
            target: { value: e?.[optionValue || 'value'], name },
          })
        }
      />
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </Form.Group>
  );
};

export default SelectInput;
