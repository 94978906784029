const array = [
  {
    _tag: 'item',
    name: 'Dashboard',
    to: '/',
    icon: 'fa fa-dashboard',
  },
  {
    _tag: 'item',
    name: 'Death Notices',
    to: '/death-notices',
    icon: 'fa fa-bell',
  },
  {
    _tag: 'item',
    name: 'Obituaries',
    to: '/obituaries',
    icon: 'fa fa-bell',
  },
  {
    _tag: 'item',
    name: 'Questions',
    to: '/questions',
    icon: 'fa fa-question-circle-o',
  },
  {
    _tag: 'title',
    name: 'Charities',
  },
  {
    _tag: 'item',
    name: 'Charity Categories',
    to: '/charity-categories',
    icon: 'fa fa-sitemap',
  },
  {
    _tag: 'item',
    name: 'Charities',
    to: '/charities',
    icon: 'fa fa-building-o',
  },
  {
    _tag: 'title',
    name: 'Invoices & Will Searches',
  },
  {
    _tag: 'item',
    name: 'Invoices',
    to: '/invoices',
    icon: 'fa fa-usd',
  },
  {
    _tag: 'item',
    name: 'Missing Will Searches',
    to: '/missing-will-searches',
    icon: 'fa fa-file-text',
  },
  {
    _tag: 'title',
    name: 'Pages & Templates',
  },
  {
    _tag: 'item',
    name: 'Pages',
    to: '/pages',
    icon: 'fa fa-file',
  },
  {
    _tag: 'item',
    name: 'Templates',
    to: '/templates',
    icon: 'fa fa-envelope',
  },
  {
    _tag: 'item',
    name: 'Meta Tags',
    to: '/meta-tags',
    icon: 'fa fa-tags',
  },
  {
    _tag: 'title',
    name: 'Users',
  },
  {
    _tag: 'item',
    name: 'Will Creators',
    to: '/will-creators',
    icon: 'fa fa-file',
  },
  {
    _tag: 'item',
    name: 'Will Registrants',
    to: '/living-will',
    icon: 'fa fa-file-text',
  },
  {
    _tag: 'item',
    name: 'Admin Users',
    to: '/admin-users',
    icon: 'fa fa-users',
  },
  {
    _tag: 'title',
    name: 'Miscellaneous',
  },
  {
    _tag: 'item',
    name: 'Living Will Forms',
    to: '/living-will-forms',
    icon: 'fa fa-file-text',
  },
  {
    _tag: 'item',
    name: 'Testimonials',
    to: '/testimonials',
    icon: 'fa fa-file-text',
  },
  {
    _tag: 'item',
    name: 'Terminologies',
    to: '/terminologies',
    icon: 'fa fa-book',
  },
  {
    _tag: 'item',
    name: 'Faqs',
    to: '/faqs',
    icon: 'fa fa-question',
  },
  {
    _tag: 'item',
    name: 'Pricing',
    to: '/pricing',
    icon: 'fa fa-usd',
  },
];

export default array;
