import { Col, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import InvoiceDashboardListing from '../invoices/InvoiceDashboardListing';
import MissingWillSearchDashboardListing from '../missing-will-search/MissingWillSearchDashboardListing';
import { IDashboardPayload } from 'src/datatypes/Dashboard';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { fetchDashboardData } from 'src/redux/slices/dashboardSlice';
import WillCreatorDashboardListing from '../will-creators/WillCreatorDashboardListing';
import Loader from '../../components/common/Loader';

const Dashboard = () => {
  const dispatch = useAppDispatch();

  const { dashboardData } = useAppSelector<IDashboardPayload>(
    (state) => state.dashboard
  );

  const getDashboardData = () => {
    dispatch(fetchDashboardData());
  };

  useEffect(() => {
    getDashboardData();
    // eslint-disable-next-line
  }, []);

  return dashboardData.loading ? (
    <Loader isFull />
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Row>
        <Col lg={6}>
          <InvoiceDashboardListing
            invoiceData={dashboardData.data.invoiceData}
            isLoading={dashboardData.loading}
          />
        </Col>
        <Col lg={6}>
          <MissingWillSearchDashboardListing
            missingWillData={dashboardData.data.missingWillData}
            isLoading={dashboardData.loading}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <WillCreatorDashboardListing
            willCreatorsData={dashboardData.data.willCreatorsData}
            isLoading={dashboardData.loading}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
