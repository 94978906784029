import React from 'react';
import { Form } from 'react-bootstrap';
import ErrorMessage from './ErrorMessage';

interface IProps {
  name: string;
  acceptFiles: string;
  value?: string | File | null;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  errorMessage?: string;
  controlId?: string;
  className?: string;
  placeholder?: string;
}

const FileInput = ({
  name,
  value,
  label,
  onChange,
  isRequired,
  errorMessage,
  controlId,
  acceptFiles,
}: IProps) => {
  return (
    <Form.Group controlId={controlId}>
      {label && (
        <div className="instruction-title">
          <Form.Label>
            {label}
            {isRequired && <span className="required">*</span>}
          </Form.Label>
        </div>
      )}
      <div className="custom-file">
        <input
          type="file"
          className="custom-file-input"
          name={name}
          accept={acceptFiles}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e);
          }}
          id="customFile"
        />
        <label className="custom-file-label">
          {value instanceof File ? value.name : 'Choose file'}
        </label>
      </div>
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </Form.Group>
  );
};

export default FileInput;

FileInput.defaultProps = {
  isRequired: false,
  label: '',
  errorMessage: '',
  controlId: '',
  className: '',
  placeholder: '',
  acceptFiles: '.jpg, .jpeg, .png',
};
