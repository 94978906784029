import useAxiosService from '../services/axios.service';

const useStateCounty = () => {
  const { getUserHttpClient } = useAxiosService();

  //api call to get states list
  const getStates = async () => {
    return await getUserHttpClient()
      .get('/states')
      .then((response) => response.data.states);
  };

  //api call to get state details
  const getStateDetails = async (state: string) => {
    return await getUserHttpClient()
      .get(`/states/${state}`)
      .then((response) => response.data);
  };

  //api call to delete state details
  const deleteStateDetails = async (state: string) => {
    return await getUserHttpClient()
      .delete(`/states/${state}`)
      .then((response) => response.data);
  };

  // api call to get counties list
  const getCounties = async (state: string) => {
    return await getUserHttpClient()
      .get(`/state-counties/${state}`)
      .then((response) => response.data.counties);
  };

  return { getStates, getStateDetails, deleteStateDetails, getCounties };
};

export default useStateCounty;
