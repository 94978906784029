import { dtCustomStyle } from '../../helpers/DataTableHelper';
import { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import {
  IShowDeleteTestimonialModal,
  ITestimonialColumn,
  ITestimonialRow,
  ITestimonialPayload,
} from 'src/datatypes/Testimonial';
import { useAppDispatch } from 'src/redux/hooks';
import DeleteModal from './DeleteModal';
import { deleteTestimonialData } from 'src/redux/slices/testimonialSlice';
import { PageOptions } from 'src/constants/Common';

interface IProps {
  testimonialList: ITestimonialPayload['testimonialList'];
  deleteTestimonialDetail: ITestimonialPayload['deleteTestimonialStatus'];
  showDeleteModal: IShowDeleteTestimonialModal;
  setShowDeleteModal: React.Dispatch<
    React.SetStateAction<IShowDeleteTestimonialModal>
  >;
}

const Listing = ({
  testimonialList,
  deleteTestimonialDetail,
  showDeleteModal,
  setShowDeleteModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  //siwtch for delete modal
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev: IShowDeleteTestimonialModal) => ({
      ...prev,
      show: !showDeleteModal.show,
      ...(showDeleteModal.show ? { idDetails: [] } : {}),
    }));
  };

  // selected testimonial
  const selectedTestimonialId = (rows: ITestimonialRow[]) => {
    setShowDeleteModal((prev: IShowDeleteTestimonialModal) => ({
      ...prev,
      idDetails: rows,
    }));
  };

  // handle delete testimonial
  const handleDelete = () => {
    const selectedIds = showDeleteModal.idDetails
      .map((testimonialDetails: ITestimonialRow) => testimonialDetails.id)
      .join(',');
    dispatch(deleteTestimonialData(selectedIds));
  };

  // handle delete modal open
  const openDeleteModal = (row: ITestimonialRow) => {
    selectedTestimonialId([row]);
    toggleDeleteModal();
  };

  //columns for listing
  const columns: ITestimonialColumn[] = [
    {
      id: 'name',
      name: 'Name',
      selector: (row: ITestimonialRow) => row.name,
      sortable: true,
      width: '9.5rem',
    },
    {
      id: 'text',
      name: 'Text',
      selector: (row: ITestimonialRow) => row.testimonial_text,
      sortable: true,
      maxWidth: '28rem',
    },
    {
      id: 'location',
      name: 'Location',
      selector: (row: ITestimonialRow) => row.location,
      sortable: true,
      width: '9.5rem',
      cell: (row: ITestimonialRow) => row.location ?? 'N/A',
    },
    {
      id: 'date',
      name: 'Created On',
      selector: (row: ITestimonialRow) => row.date,
      sortable: true,
      width: '8.5rem',
    },
    {
      id: 'actions',
      name: 'Actions',
      selector: (row: ITestimonialRow) => row.actions,
      center: true,
      compact: true,
      width: '8.5rem',
      cell: (row: ITestimonialRow) => (
        <div className="mx-auto" style={{ display: 'flex', gap: '10px' }}>
          <a
            title="View"
            className="btn btn-secondary btn-sm"
            href={`/testimonials/${row?.id}/view`}
            key="view"
          >
            <i className="fa fa-eye"></i>
          </a>
          <a
            title="Edit"
            className="btn btn-primary btn-sm"
            href={`/testimonials/${row.id}/edit`}
            key="edit"
          >
            <i className="fa fa-pencil"></i>
          </a>
          <Button
            title="Delete"
            className="btn btn-danger btn-sm"
            key="delete"
            onClick={() => openDeleteModal(row)}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
  ];

  // handle delete modal close on success delete
  useEffect(() => {
    if (deleteTestimonialDetail?.success) {
      setShowDeleteModal((prev: IShowDeleteTestimonialModal) => ({
        ...prev,
        idDetails: [],
      }));
    }
    // eslint-disable-next-line
  }, [deleteTestimonialDetail?.success]);

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={testimonialList.loading}
              keyField="id"
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              highlightOnHover
              defaultSortFieldId="title"
              columns={columns}
              paginationTotalRows={testimonialList.data?.total}
              data={testimonialList.data?.data}
              customStyles={dtCustomStyle}
            />
          </Card.Body>
        </Card>
        <DeleteModal
          show={showDeleteModal.show}
          selectedIdDetails={showDeleteModal.idDetails}
          toggleDeleteModal={toggleDeleteModal}
          handleSubmit={handleDelete}
          deleteTestimonialDetail={deleteTestimonialDetail}
        />
      </div>
    </div>
  );
};

export default Listing;
