import { IQuestionDetails } from 'src/datatypes/Questions';
import useAxiosService from '../services/axios.service';

const useQuestionsApi = () => {
  const { getUserHttpClient } = useAxiosService();

  //get questions list
  const getQuestionsList = async () => {
    return await getUserHttpClient()
      .get('/questions')
      .then((response) => response?.data);
  };

  //get question by id
  const getQuestionDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/questions/' + id)
      .then((response) => response?.data);
  };

  // save new question
  const saveQuestionDetails = async (data: IQuestionDetails) => {
    return await getUserHttpClient()
      .post('/questions', data)
      .then((response) => response);
  };

  // update question
  const updateQuestionDetails = async (data: IQuestionDetails, id: string) => {
    return await getUserHttpClient()
      .put(`/questions/${id}`, data)
      .then((response) => response);
  };

  //delete question by id
  const deleteQuestionData = async (id: string) => {
    return await getUserHttpClient()
      .delete(`/questions/${id}`)
      .then((response) => response?.data);
  };

  return {
    getQuestionsList,
    getQuestionDetails,
    saveQuestionDetails,
    updateQuestionDetails,
    deleteQuestionData,
  };
};

export default useQuestionsApi;
