import {
  ILivingWillFormDetails,
  ILivingWillFormValidations,
} from 'src/datatypes/LivingWillForms';

// initial state for form and error
export const templateLivingWillFormDetails: ILivingWillFormDetails = {
  site_title: '',
  page_title: '',
  meta_title: '',
  meta_description: '',
  state: '',
  pdf_name: '',
};

// validations for form
export const livingWillFormValidations: ILivingWillFormValidations = {
  site_title: 'required|min:2|max:256',
  page_title: 'required|min:2|max:256',
  meta_title: 'required|min:2|max:256',
  meta_description: '',
  state: 'required',
  pdf_name: 'required',
};
